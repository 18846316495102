@use "/src/styles/base/resources" as *;

.remote-session-editor {
  .platform-icon {
    border: 2px solid transparent;
    padding: 8px;
    margin-right: 10px;

    &.selected {
      border-color: $blue;
      background-color: lighten($blue, 50%);
    }
  }

  .rap-target-container {
    z-index: 19 !important; // needed otherwise it will be on top of the drawer from the navbar (z-index 100) and the overlay from the dialog (z-index 20)

    button.asci-btn {
      margin: 0;
    }
  }

  .rap-popover-content {
    background: white;
    color: $black;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 1px 2px 10px rgba(100, 100, 100, 0.2);
  }
}
