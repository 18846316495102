@use "/src/styles/base/resources" as *;

.template-list {
  width: 100%;
  box-shadow: $glow-shadow;

  .template-list-header {
    border-radius: 8px 8px 0 0;
    background-color: $primary1;
    color: white;
    padding: 15px;

    .template-name {
      padding-left: 1%;
    }

    .template-programs-affected {
      padding-left: 35%;
    }
  }

  .serviceList-expansion {
    margin-top: 0px;
    .service-list-expansion-panel {
      border-radius: 0px;
      &.md-expansion-panel--expanded:not(:last-child) {
        margin-bottom: 0px;
      }

      &.md-paper {
        border-radius: 0px;
        box-shadow: none;
        margin-top: 0px;
        margin-bottom: 2px;
      }

      & > .md-panel-header {
        background-color: white;
        border-radius: 0px;
        border-color: white;

        .template-identifier {
          color: $gray8;
          min-width: 575px;
        }

        .programs-affected {
          color: $primary1;
        }

        .duplicate-btn {
          color: $primary1;
          border-radius: 3px;
          height: 25px;
        }

        & > .md-collapser {
          color: $primary1;
          font-size: 16px;
        }

        & > .md-panel-column.md-text {
          color: $gray8;
          font-size: 16px;
          width: 100%;
        }
      }
    }
  }
}
