@use "/src/styles/base/resources" as *;

.test-categories-list {
  $row-height: 60px;

  > div {
    height: $row-height;

    .image {
      width: 70px;

      img {
        height: 40px;
        width: 40px;
      }
    }

    .name {
      color: #5a5a5a;
      line-height: 18px;
      font-size: 14px;
    }

    .arrow {
      width: 32px;

      .md-icon {
        color: #b3b3b3;
        font-size: 18px;
      }
    }

    &.selected {
      background: #dde3e6;

      .name,
      .arrow .md-icon {
        color: $blue;
      }
    }

    &:hover:not(.selected) {
      cursor: pointer;
    }
  }
}
