@use "/src/styles/base/resources" as *;

.program-editor {
  background: white;
  border-radius: 8px;
  box-shadow: $glow-shadow;
  .cc-section-header {
    border-radius: 8px 8px 0 0;
  }

  .cc-details-panel {
    border-radius: 0 0 8px 8px;
  }
}
