@use "/src/styles/base/resources" as *;

.session-info-container {
  .add-to-print {
    padding: 5px;
    border: 1px solid $gray4;
  }
}

.cc-section-header.table-header.md-panel-header {
  background-color: $gray1;
  border: 1px $gray3 solid;
  color: $primary1;
  border-radius: 2px;
}
