@use "/src/styles/base/resources" as *;

.marquee-active {
  .marquee-text {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  }
  &:hover {
    pointer-events: stroke;
    text-overflow: unset;
    border-left: 1px $grey solid;
    border-right: 1px $grey solid;
    .marquee-text {
      display: inline-block;
      animation: marquee linear infinite;
    }
  }
  @keyframes marquee {
    0% {
      transform: translateX(8em);
    }

    100% {
      transform: translateX(-99%);
    }
  }
}
.clickable-text {
  .marquee-text:hover {
    cursor: pointer;
  }
}
