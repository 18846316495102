@use "/src/styles/base/resources" as *;

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  hyphens: auto;
}

.service-wrapper {
  margin: 1.5em 0;
}

.service-name {
  font-size: 1.2em;
  color: $blue;
}

.service-id {
  font-size: 14px;
  font-style: italic;
  color: $gray9;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.argument {
  margin-left: 12px;
  margin-bottom: 0.25em;

  &:last-child {
    margin-bottom: 0;
  }

  .argument-name {
    white-space: nowrap;
  }

  .argument-value {
    margin-left: 0.5em;
    font-weight: bold;
  }
}
