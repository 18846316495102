@use "/src/styles/base/resources" as *;

.service-details {
  background-color: $gray1;
  padding-left: 15px;
  padding-top: 10px;
  min-height: 200px;

  .service-info {
    margin-right: 15%;
    .service-className {
      margin-top: 6px;
      .className-label {
        color: $gray7;
        font-size: 15px;
      }
      .className-info {
        margin-left: 15px;
        font-size: 15px;
        color: $gray9;
      }
    }

    .displayName {
      margin-top: 6px;
      .displayName-label {
        font-size: 15px;
        color: $gray7;
        line-height: 40px;
        margin-right: 6px;
      }

      .displayName-textfield {
        margin-right: 5px;
        border: 0.5px solid $gray2;
        border-radius: 3px;
        width: 170px;
        height: 35px;
        min-width: 100px;
        background-color: $gray1;
        padding-left: 5px;
        position: relative;
        font-size: 16px;

        .md-divider {
          display: none;
        }
        .md-text {
          font-size: 15px;
          margin-top: 10px;
          padding-left: 3px;
          color: $gray8;
        }

        .md-text-field-icon {
          display: none;
          line-height: 0px;
          position: absolute;
          margin-left: 140px;
          font-size: 15px;
          color: $gray5;
        }
      }

      .displayName-textfield:focus-within {
        @extend .displayName-textfield;
        background-color: white;
        .md-text-field-icon {
          display: none;
        }
      }

      .displayName-textfield:hover {
        @extend .displayName-textfield;
        .md-text-field-icon {
          display: initial;
        }
      }
    }

    .isEnabled {
      margin-top: 5px;
      .isEnabled-label {
        font-size: 15px;
        color: $gray7;
        line-height: 20px;
        margin-right: 6px;
      }
      .cc-checkbox {
        height: 20px;
      }
    }

    .isOptional {
      margin-top: 15px;
      .isOptional-label {
        font-size: 15px;
        color: $gray7;
        line-height: 20px;
        margin-right: 6px;
      }
      .cc-checkbox {
        height: 20px;
      }
    }
  }
  .remove-btn {
    margin-left: 2%;
    font-size: 14px;
    color: $gray7;
    padding: 0;
    min-width: 15px;
    border-radius: 7px;

    &:hover:not([disabled]) {
      background-color: $gray3;
    }
  }
  .add-btn {
    margin: 1% 2% 5% 6%;
    color: white;
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: $primary1;
    border-radius: 12px;

    &:hover:not([disabled]) {
      background-color: $dark-blue;
    }
  }
  .argument-editor {
    .arguments-title {
      margin: 1% 0% 2% 5%;
      color: $gray7;
      font-size: 16px;
    }

    .name-label {
      margin-left: 5%;
      color: $gray8;
      font-size: 14px;
      font-weight: bold;
    }
    .type-label {
      margin-left: 20%;
      color: $gray8;
      font-size: 14px;
      font-weight: bold;
    }
    .value-label {
      margin-left: 12%;
      color: $gray8;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
