@use "/src/styles/base/resources" as *;

.manual-upload-tab {
  .section-spacing {
    padding: 20px;
  }
  .section-separator {
    margin: auto;
    height: 1px;
    width: 95%;
    border: 1px solid #bab9b9;
    opacity: 0.25;
  }

  .cc-content-container {
    margin: none;
  }
}
