@use "/src/styles/base/resources" as *;

.platform-icon {
  &.selectable {
    &:hover {
      cursor: pointer;
    }

    border: 2px solid transparent;
  }

  &.tabable {
    outline: 0;
    &:focus {
      border-color: transparentize($blue, 0.8);
    }
  }

  svg {
    display: block;
    width: 40px;
    height: 40px;
    fill: black;
  }
}
