@use "/src/styles/base/resources" as *;

.certificate-description {
  .certificate-stamp-logo,
  .certificate-description-text {
    margin: 15px;
    text-align: justify;
    text-justify: inter-word;

    h2 {
      font-size: 19px;
      font-weight: bold;
    }

    .bolder-text {
      font-weight: bold;
      font-size: 13px;
    }

    img {
      height: 120px;
      width: auto;
      filter: grayscale(100%);
    }
  }
}
