@use "/src/styles/base/resources" as *;

.reporting-page {
  max-width: 1800px !important;

  & > [class^="reporting-"] {
    margin-bottom: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ant-tabs {
    border-radius: $border-radius;
    box-shadow: $glow-shadow;
    background: $white;

    .ant-tabs-nav {
      border-radius: $border-radius-top;
      background: $primary3;
      padding: 0 24px;
      margin: 0;

      .ant-tabs-tab {
        font-weight: 500;
        padding: 16px 0;
      }
    }
  }

  .ant-btn-icon-only.ant-btn-lg > * {
    font-size: 24px;
  }
}
