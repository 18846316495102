@use "/src/styles/base/resources" as *;

.sku-groups-page {
  .ant-card {
    border-radius: $border-radius;
    box-shadow: $glow-shadow;

    .ant-card-head {
      border-radius: $border-radius-top;
      background: $primary3;
    }
  }

  .sku-groups {
    .ant-card-body {
      padding: 0;
    }
  }
}
