@use "/src/styles/base/resources" as *;

.md-tabs-container.tabs-container-override {
  // navigation tabs
  .md-tabs {
    padding-left: 0 !important;

    .tab-nav-icon {
      color: $white;
    }

    .md-btn--tab-overflow-right {
      right: 0;
    }

    .md-btn--tab-overflow-left {
      left: 0;
    }

    .md-tab-indicator {
      display: none;
    }

    .md-tab {
      font-size: 2.5em;
      background-color: #e1e1e1;
      color: $dark-color;
      padding: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-left: solid 1px #ccc;

      .md-tab-label {
        text-transform: uppercase;
        font-weight: bold;
      }

      &.md-tab--active {
        background-color: $blue;
        color: $white;
      }

      &:first-of-type {
        @include border-radius(5px 0 0 0);
        border-left: none;
      }

      &:last-of-type {
        @include border-radius(0 5px 0 0);
      }

      &:only-of-type {
        @include border-radius(5px 5px 0 0);
        border-left: none;
        padding: 0 15px;
      }
    }
  } // content
  .md-tabs-content {
    background: $white;
    overflow: unset !important;

    div[aria-hidden="true"] {
      opacity: 0;
      overflow: hidden;
    }

    div[aria-hidden="false"] {
      opacity: 1;
      overflow: hidden;
    }

    .tabs-container {
      background: $white;
      padding: 0;

      & > div {
        width: 100%;
      }

      div {
        .facility-list {
          background: $white;
        }
      }
    }
  }
}
