@use "/src/styles/base/resources" as *;

.cc-day-picker {
  .day-picker-label {
    color: $primary1;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  .date-picker {
    .ant-calendar-picker-input {
      border: 1px solid $gray1;
      color: $gray5;
    }

    .ant-calendar-picker-icon {
      color: $gray5;
    }

    &.ant-calendar-picker:hover {
      .ant-calendar-picker-input:not(.ant-input-disabled) {
        border-color: $primary2;
      }

      .ant-calendar-picker-icon:not(.ant-input-disabled) {
        color: $primary2;
      }
    }
  }
}

.popup-calendar {
  .ant-calendar-today .ant-calendar-date {
    color: $primary2;
    border-color: $primary2;
  }

  .ant-calendar-today-btn {
    color: $primary2;
    padding: 0 11px;
    border-radius: 5px;

    &:hover {
      color: $primary2;
      background-color: $gray-cold1;
    }
  }

  .ant-calendar-today-btn:hover {
    color: $primary2;
  }
}
