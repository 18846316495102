@use "/src/styles/base/resources" as *;

@mixin margin-right-except-last($margin) {
  margin-right: $margin;

  &:last-child {
    margin-right: 0;
  }
}

.reporting-filters-and-chart {
  & > .reporting-section {
    margin-bottom: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .report-icons .anticon {
    @include margin-right-except-last(0.5em);
  }

  .buttons .ant-btn {
    @include margin-right-except-last(0.5em);
  }
}

.ant-select-dropdown {
  .report-icons .anticon {
    @include margin-right-except-last(0.5em);
  }
}
