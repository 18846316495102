@use "/src/styles/base/resources" as *;

.asci-spinner-container {
  padding: 0;
  margin: 0;
  @include flexbox;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.3);
}

.asci-spinner-large {
  color: $blue;
  font-size: 6em;
  animation: asci-spinner-spin 3s linear infinite;
}

.asci-spinner-small {
  color: $blue;
  font-size: 4em;
  animation: asci-spinner-spin 3s linear infinite;
}

@keyframes asci-spinner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
