@use "/src/styles/base/resources" as *;

.user-logs-filters {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  background-color: $white;
  border-bottom: none;

  .row {
    margin: 0 20px;
  }

  .panel-container {
    margin: 0 60px;
  }

  .asci-btn.white-button.generate-log-btn {
    margin: 4px 10px;
    border-radius: 3px;
    color: white;
    background-color: $primary1;
    font-weight: bold;
    text-shadow: none;
    padding: 0 15px;
    min-width: 150px;
  }
}
