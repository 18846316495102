@use "/src/styles/base/resources" as *;

.advanced-informations {
  .desktop-information-table {
    margin: 2%;
    .table-header {
      background-color: $primary1;
      .header-label {
        color: white;
        font-size: 18px;
      }
    }

    .table-body {
      .table-label {
        color: $primary1;
        font-size: 16px;
      }

      .table-data {
        color: $gray8;
        font-size: 16px;
      }
    }
  }

  .software-information-table {
    margin: 2%;
    .table-header {
      background-color: $primary1;
      .header-label {
        color: white;
        font-size: 18px;
      }
    }

    .table-body {
      .table-label {
        color: $primary1;
        font-size: 16px;
      }

      .table-data {
        color: $gray8;
        font-size: 16px;
      }
    }
  }

  .other-information-table {
    margin: 2%;
    .table-header {
      background-color: $primary1;
      .header-label {
        color: white;
        font-size: 18px;
      }
    }

    .table-body {
      .table-label {
        color: $primary1;
        font-size: 16px;
      }

      .table-data {
        color: $gray8;
        font-size: 16px;
      }
    }
  }

  .download-information-table {
    width: 47%;
    margin: 2%;
    .table-header {
      background-color: $primary1;
      .header-label {
        color: white;
        font-size: 18px;
      }
    }

    .table-body {
      .download-button {
        margin: 5px;

        .asci-btn.blue-button.download-json {
          height: 40px;
          font-size: 15px;
          padding: 5px 15px;
          min-width: 150px;

          &:hover:not([disabled]) {
            background-color: $dark-blue;
          }
        }
      }
    }
  }
}
