@use "/src/styles/base/resources" as *;

.main-container.test-suites {
  .test-suite-editor {
    .test-suite-order-list {
      box-shadow: $glow-shadow;
    }
    .cc-section-header {
      border-radius: 8px 8px 0 0;
      margin: 0 1px 0 1px;
    }
  }
}
