@use "/src/styles/base/resources" as *;

.workflow-editor {
  .cc-section-header {
    width: 100%;
    .title {
      width: 100%;
    }
  }
}
.add-icon-btn {
  cursor: pointer;
  user-select: none;
}
.workflow-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
