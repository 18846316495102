@use "/src/styles/base/resources" as *;

.test-processing-times-page {
  .report-results-wrapper {
    background: #e5eef1;

    .report-results {
      margin: 0 3em;
      padding: 1em 0;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid rgba($gray-cold4, 0.2);
    }

    .result-item {
      margin-right: 2em;
    }
    .report-statistics {
      display: flex;
    }
  }

  .md-dialog {
    max-width: 1000px;
  }
}
