@use "/src/styles/base/resources" as *;

.user-avatar {
  text-transform: uppercase;
  div {
    text-transform: uppercase;
  }
  background-size: cover;
  background-repeat: no-repeat;
  .md-avatar-content {
    font-size: 16px;
  }
  &.md-avatar--yellow {
    background: #fffd38;
    .md-avatar-content {
      background: linear-gradient(45deg, #ffe052, #fffd38);
    }
  }
  &.md-avatar--red {
    background: #d30915;
    .md-avatar-content {
      background: linear-gradient(45deg, #d30915, #d30989);
    }
  }
  &.md-avatar--orange {
    background: #f98b25;
    .md-avatar-content {
      background: linear-gradient(45deg, #f98b25, #f9ac25);
    }
  }
  &.md-avatar--blue {
    background: #2f67fb;
    .md-avatar-content {
      background: linear-gradient(45deg, #2f67fb, #2fa7fb);
    }
  }
  &.md-avatar--light-blue {
    background: #55c4f5;
    .md-avatar-content {
      background: linear-gradient(45deg, #55c4f5, #55e0f5);
    }
  }
  &.md-avatar--green {
    background: #327c36;
    .md-avatar-content {
      background: linear-gradient(45deg, #327c36, #329a56);
    }
  }
  &.md-avatar--grey {
    background: #616161;
    .md-avatar-content {
      background: linear-gradient(45deg, #616161, lighten(#616161, 4%));
    }
  }
}
