@use "/src/styles/base/resources" as *;

.selection-container {
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc;
  color: #005288;
  overflow: hidden;
  transition: border-color 0.5s ease-in-out;
  margin: 15px;
  background: transparentize($white, 0.6);

  &.active {
    border-color: $blue;
    background: $white;
  }
  .thumbnail {
    transition: box-shadow 0.5s ease-in-out;
    box-shadow: -1px 4px 18px -8px rgba(0, 0, 0, 0.85);

    background: $blue;
    padding: 15px;
    margin: 15px;
    color: $white;
    width: 180px;
    height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  &:hover,
  &.active {
    .thumbnail {
      box-shadow: -1px 10px 18px -6px rgba(0, 0, 0, 0.85);
    }
  }
  .description-icon {
    padding: 1em;
    i {
      font-size: 4em;
    }
  }
  img {
    height: auto;
    width: 100%;
  }
}
