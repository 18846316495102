@use "/src/styles/base/resources" as *;

.fail-codes-list {
  $selector-row-height: 60px;
  $border-color: #979797;

  .selector-row {
    height: $selector-row-height;
    border-bottom: 1px solid $border-color;
    padding: 5px;
    .content-pointer {
      color: $grey;
    }
    &.checked {
      background: $grey-ligth;
    }
    &.current {
      background: #bbe4ff;
    }
    &.hidden {
      > div {
        opacity: 0.33;
      }

      .visible {
        opacity: 1;
      }
    }
  }

  .fail-code-name {
    width: 20%;
    flex-shrink: 0;
    padding: 5px;
    .subtitle {
      font-size: 10px;
      color: grey;
    }
  }
  .fail-code-description {
    width: 30%;
    flex-shrink: 0;
    padding: 5px;
  }
}
