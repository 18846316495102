@use "/src/styles/base/resources" as *;

.main-container.test-suites {
  .affected-programs {
    margin-top: 1em;

    ul {
      list-style-type: disc;
      padding-left: 2em;
      margin-bottom: 0.5em;
    }
  }

  .test-suites-list {
    .test-suites-table {
      box-shadow: $glow-shadow;

      .cc-section-header {
        border-radius: 8px 8px 0 0;
      }
    }
  }
}
