@use "/src/styles/base/resources" as *;

.cc-platform-selector {
  .platform-icon {
    border: 2px solid transparent;
    padding: 8px;
    margin-right: 10px;

    &.selected {
      border-color: $blue;
      background-color: lighten($blue, 50%);
    }
  }

  &.disabled {
    cursor: no-drop;

    .platform-icon {
      opacity: 0.3;

      &.selectable {
        &:hover {
          cursor: no-drop;
        }
      }
    }
  }
}
