@use "/src/styles/base/resources" as *;

.label-editor {
  background: #ababab;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  overflow: auto;

  .label-editor-layout-container {
    background: white;
    overflow: hidden;
    box-sizing: border-box;
    page-break-after: always;
    page-break-inside: avoid;
  }
}
.label-body-print {
  margin: 0;
  padding: 0;
}
