@use "/src/styles/base/resources" as *;

.label-grid-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 100%;

  .label-grid-row {
    display: flex;
    width: 100%;

    .label-grid-column {
      box-sizing: border-box;
      .label-shape {
        width: 100%;
        height: 100%;
      }
    }
  }
}
