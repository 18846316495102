@use "/src/styles/base/resources" as *;

.cc-performance-card {
  .tests-table {
    width: 100%;
    max-height: 296px;
    margin: 16px 36px;
    padding: 0 8px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: $gray4;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: $primary1;
      border-radius: 4px;
    }

    .md-table-header {
      background: $gray-cold2;
    }

    .md-text--secondary {
      color: $gray8;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .no-data {
    margin: 2em 1em;

    [class^="icon-"],
    [class*=" icon-"] {
      margin-right: 0.5em;
    }
  }
}
