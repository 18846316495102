@use "/src/styles/base/resources" as *;

.existing-services-dialog {
  .service-displayName {
    font-size: 15px;
    color: $gray8;
    line-height: 42px;
    padding-right: 12px;
  }

  .btn-group {
    justify-content: flex-end;
    margin-top: 10px;

    .asci-btn.green-button.confirm-btn {
      box-shadow: none;
      text-shadow: none;
      padding: 0 10px;
      min-width: 70px;
      min-height: 35px;
      border-radius: 4px;
    }

    .asci-btn.white-button.cancel-btn {
      background-color: $gray1;
      box-shadow: none;
      text-shadow: none;
      padding: 0 10px;
      min-width: 70px;
      min-height: 35px;
      border-radius: 4px;

      &:hover:not([disabled]) {
        background-color: $gray3;
      }
    }
  }
}
