@use "/src/styles/base/resources" as *;

.bar-chart-graph {
  .recharts-cartesian-axis-ticks {
    font-size: 12px;
    font-weight: 500;
  }

  .recharts-label-list {
    font-size: 14px;
  }

  .recharts-legend-item {
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }

  .recharts-wrapper.drill-down {
    .recharts-bar,
    .recharts-xAxis .recharts-cartesian-axis-tick {
      cursor: pointer;
    }
  }
}
