@use "/src/styles/base/resources" as *;

.analytics-page {
  .analytics-section {
    margin-bottom: 72px;

    &:last-child {
      margin-bottom: 0;
    }

    .section-header {
      margin-bottom: 32px;

      .section-title {
        color: $gray-cold7;
        font-size: 25px;
        font-weight: 500;
      }

      .section-description {
        color: $gray8;
        font-size: 18px;
      }
    }
  }

  .app-list {
    margin: -16px -18px;

    .analytics-button {
      margin: 16px 18px;
    }
  }
}
