@use "/src/styles/base/resources" as *;

.reporting-section {
  background: $white;
  box-shadow: $glow-shadow;
  border-radius: 8px;

  .cc-section-header {
    border-radius: 8px 8px 0 0;
  }
}
