@use "/src/styles/base/resources" as *;

.argument-editor {
  padding-right: 15px;

  .argument-textfield {
    margin-right: 5px;
    border: 0.5px solid $gray2;
    border-radius: 3px;
    width: 150px;
    height: 40px;
    min-width: 100px;
    background-color: $gray1;
    padding: 0 0 5px 7px;
    margin-top: 5px;
    font-size: 14px;
    line-height: 15px;
    position: relative;

    .md-divider {
      display: none;
    }
    .md-text {
      color: $gray8;
    }

    .md-text-field-icon {
      display: none;
      line-height: 0px;
      position: absolute;
      margin-left: 120px;
      font-size: 15px;
      color: $gray5;
    }
  }

  .argument-textfield:hover {
    @extend .argument-textfield;
    .md-text-field-icon {
      display: initial;
    }
  }

  .argument-textfield:focus-within {
    @extend .argument-textfield;
    background-color: white;
    .md-text-field-icon {
      display: none;
    }
  }

  .argument-selectlist {
    .cc-select-list {
      border: 0.5px solid $gray2;
      margin-right: 5px;
      border-radius: 3px;
      margin-top: 5px;
      height: 40px;
      width: 100px;
      line-height: 25px;
      font-size: 14px;

      &::after,
      select {
        color: $gray8;
        min-height: 40px;
      }
    }
  }

  .argument-type {
    .cc-select-list {
      border: 0.5px solid $gray2;
      margin-right: 5px;
      border-radius: 3px;
      margin-top: 5px;
      height: 40px;
      width: 100px;
      line-height: 25px;
      font-size: 14px;

      &::after,
      select {
        color: $gray8;
        min-height: 40px;
      }
    }
  }
}
