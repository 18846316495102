@use "/src/styles/base/resources" as *;

.cc-asci-wizard-navigation {
  $nav-button-container-width: 140px;

  .nav-btn-container {
    width: $nav-button-container-width;
    .asci-btn {
      margin: 0;
    }
  }

  .steps-container {
    width: calc(100% - #{2 * $nav-button-container-width});
  }

  .navigation-wizard .ant-steps-item-icon {
    margin-left: 25px;
  }

  .navigation-wizard .ant-steps-item-tail {
    padding: 0;
    top: 25px;
  }
}
