@use "/src/styles/base/resources" as *;

.cc-text-field {
  $border-color: #ccc;
  $bg-color: $white;
  $input-height: 32px;
  $input-border-size: 1px;
  $input-padding-h: 5px;
  $number-control-background: #f1f1f1;

  position: relative;
  display: inline-block;

  input {
    max-width: 100%;
    height: $input-height;
    line-height: $input-height;
    font-size: 14px;
    border: $input-border-size solid $border-color;
    border-radius: 3px;
    background-color: $bg-color;
    outline: 0;
    padding: 0 $input-padding-h;

    &:hover {
      background-color: darken($bg-color, 1%);
    }

    &:focus {
      border-color: darken($border-color, 10%);
    }
  }

  &.full-width {
    width: 100%;

    input {
      width: 100%;
    }
  }

  .icon-close {
    position: absolute;
    top: $input-height * 0.5;
    right: $input-padding-h;
    transform: translateY(-50%);
    color: $border-color;

    &:hover {
      color: $dark-color;
      cursor: pointer;
    }

    &:active {
      color: lighten($dark-color, 2%);
    }
  }

  &.type-number {
    input {
      padding-right: $input-padding-h + 20px;
    }
  }

  .number-controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: $input-border-size;
    right: $input-border-size;

    .md-icon {
      font-size: 16px;
      width: 20px;
      height: $input-height * 0.5 - 1px;
      padding: 0 $input-padding-h;
      background-color: $number-control-background;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      color: $dark-color;

      &:hover {
        background-color: darken($number-control-background, 3%);
        cursor: pointer;
      }
    }
  }

  &.disabled {
    input {
      opacity: 0.7;
      background-color: darken($bg-color, 2%);

      &:hover {
        background-color: darken($bg-color, 2%);
        cursor: no-drop;
      }
    }

    .number-controls {
      .md-icon {
        color: darken($number-control-background, 10%);

        &:hover {
          background-color: $number-control-background;
          cursor: no-drop;
        }
      }
    }
  }
}
