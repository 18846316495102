@use "/src/styles/base/resources" as *;

.value-editor {
  > * {
    margin: 10px 0;
  }

  .label {
    flex: 0 1 50%;
    text-align: right;
    padding-right: 20px;
  }
}

.bool-editor {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.string-editor,
.integer-editor,
.float-editor {
  display: flex;
  flex-direction: row;
  align-items: center;

  .cc-text-field {
    flex: 0 1 50%;
  }
}
