@use "/src/styles/base/resources" as *;

.md-dialog {
  &--very-large {
    max-width: 1200px !important;
  }

  .md-dialog-content {
    &--no-padding {
      padding: 0;
    }

    &--no-margin {
      margin: 0;
    }
  }
}

.dialog-actions {
  margin: 15px 0 0 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
