@use "/src/styles/base/resources" as *;

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  flex: 0 1 auto;

  h1,
  h2,
  h3,
  h4,
  p,
  span,
  a {
    font-family: "Titillium";
  }

  h3 {
    color: $primary1;
    font-size: 16px;
  }

  .row-to-column {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    @include mq("tablet", "max") {
      flex-flow: column nowrap;
    }
  }
  .home-documentation-body {
    background: $white;
    box-shadow: $glow-shadow;
    border-radius: 8px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    min-width: 300px;
    padding: 70px 0 40px 0;
    margin-top: 8px;
  }

  .icon-file {
    font-size: 1.5em;
    color: $green;
    margin-right: 15px;
  }
  .doc-name {
    color: #043863;
    font-weight: bold;
    text-transform: capitalize;
    line-height: 1.1em;
  }
  .doc-date {
    color: $grey-description;
    font-size: 0.9em;
    line-height: 1;
    margin-top: 5px;
  }

  .section-wrapper {
    &:last-of-type {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-grow: 1;
    }
    .content-box {
      padding: 0 30px;
      margin: 0 0 10px 0;
      h3 {
        border-bottom: 0.2em solid $green;
        display: inline-block;
        padding: 20px 10px 5px 0px;
        text-transform: uppercase;
        font-size: 16px;
      }

      h2 {
        color: $green;
      }

      &.new-content {
        padding: 0 30px 0 0;
        h3 {
          max-width: 100%;
          max-height: 3.5em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-wrap;
          padding-left: 1em;
          text-indent: -1em;
          i {
            margin-right: 5px;
          }
        }

        .box-content {
          width: 260px;
          font-size: 13px;
          padding: 10%;
          border-radius: 8px;
          background: $gray-cold2;
        }
      }
      @include mq("tablet-wide", "max") {
        &.new-content-box {
          max-width: 100%;
        }
      }
    }
  }
}
