@use "/src/styles/base/resources" as *;

.cc-input-search {
  .search-container {
    max-width: 360px;
    padding: 5px;
    height: 32px;
    background: $white;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .md-text-field-container {
      padding: 0;
    }
    .search-text-field {
      font-size: 1em;
      padding: 0;
      margin: 0;
      input {
        font-size: 100%;
      }
      i {
        font-size: 1.2em;
        transition: color 0.5s;
        &.md-text--theme-primary {
          color: $blue;
        }
      }
    }
  }
  &.cc-input-search-large {
    .search-container {
      max-width: 100%;
    }
  }

  &.inline {
    .search-container {
      width: 200px;
    }
  }

  .search-close-button {
    width: 24px;
    height: 24px;
    color: $gray-cold4;
    margin-right: 0.5em;

    .icon-x {
      font-size: 20px;
    }
  }
}
