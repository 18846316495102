@use "/src/styles/base/resources" as *;

.device-information-component {
  .device-details {
    .device-image {
      margin: 15px;
      margin-top: 6%;
    }

    .process-result-pass {
      border: 1px $gray3 solid;
      border-radius: 2px;
      background-color: $gray1;
      padding: 5px 30px;
      font-size: 26px;
      color: $green;
      margin: 15px;
    }

    .process-result-fail {
      border: 1px $gray3 solid;
      border-radius: 2px;
      background-color: $gray1;
      padding: 5px 30px;
      font-size: 26px;
      color: $red;
      margin: 15px;
    }

    .best-ID {
      border: 1px $gray3 solid;
      border-radius: 2px;
      background-color: $gray1;
      padding: 10px 20px;
      width: 250px;

      .section-title {
        margin: 3px;
        color: $primary1;
        font-size: 18px;
      }

      .best-ID-value {
        color: $gray8;
      }
    }

    .device-information-table {
      margin: 2%;
      .table-header {
        background-color: $primary1;
        .header-label {
          color: white;
          font-size: 18px;
        }
      }

      .table-body {
        .table-label {
          color: $primary1;
          font-size: 16px;
        }

        .table-data {
          color: $gray8;
          font-size: 16px;
        }
      }
    }

    .process-information-table {
      margin: 2%;
      .table-header {
        background-color: $primary1;
        .header-label {
          color: white;
          font-size: 18px;
        }
      }

      .table-body {
        .table-label {
          color: $primary1;
          font-size: 16px;
        }

        .table-data {
          color: $gray8;
          font-size: 16px;
        }
      }
    }

    .model-information-table {
      margin: 2%;
      .table-header {
        background-color: $primary1;
        .header-label {
          color: white;
          font-size: 18px;
        }
      }

      .table-body {
        .table-label {
          color: $primary1;
          font-size: 16px;
        }

        .table-data {
          color: $gray8;
          font-size: 16px;
        }
      }
    }
  }
}
