@use "/src/styles/base/resources" as *;

$fontsPath: "../fonts" !default;

$openSansFontPath: $fontsPath + "/opensans_regular/OpenSans-Regular-webfont";
$robotoFontPath: $fontsPath + "/roboto";
$titilliumFontPath: $fontsPath + "/titillium/titillium";

@font-face {
  font-family: "Open Sans";
  src: url("#{$openSansFontPath}.eot");
  src: url("#{$openSansFontPath}.woff") format("woff"),
    url("#{$openSansFontPath}.ttf") format("ttf");
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("#{$robotoFontPath}/roboto-v19-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("#{$robotoFontPath}/roboto-v19-latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("#{$robotoFontPath}/roboto-v19-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("#{$robotoFontPath}/roboto-v19-latin-500.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("#{$robotoFontPath}/roboto-v19-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("#{$robotoFontPath}/roboto-v19-latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Titillium";
  src: url("#{$titilliumFontPath}.eot");
  src: url("#{$titilliumFontPath}.woff") format("woff"),
    url("#{$titilliumFontPath}.ttf") format("ttf");
}

// Font styles
// Take the pain out of setting styles for a font. (This assumes you have already included the font), set your fallback font once and you never have to worry again.
@mixin font-source-sans($size: false, $colour: false, $weight: false, $lh: false) {
  font-family: "Roboto", "Open Sans", Helvetica, Arial, sans-serif;
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
}

html {
  font-size: 0.8em;
  @include mq("tablet") {
    font-size: 1em;
  }
  body {
    @include font-source-sans;
    font-size: 1em;
    h1,
    h2,
    h3,
    h4,
    p,
    ul,
    li,
    span,
    a {
      @include font-source-sans;
      padding: 0;
      text-decoration: none;
    }
    h1,
    h2,
    h3,
    h4 {
      margin: 0;
      padding: 0;
    }
    h1 {
      font-size: 2.3em;
    }
    h2 {
      font-size: 1.3em;
    }
    h3 {
      font-size: 1.17em;
    }
    h4 {
      line-height: 1.2em;
      font-size: 0.9em;
    }
    ul {
      list-style-type: none;
    }
    .inline-text {
      clear: both;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }
    .ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 1.5em;
    }
    .capitalize {
      text-transform: capitalize;
    }
  }
}
