@use "/src/styles/base/resources" as *;

.test-definition-fail-codes-selection-dialog {
  $border-color: #979797;
  $primary-column-background: #4e4e4e;
  $secondary-column-background: #696969;
  $tertiary-column-background: #848484;

  h2 {
    color: $white;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 5px;
  }

  .sections {
    min-height: 500px;

    > div:not(:last-of-type) {
      border-right: 1px solid $border-color;
    }
  }

  .col-categories {
    flex: 3;
    min-width: 350px;

    > h2 {
      background: $primary-column-background;
    }
  }

  .col-fail-codes {
    flex: 5;
    min-width: 470px;

    > h2 {
      background: $secondary-column-background;
    }
  }

  .col-tests {
    flex: 4;
    min-width: 300px;

    > h2 {
      background: $tertiary-column-background;
    }
  }
}
