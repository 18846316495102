@use "/src/styles/base/resources" as *;

.cc-toggle-btn {
  background: $white;
  color: $primary1;
  font-size: 16px;
  font-weight: bold;
  height: 35px;
  padding: 0 1em;
  border: 1px solid $primary1;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:first-child {
    border-radius: 3px 0 0 3px;
  }

  &:last-child {
    border-radius: 0 3px 3px 0;
  }

  &.is-active {
    background: $primary1;
    color: $white;
  }
}
