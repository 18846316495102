$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-file-text: "\e93d";
$icon-stats-bars: "\e905";
$icon-stats: "\e905";
$icon-statistics: "\e905";
$icon-chart: "\e905";
$icon-stats-bars1: "\e906";
$icon-stats1: "\e906";
$icon-statistics1: "\e906";
$icon-chart1: "\e906";
$icon-stats-dots: "\e907";
$icon-stats2: "\e907";
$icon-plot: "\e907";
$icon-statistics2: "\e907";
$icon-chart2: "\e907";
$icon-pie-chart: "\e908";
$icon-stats3: "\e908";
$icon-statistics3: "\e908";
$icon-graph: "\e908";
$icon-printer: "\e91e";
$icon-film: "\e91a";
$icon-video: "\e91a";
$icon-movie: "\e91a";
$icon-tape: "\e91a";
$icon-play: "\e91a";
$icon-film2: "\e91b";
$icon-video2: "\e91b";
$icon-movie2: "\e91b";
$icon-tape2: "\e91b";
$icon-play2: "\e91b";
$icon-text-color: "\e915";
$icon-pilcrow: "\e916";
$icon-barcode: "\e917";
$icon-users2: "\e909";
$icon-user-minus2: "\e90a";
$icon-user-plus2: "\e90b";
$icon-user2: "\e90c";
$icon-asci-logo: "\e90d";
$icon-empty-gear: "\e90e";
$icon-billing: "\e90f";
$icon-cam-manager: "\e910";
$icon-client-deployment: "\e911";
$icon-customer-manager: "\e912";
$icon-dashboard: "\e913";
$icon-docs: "\e914";
$icon-operator-manager: "\e918";
$icon-client-portal: "\e919";
$icon-user-management: "\e91d";
$icon-filled-gear: "\e91f";
$icon-test-editor: "\e920";
$icon-remote-testing: "\e923";
$icon-remote-editor: "\e924";
$icon-label-designer: "\e925";
$icon-asci-gear: "\e927";
$icon-spinner-10: "\e928";
$icon-expert-op: "\e92a";
$icon-junior-op: "\e92b";
$icon-probationary-op: "\e92c";
$icon-senior-op: "\e92d";
$icon-standard-op: "\e92e";
$icon-training-op: "\e92f";
$icon-search: "\f002";
$icon-user: "\f007";
$icon-th-large: "\f009";
$icon-th: "\f00a";
$icon-th-list: "\f00b";
$icon-check: "\f00c";
$icon-close: "\f00d";
$icon-search-plus: "\f00e";
$icon-search-minus: "\f010";
$icon-power-off: "\f011";
$icon-cog: "\f013";
$icon-home: "\f015";
$icon-file-o: "\f016";
$icon-clock-o: "\f017";
$icon-download: "\f019";
$icon-arrow-circle-o-down: "\f01a";
$icon-arrow-circle-o-up: "\f01b";
$icon-play-circle-o: "\f01d";
$icon-repeat: "\f01e";
$icon-refresh: "\f021";
$icon-lock: "\f023";
$icon-align-left: "\f036";
$icon-align-center: "\f037";
$icon-align-right: "\f038";
$icon-align-justify: "\f039";
$icon-list: "\f03a";
$icon-dedent: "\f03b";
$icon-indent: "\f03c";
$icon-video-camera: "\f03d";
$icon-image: "\f03e";
$icon-share-square-o: "\f045";
$icon-check-square-o: "\f046";
$icon-arrows: "\f047";
$icon-step-backward: "\f048";
$icon-fast-backward: "\f049";
$icon-backward: "\f04a";
$icon-play22: "\f04b";
$icon-pause: "\f04c";
$icon-stop: "\f04d";
$icon-forward: "\f04e";
$icon-fast-forward: "\f050";
$icon-step-forward: "\f051";
$icon-eject: "\f052";
$icon-chevron-left: "\f053";
$icon-chevron-right: "\f054";
$icon-plus-circle: "\f055";
$icon-minus-circle: "\f056";
$icon-times-circle: "\f057";
$icon-check-circle: "\f058";
$icon-question-circle: "\f059";
$icon-info-circle: "\f05a";
$icon-times-circle-o: "\f05c";
$icon-check-circle-o: "\f05d";
$icon-ban: "\f05e";
$icon-arrow-left: "\f060";
$icon-arrow-right: "\f061";
$icon-arrow-up: "\f062";
$icon-arrow-down: "\f063";
$icon-mail-forward: "\f064";
$icon-expand: "\f065";
$icon-compress: "\f066";
$icon-plus: "\f067";
$icon-minus: "\f068";
$icon-exclamation-circle: "\f06a";
$icon-eye: "\f06e";
$icon-eye-slash: "\f070";
$icon-exclamation-triangle: "\f071";
$icon-calendar: "\f073";
$icon-random: "\f074";
$icon-chevron-up: "\f077";
$icon-chevron-down: "\f078";
$icon-retweet: "\f079";
$icon-folder: "\f07b";
$icon-folder-open: "\f07c";
$icon-arrows-v: "\f07d";
$icon-arrows-h: "\f07e";
$icon-cogs: "\f085";
$icon-sign-out: "\f08b";
$icon-external-link: "\f08e";
$icon-sign-in: "\f090";
$icon-upload: "\f093";
$icon-square-o: "\f096";
$icon-bookmark-o: "\f097";
$icon-unlock: "\f09c";
$icon-arrow-circle-left: "\f0a8";
$icon-arrow-circle-right: "\f0a9";
$icon-arrow-circle-up: "\f0aa";
$icon-arrow-circle-down: "\f0ab";
$icon-globe: "\f0ac";
$icon-wrench: "\f0ad";
$icon-tasks: "\f0ae";
$icon-briefcase: "\f0b1";
$icon-arrows-alt: "\f0b2";
$icon-group: "\f0c0";
$icon-chain: "\f0c1";
$icon-cloud: "\f0c2";
$icon-paperclip: "\f0c6";
$icon-floppy-o: "\f0c7";
$icon-square: "\f0c8";
$icon-bars: "\f0c9";
$icon-list-ul: "\f0ca";
$icon-list-ol: "\f0cb";
$icon-caret-down: "\f0d7";
$icon-caret-up: "\f0d8";
$icon-caret-left: "\f0d9";
$icon-caret-right: "\f0da";
$icon-sort: "\f0dc";
$icon-sort-desc: "\f0dd";
$icon-sort-asc: "\f0de";
$icon-envelope: "\f0e0";
$icon-rotate-left: "\f0e2";
$icon-sitemap: "\f0e8";
$icon-clipboard: "\f0ea";
$icon-exchange: "\f0ec";
$icon-cloud-download: "\f0ed";
$icon-cloud-upload: "\f0ee";
$icon-file-text-o: "\f0f6";
$icon-plus-square: "\f0fe";
$icon-angle-left: "\f104";
$icon-angle-right: "\f105";
$icon-angle-up: "\f106";
$icon-angle-down: "\f107";
$icon-circle-o: "\f10c";
$icon-circle: "\f111";
$icon-mail-reply: "\f112";
$icon-folder-o: "\f114";
$icon-folder-open-o: "\f115";
$icon-mail-reply-all: "\f122";
$icon-question: "\f128";
$icon-info: "\f129";
$icon-exclamation: "\f12a";
$icon-calendar-o: "\f133";
$icon-chevron-circle-left: "\f137";
$icon-chevron-circle-right: "\f138";
$icon-chevron-circle-up: "\f139";
$icon-chevron-circle-down: "\f13a";
$icon-unlock-alt: "\f13e";
$icon-ellipsis-h: "\f141";
$icon-ellipsis-v: "\f142";
$icon-play-circle: "\f144";
$icon-minus-square: "\f146";
$icon-minus-square-o: "\f147";
$icon-check-square: "\f14a";
$icon-external-link-square: "\f14c";
$icon-share-square: "\f14d";
$icon-file: "\f15b";
$icon-file-text-1: "\f15c";
$icon-sort-alpha-asc: "\f15d";
$icon-sort-alpha-desc: "\f15e";
$icon-sort-amount-asc: "\f160";
$icon-sort-amount-desc: "\f161";
$icon-sort-numeric-asc: "\f162";
$icon-sort-numeric-desc: "\f163";
$icon-long-arrow-down: "\f175";
$icon-long-arrow-up: "\f176";
$icon-long-arrow-left: "\f177";
$icon-long-arrow-right: "\f178";
$icon-arrow-circle-o-right: "\f18e";
$icon-arrow-circle-o-left: "\f190";
$icon-caret-square-o-left: "\f191";
$icon-dot-circle-o: "\f192";
$icon-plus-square-o: "\f196";
$icon-file-pdf-o: "\f1c1";
$icon-file-word-o: "\f1c2";
$icon-file-excel-o: "\f1c3";
$icon-file-powerpoint-o: "\f1c4";
$icon-file-image-o: "\f1c5";
$icon-file-archive-o: "\f1c6";
$icon-file-audio-o: "\f1c7";
$icon-file-movie-o: "\f1c8";
$icon-file-code-o: "\f1c9";
$icon-circle-o-notch: "\f1ce";
$icon-history: "\f1da";
$icon-circle-thin: "\f1db";
$icon-sliders: "\f1de";
$icon-at: "\f1fa";
$icon-mouse-pointer: "\f245";
$icon-hand-grab-o: "\f255";
$icon-hand-stop-o: "\f256";
$icon-hand-pointer-o: "\f25a";
$icon-registered: "\f25d";
$icon-creative-commons: "\f25e";
$icon-television: "\f26c";
$icon-calendar-plus-o: "\f271";
$icon-calendar-minus-o: "\f272";
$icon-calendar-times-o: "\f273";
$icon-calendar-check-o: "\f274";
$icon-question-circle-o: "\f29c";
$icon-times-rectangle: "\f2d3";
$icon-times-rectangle-o: "\f2d4";
$icon-apple: "\e929";
$icon-brand: "\e929";
$icon-android: "\e930";
$icon-brand1: "\e930";
$icon-os: "\e930";
$icon-mobile: "\e930";
$icon-copyright: "\f1f9";
$icon-hour-glass: "\e979";
$icon-terminal: "\ea81";
$icon-mail4: "\ea86";
$icon-tux: "\eabd";
$icon-appleinc: "\eabe";
$icon-windows8: "\eac2";
$icon-file-c: "\e921";
$icon-file-java: "\e922";
$icon-archive: "\e93b";
$icon-unarchive: "\e93c";
$icon-x: "\e93a";
$icon-border_left: "\e933";
$icon-cell-phone: "\e900";
$icon-code: "\e901";
$icon-pencil: "\e902";
$icon-trash: "\e903";
$icon-copy: "\e904";
$icon-csv-json: "\e91c";
$icon-border_color: "\e926";
$icon-empty-data: "\e931";
$icon-template: "\e932";
$icon-align-vertical-middle: "\e934";
$icon-align-horizontal-middle: "\e935";
$icon-align-top: "\e936";
$icon-align-left1: "\e937";
$icon-align-bottom: "\e938";
$icon-align-right1: "\e939";

