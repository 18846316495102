@use "/src/styles/base/resources" as *;

#app-wrapper {
  .main-container {
    .time-picker {
      .md-dialog {
        max-width: 600px;
        max-height: calc(100vh - 10px);

        .origin-date-indicator {
          font-size: 16px;
          width: 100%;
          text-align: center;
          margin-bottom: 15px;
        }
        .margin-error {
          margin-top: 15px;
        }
      }

      // timekeeper custom styling
      --hand-line-color: #005288;
      --hand-circle-center: #005288;
      --hand-circle-outer: #e6e6e6;
      --hand-minute-circle: #99cc33;
      --clock-wrapper-bg: #e6e6e6;
      --meridiem-selected-bg-color: #005288;
      --meridiem-selected-text-color: #fff;
      --main-font-family: "open sans", sans-serif;
      --top-bg: #005288;
      --top-text-color: #fff;
      --top-colon-color: #fff;
      --top-meridiem-color: #fff;
      --top-selected-color: #99cc33;
    }
  }
}
