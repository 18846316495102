@use "/src/styles/base/resources" as *;

.cc-table {
  $border-color: #e1e1e1;
  $header-text-color: #005288;
  background-color: white;
  width: stretch;

  &.main-information {
    table {
      border: none;
      tr {
        &:nth-of-type(even),
        &:nth-of-type(odd) {
          background-color: $old-primary7;
          border: none;
          &:hover {
            background-color: $old-primary7;
          }
        }
        td {
          padding-left: 30px;
        }
      }
    }
  }

  &.first-td-bold {
    td:first-of-type {
      color: $old-primary1;
      font-weight: bold;
    }
  }

  table {
    border: 1px solid $border-color;

    thead {
      tr {
        background-color: $border-color;
        border-bottom: 1px solid darken($border-color, 5%);

        th {
          color: $header-text-color;
          font-weight: bold;
          font-size: 0.8em;

          &.sortable {
            &:hover {
              cursor: pointer;
            }

            &::after {
              font-family: "icomoon";
              margin-left: 8px;
            }
          }

          &.is-sorted {
            &::after {
              content: "\F0D8";
            }

            &.sort-reverse {
              &::after {
                content: "\F0D7";
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-of-type(even) {
          background-color: lighten($border-color, 8%);
          &.row-modified,
          &.row-modified:hover {
            background: lighten($color: $blue, $amount: 50);
          }
        }

        &:nth-of-type(odd) {
          &.row-modified,
          &.row-modified:hover {
            background: lighten($color: $blue-sky, $amount: 50);
          }
        }

        &:hover:not(.message-empty) {
          background-color: lighten($border-color, 4%);
        }

        td {
          height: 4em;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.message-empty td {
          text-align: center;
          color: darken($border-color, 4%);
        }

        .clickable {
          &:hover {
            cursor: pointer;
          }

          &.link,
          .link {
            color: $blue;
            text-decoration: underline;
          }
        }
      }
    }

    th,
    td {
      padding: 5px;
      &:first-of-type {
        padding-left: 24px;
      }
    }
  }
}

@media print {
  .cc-table table tbody tr td,
  .cc-table table thead tr th,
  .cc-table table thead tr th.sortable {
    font-weight: normal;
  }

  .cc-table {
    print-color-adjust: exact;

    table {
      border: none;
      .md-table-header {
        .md-table-row {
          background: lighten($grey-ligth, 5%);
        }

        th,
        th.sortable,
        .md-table-header {
          font-size: 12px;
          height: 2em;
          min-height: 2em;
          color: $black;
          white-space: normal;
        }
      }
    }

    .md-table-body,
    tfoot {
      .md-table-row {
        th {
          font-size: 14px;
          height: 2em;
          min-height: 2em;
          color: $black;
          border: none;
        }

        td {
          font-size: 12px;
          height: 2em;
          min-height: 2em;
          color: $black;
        }

        &:not(.md-table-header) {
          &:nth-child(even) {
            background: lighten(#000, 95%);
          }
        }
      }
    }

    .md-table-header {
      .md-table-row {
        background: $grey;
      }
    }

    &.main-information {
      table {
        .md-table-body {
          tr.md-table-row {
            &:nth-of-type(even),
            &:nth-of-type(odd) {
              background-color: $white;
            }
          }
        }
      }
    }
  }
}

.asci-table {
  display: table;
  background-color: $white; // default width of the table
  width: stretch;
  font-size: 0.8em; // when size is bigger than tablet, the grid is 100% width;
  @include mq("tablet") {
    width: 100%;
    font-size: 16px;
  }
  .toolbar-header {
    background-color: $blue;
    color: $white;
    @include flexbox;
    @include flex-direction;
    @include justify-content(space-between);
    @include align-items(center);
    padding: 1em;
  }

  .asci-table-row {
    text-align: center;
    display: table-row;
    .asci-table-cell {
      display: table-cell;
      padding-right: 5px;
      height: 4em;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;

      &.lg-cell {
        width: 180px;
        max-width: 180px;
      }
      &.md-cell {
        width: 120px;
        max-width: 120px;
      }
      &.sm-cell {
        width: 65px;
        max-width: 65px;
      }
      &.xs-cell {
        width: 55px;
        max-width: 60px;
        padding: 0;
      }
      & > div {
        word-wrap: break-word;
      }
    }

    &:not(.asci-table-header) {
      &:nth-child(even) {
        background: $white-dirty;
        &:hover {
          background: darken($color: $grey-ligth, $amount: 5);
        }
      }
    }
  }

  .asci-table-header {
    background-color: $grey-header;
    color: $blue;
    font-weight: bold;
    th {
      padding-right: 5px;

      &.lg-cell {
        width: 180px;
        max-width: 180px;
      }
      &.md-cell {
        width: 120px;
        max-width: 120px;
      }
      &.sm-cell {
        width: 65px;
        max-width: 65px;
      }
      &.xs-cell {
        width: 55px;
        max-width: 60px;
        padding: 0;
      }
    }
  }
}

tbody.asci-table {
  border: 1px solid $grey-ligth;
  border-top: 0;
}
