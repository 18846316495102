@use "/src/styles/base/resources" as *;

.workflow-event-editor {
  .workflow-event-editor-title {
    background-color: #f3f3f3;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    .title-label {
      font-weight: bold;
    }
  }
  .workflow-event-editor-body {
  }
  margin-bottom: 0.5rem;
}
.event-description {
  background: white;
  border-radius: 3px;
  border: 1px solid #dedede;
  padding: 0.5rem;
  font-style: italic;
  color: #8b8b8b;
}
