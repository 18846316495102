@use "/src/styles/base/resources" as *;

.new-service-dialog {
  .className {
    margin-bottom: 10px;
    .className-label {
      font-size: 15px;
      color: $gray8;
      line-height: 35px;
      margin-right: 6px;
      padding-right: 12px;
    }

    .className-field {
      margin-right: 5px;
      border: 0.5px solid $gray2;
      border-radius: 3px;
      width: 170px;
      height: 35px;
      min-width: 100px;
      background-color: $gray1;
      padding-left: 5px;
      position: relative;
      font-size: 16px;

      .md-divider {
        display: none;
      }
      .md-text {
        font-size: 15px;
        margin-top: 10px;
        padding-left: 3px;
        color: $gray8;
      }

      .md-text-field-icon {
        display: none;
        line-height: 0px;
        position: absolute;
        margin-left: 140px;
        font-size: 15px;
        color: $gray5;
      }
    }
  }

  .displayName {
    .displayName-label {
      font-size: 15px;
      color: $gray8;
      line-height: 35px;
      margin-right: 6px;
    }

    .displayName-field {
      margin-right: 5px;
      border: 0.5px solid $gray2;
      border-radius: 3px;
      width: 170px;
      height: 35px;
      min-width: 100px;
      background-color: $gray1;
      padding-left: 5px;
      position: relative;
      font-size: 16px;

      .md-divider {
        display: none;
      }
      .md-text {
        font-size: 15px;
        margin-top: 10px;
        padding-left: 3px;
        color: $gray8;
      }

      .md-text-field-icon {
        display: none;
        line-height: 0px;
        position: absolute;
        margin-left: 140px;
        font-size: 15px;
        color: $gray5;
      }
    }
  }

  .is-enabled {
    margin-top: 4px;
    .is-enabled-label {
      font-size: 15px;
      color: $gray8;
      line-height: 40px;
      margin-right: 6px;
      padding-right: 2px;
    }
  }
  .is-optional {
    .is-optional-label {
      font-size: 15px;
      color: $gray8;
      line-height: 40px;
      margin-right: 6px;
    }
  }
}

.btn-group {
  justify-content: flex-end;

  .asci-btn.green-button.confirm-btn {
    box-shadow: none;
    text-shadow: none;
    padding: 0 10px;
    min-width: 70px;
    min-height: 35px;
    border-radius: 4px;
  }

  .asci-btn.white-button.cancel-btn {
    background-color: $gray1;
    box-shadow: none;
    text-shadow: none;
    padding: 0 10px;
    min-width: 70px;
    min-height: 35px;
    border-radius: 4px;

    &:hover:not([disabled]) {
      background-color: $gray3;
    }
  }
}
