@use "/src/styles/base/resources" as *;

footer {
  z-index: 1;
  .footer-container {
    padding: 1.66rem;
    @include flexbox;
    @include flex-flow(row nowrap);
    @include justify-content(space-between);
    @include linear-gradient($dark-color);
    height: $footer-height;
    .footer-left {
      @include align-items(center);
      @include flexbox;
      @include flex(8);
      @include flex-flow(row nowrap);
      @include justify-content(flex-start);
      .footer-label-display {
        @include flex(1 0 auto);
      }
      .logo-icon {
        font-size: 32px;
        color: $white;
        text-shadow: 0px 1px 1px black;
        margin-right: 1rem;
      }
    }
    .footer-middle {
      @include align-items(center);
      @include flexbox;
      @include flex(1);
      @include flex-flow(row nowrap);
      @include justify-content(center);
    }
    .footer-right {
      @include align-items(center);
      @include flexbox;
      @include flex(2);
      @include flex-flow(row nowrap);
      @include justify-content(flex-end);
      .language-icon {
        font-size: 20px;
        color: white;
        margin: 0.5em;
      }
    }
    .footer-copyright {
      @include align-items(center);
      @include flexbox;
      @include flex-flow(row nowrap);
      color: $white-dirty;
      height: 1.665rem;
      div:first-child {
        padding-right: 1em;
      }
    }
  }
}
