@use "/src/styles/base/resources" as *;

.delete-errors-container {
  max-height: 80vh;
  overflow: auto;
  .delete-error-detail {
    margin: 5px 0;
    & b:first-of-type {
      color: $blue;
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid $grey;
    }
  }
  .delete-error-fail-code-container {
    padding: 10px 5px;
    .associated-tests-container {
      padding: 10px;
      margin: 5px 0 0 0;
      border: 1px solid $grey-header;
      border-radius: 5px;
      .associated-tests-title {
        font-weight: bold;
      }
      .associated-tests-header {
        margin: 5px 0;
        border-bottom: 1px solid $grey-header;
        border-top: 1px solid $grey-header;
        padding: 5px 0;
        & > div:last-of-type {
          text-align: right;
        }
      }
      .associated-tests-body {
        color: $grey-description;
        align-items: center;
        margin: 5px 0;
        & > div:last-of-type {
          text-align: right;
        }
      }
    }
  }
}
