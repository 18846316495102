@use "/src/styles/base/resources" as *;

.developers-zone-page {
  .panel-container.developers-zone {
    margin-left: 1em;
    background: $gray-bg;
    .cc-section-header {
      box-shadow: $glow-shadow;
      border-radius: 6px 6px 0 0;
    }
    .body-wrapper {
      box-shadow: $glow-shadow;
      background: white;
      border-radius: 0 0 6px 6px;
    }
  }
}
