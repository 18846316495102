@use "/src/styles/base/resources" as *;

.test-definitions-simple-view {
  $border-color: #979797;

  .selection-columns {
    .categories-container {
      flex: 0 1 300px;
    }

    .tests-container {
      flex: 1 1 auto;
      min-width: 600px;
      border-right: 0 !important;
    }
  }

  .col-icon {
    flex: 0 0 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .col-name {
    flex: 1 1 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .col-configs {
    flex: 1 1 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .col-fail-codes {
    flex: 1 1 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .col-inclusions {
    flex: 1 1 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .col-arrow {
    flex: 0 0 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .column-headers {
    background: #b2b2b2;
    color: $blue;
    font-weight: bold;

    > div {
      padding: 5px;
    }

    .col-configs,
    .col-fail-codes,
    .col-inclusions {
      text-align: center;
    }
  }

  .test-rows {
    .simple-test-row {
      padding: 5px 0;
      border-bottom: 1px solid $border-color;
      position: relative;

      &.can-expand {
        padding-bottom: 20px;

        &.expanded {
          .toggle-expand::after {
            content: "\25b2";
          }
        }

        &:not(.expanded) {
          .col-configs,
          .col-fail-codes,
          .col-inclusions > div:last-of-type {
            position: relative;

            &::after {
              position: absolute;
              content: "...";
              bottom: -15px;
              left: 0;
              right: 0;
              text-align: center;
            }
          }
        }
      }
    }

    .toggle-expand {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: white;
      border: 1px solid $border-color;
      color: $blue;
      border-bottom: 0;
      text-align: center;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      width: 100px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      cursor: pointer;
      z-index: 1;

      &::after {
        position: relative;
        content: "\25bc";
        margin-left: 5px;
        z-index: -1;
      }
    }

    .col-name {
      color: #5a5a5a;
      line-height: 18px;
      font-size: 14px;
    }

    .col-configs,
    .col-fail-codes {
      padding: 0 10px;
      text-align: center;
    }

    .col-inclusions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 10px;

      .all {
        color: $grey;
        text-align: center;
      }
    }

    .col-arrow {
      .md-icon {
        color: #b3b3b3;
        font-size: 18px;
        cursor: pointer;
      }
    }

    .col-configs {
      .test-configs {
        cursor: pointer;
        padding: 0 0.5em;
        max-width: 300px;

        &:hover {
          background: $gray-cold1;
        }

        .ellipsis {
          line-height: inherit;
        }

        .md-icon {
          color: $primary1;
          font-size: 12px;
          margin-left: 12px;
        }
      }
    }
  }
}
