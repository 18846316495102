@use "/src/styles/base/resources" as *;

// flex: [flex-grow] [flex-shrink] [flex-basis]
// justify-content: main axis
// aling-items: cross axis

.row {
  @include flexbox;
  @include flex-flow(row nowrap);
}

.row-wrap {
  @include flexbox;
  @include flex-flow(row wrap);
}

.column {
  @include flexbox;
  @include flex-flow(column nowrap);
}

.column-wrap {
  @include flexbox;
  @include flex-flow(column wrap);
}

.centered {
  @include align-items(center);
  @include justify-content(center);
}

.spaced-centered {
  @include align-items(center);
  @include justify-content(space-between);
}

.spaced {
  @include justify-content(space-between);
}

.base-aligned {
  align-items: baseline;
}

.space-around {
  @include justify-content(space-around);
}

.to-end {
  @include justify-content(flex-end);
}

.text-centered {
  margin: auto;
  text-align: center;
  & > button {
    margin: auto;
  }
  a > button {
    margin: auto;
  }
}

.flow-centered {
  @include align-items(center);
}

.text-flow-centered {
  margin: auto 0;
}

.flow-align-start {
  @include align-items(flex-start);
}

.flow-align-end {
  @include align-items(flex-end);
}

.grow-noshrink {
  @include flex(1 0 auto);
}

.grow-shrink {
  @include flex(1 1 auto);
}

.nogrow-shrink {
  @include flex(0 1 auto);
}

.nogrow-noshrink {
  @include flex(0 0 auto);
}

.flex {
  @include flex(1);
}

.md-no-scroll {
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
}

.asci-hide {
  opacity: 0;
  pointer-events: none;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: $grey-ligth;
  overflow-x: hidden;
  overflow-y: auto;
}

html {
  body {
    #app-wrapper {
      background: $gray-bg;
      overflow: hidden;

      .main-container {
        margin: 0;
        padding: 0;
        display: flex;
        min-height: 100vh;
        flex-direction: column;

        .content-container {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          .panel-container {
            width: 100%;
            overflow: visible;

            &:not(.home-page) {
              max-width: 1400px;
              padding: 32px 32px 30px 32px;
              &:last-child {
                padding-bottom: 80px;
              }
            }
          }

          .content-footer {
            @extend .row;
            @extend .flow-centered;
            @extend .spaced;
            @extend .centered;
            padding: 1em;
            background: $grey;

            &.push-right {
              @include justify-content(flex-end);
            }

            p {
              margin: 0;
            }

            .asci-btn {
              &:last-of-type {
                margin-right: 0;
              }
            }
          }

          .row-reverse {
            flex-direction: row-reverse;
          }

          .white-background {
            padding: 1em;
            background: lighten($grey-header, 3);
            color: $dark-color;
            border-bottom: 1px solid $grey;
          }

          .grey-border {
            border: 1px solid $grey;
          }

          .text-right {
            text-align: right;

            & > button {
              margin-left: auto;
            }
          }

          .full-height {
            height: 100%;
          }

          .min-margin {
            margin: 0 5px;
          }
        }

        .horizontal-label-margin {
          margin-right: 10px;
        }

        .info-message {
          color: $dark-color;
          line-height: 1.42857;
          font-size: 14px;
        }

        .instructions-list {
          ol,
          ul {
            li {
              margin: 1em 0;
            }
          }
        }

        .error-message {
          color: #f44336;
          line-height: 1.42857;
          font-size: 12px;

          &--large {
            font-size: 18px;
            @extend .error-message;
          }
        }

        .no-data-message {
          display: flex;
          flex-flow: row;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          height: 4em;
          padding: 5px;
        }

        [class*="-disabled"] {
          cursor: not-allowed;
        }

        .clickable-text {
          color: $blue;
          text-decoration: underline;
          &:hover {
            cursor: pointer;
          }
        }

        .popover-clickable-text {
          color: $blue;
          font-weight: bold;
          &:hover {
            cursor: pointer;
          }
        }

        .full-width {
          width: 100%;
        }
        .fit-content {
          min-width: fit-content;
        }
      }
      .borderless {
        border: none;
      }
      .border-radiusless {
        border-radius: 0;
      }
    }
  }
}

.paper-row {
  overflow: auto;
  min-height: 60px;
  padding: 10px;
  margin: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.5s ease-in-out;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
  &:active:after {
    padding: 0;
    margin: 0;
    opacity: 0.5;
    transition: 0s;
  }

  &:hover,
  &.active {
    box-shadow: -1px 4px 5px -1px rgba(0, 0, 0, 0.5);
  }
  i {
    font-size: 24px;
    margin: 5px;
  }
}

.paper-button {
  margin: 15px;
  padding: 15px;
  height: 182px;
  width: 203px;
  border-radius: 2px;
  transition: box-shadow 0.5s ease-in-out;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  color: $blue;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  position: relative;
  overflow: hidden;
  user-select: none;
  i {
    font-size: 58px;
    color: $gray6;
    margin: 0 0 30px 0;
  }

  &:not(.disabled) {
    &:after {
      content: "";
      background: $gray2;
      display: block;
      position: absolute;
      padding-top: 300%;
      padding-left: 350%;
      margin-left: -20px !important;
      margin-top: -120%;
      opacity: 0;
      bottom: 0;
      transition: all 1s;
    }

    &:active:after {
      padding: 0;
      margin: 0;
      opacity: 0.5;
      transition: 0s;
    }

    &:hover,
    &.active {
      box-shadow: -1px 10px 18px -6px rgba(0, 0, 0, 0.85);
    }
  }
  &.disabled {
    background: $gray1;
    color: $gray6;
  }
}
