@use "/src/styles/base/resources" as *;

// Remove the autofill background color on input fields
// kind of a hack, nothing else seems to work
// see https://stackoverflow.com/a/37432260
@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}

.cc-static-field {
  input.md-text-field {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background: none;
      background-color: transparent !important;
      box-shadow: 0 0 0px 1000px transparent inset;
      -webkit-animation-name: autofill;
      -webkit-animation-fill-mode: both;
    }
  }
}
