@use "/src/styles/base/resources" as *;

.asci-search {
  margin: 0 10px;
  .search-container {
    padding: 5px;
    width: 200px;
    min-height: 2em;
    background: $white;
    border-radius: 5px;
    @include flexbox;
    @include align-content(space-between);
    @include justify-content(center);
    .md-text-field-container {
      padding: 0;
    }
  }
}
