@use "/src/styles/base/resources" as *;

.deployments-page {
  .deployment {
    box-shadow: $glow-shadow;
    .cc-section-header {
      border-radius: 8px 8px 0 0;
    }
  }
}
