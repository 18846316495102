@use "/src/styles/base/resources" as *;

.details-field {
  display: flex;
  padding: 0.25rem;
  align-items: center;
  .details-field-label {
    width: 50%;
    font-weight: bold;
    user-select: none;
  }
  .details-field-value {
    width: 50%;
    line-break: strict;
    > *,
    input {
      width: 100%;
    }
  }
}
