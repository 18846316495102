@use "/src/styles/base/resources" as *;

.session-recording {
  display: flex;

  flex-wrap: nowrap;

  align-items: center;
  justify-content: space-between;
  color: #3d3d3d;

  padding: 0.25rem 1rem;

  &:hover {
    color: #3d3d3d;
  }

  .brand-icon i {
    font-size: 2rem;
    margin-right: 1rem;
  }

  .session-recording-field {
    font-size: 0.85rem;
    display: flex;
    flex-wrap: nowrap;

    .session-recording-field-label {
      font-weight: bold;
      margin-right: 0.25rem;
    }
  }
  .session-recording-model-info {
    display: flex;
  }
  .session-recording-result {
    font-size: 2rem;
    &.pass {
      color: green;
    }
    &.fail {
      color: red;
    }
  }
  .session-recording-id {
    font-size: 2rem;
  }

  .double-rows {
    > :first-child {
      border-bottom: #dedede;
    }
  }
}
