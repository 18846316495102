@use "/src/styles/base/resources" as *;

.cc-panel {
  $border-color: $blue;

  border: 1px solid $border-color;
  padding: 10px;
  position: relative;
  margin: 15px 5px 10px;

  > h2:first-of-type {
    color: $border-color;
    font-size: 16px;
    line-height: 10px;
    position: absolute;
    top: 0;
    left: 10px;
    transform: translateY(-50%);
    background-color: white;
    padding: 0 5px;
  }
}
