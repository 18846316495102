@use "/src/styles/base/resources" as *;

.image-gallery {
  $max-image-size: 120px;
  $grid-gap: 10px;
  $row-count: 3;
  $col-count: 3;
  $selected-border-size: 3px;

  .images {
    display: grid;
    grid-template-columns: repeat($col-count, 1fr);
    grid-template-rows: $max-image-size;
    grid-column-gap: $grid-gap;
    grid-row-gap: $grid-gap;
    justify-items: center;
    align-items: center;
    overflow-y: scroll;
    height: ($row-count * $max-image-size) + ($row-count - 1) * ($grid-gap);
    margin-bottom: 10px;

    > div {
      width: $max-image-size;
      height: $max-image-size;
      position: relative;

      img {
        max-width: $max-image-size;
        max-height: $max-image-size;
        border: $selected-border-size solid transparent;
        display: block;

        &:hover {
          cursor: pointer;
        }
      }

      &.selected {
        img {
          border-color: $blue;
        }

        &::after {
          font-family: "icomoon";
          position: absolute;
          top: 5px;
          right: 5px;
          content: "\f00c";
          color: white;
          background: $green;
          border-radius: 50%;
          font-size: 22px;
          height: 30px;
          width: 30px;
          line-height: 30px;
          text-align: center;
        }
      }
    }
  }
}
