@use "/src/styles/base/resources" as *;

.cc-content-panel {
  border: 1px solid #eeeeee;

  > header {
    background-color: $blue;
    color: $white;
    padding: 10px;
    font-size: 14px;

    .md-btn--icon {
      color: white;
    }
  }

  > footer {
    background-color: #cacaca;
    padding: 10px;
  }
}
