@use "/src/styles/base/resources" as *;

.status-container {
  align-items: center;
  &.status-pass {
    color: $green;
  }
  &.status-fail {
    color: $red;
  }
  &.status-skipped {
    color: $blue;
  }
  .status-icon {
    margin-right: 5px;
  }
  &.status-crash {
    color: $red;
  }
  &.status-unsupported {
    color: $blue;
  }
  &.status-incomplete {
    color: $blue;
  }
  &.status-retry {
    color: $blue;
  }
}
