@use "/src/styles/base/resources" as *;

.analytics-button {
  width: 298px;
  border-radius: 6px;
  background: $white;
  box-shadow: 0px 0px 60px #0000001a;

  .app-preview {
    height: 238px;

    img {
      max-width: 100%;
      max-height: 100%;
      padding: 24px;
    }
  }

  .label-container {
    height: 80px;
    border-radius: 0 0 5px 5px;
    background: $gray-cold2;
    padding: 1em;
    text-align: center;

    .label {
      color: $primary1;
      font-size: 20px;
      font-weight: 500;
    }

    .secondary-label {
      color: $gray8;
      font-size: 18px;
      font-style: italic;
    }
  }
}
