@use "/src/styles/base/resources" as *;

.test-definition-inclusion-editor {
  .include-all {
    border: 1px solid #ccc;
    padding: 5px 10px;
  }

  .include-exclude {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.87);

    .md-switch-track--on {
      background: change-color($good1, $alpha: 0.5);

      .md-switch-thumb--on {
        background: $good1;
      }
    }

    .md-switch-track--off {
      background: change-color($bad1, $alpha: 0.5);

      .md-switch-thumb--off {
        background: $bad1;
      }
    }
  }

  .target {
    border: 1px solid #ccc;
    padding: 5px 10px;
  }

  .col-checkbox {
    flex: 0 0 40px;
  }

  .col-model {
    flex: 1 1 auto;
  }
}
