@use "/src/styles/base/resources" as *;

.asci-select {
  $select-border-color: $dark-color !default;
  $select-font-size: 1em;
  $select-color: $dark-color !default;
  $select-padding: 4px;

  min-width: 50px;
  position: relative;

  &.enabled {
    &:after {
      padding: 5px;
      left: -1em;
      top: -1px;
      position: relative;
      font-family: "icomoon";
      content: "\f0d7";
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      pointer-events: none;
    }

    .browser-default {
      border-bottom: 1px solid $select-border-color;
    }
  }

  .browser-default {
    appearance: none;
    font-family: inherit;
    background-color: transparent;
    width: 100%;
    min-width: 100%;
    padding: $select-padding 15px $select-padding 0;
    font-size: $select-font-size;
    color: $select-color;
    border: none;
    border-radius: 0px;

    /* Remove focus */
    &:focus {
      outline: none;
      appearance: none;
      text-indent: 0.01px;
      text-overflow: "";
      border-bottom: 2px solid $black;
    }
  }
  &.error-true {
    .browser-default {
      border-bottom: 1px solid #f44336;
    }
    &:after {
      color: #f44336;
    }
  }
}
