@use "/src/styles/base/resources" as *;

.label-designer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .label-designer-header {
    background-color: #e9e9e9;
    border-bottom: #e1e1e1 solid 1px;
    padding: 0.5rem;

    .header-field {
      display: flex;
      margin-right: 1rem;

      .header-label {
        font-weight: bold;
        margin-right: 0.5rem;
      }
      .header-input {
        width: 6rem;
      }

      &:first-child .header-input {
        width: 12rem;
        text-align: start;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
      }
    }

    .save-btn,
    .print-btn {
      color: white;
      background-color: #005288;
      font-weight: bold;
      padding: 0.5rem 1rem;
      &:hover {
        background-color: lighten(#005288, 10);
      }
      &:disabled {
        background-color: #3c3f41;
      }
    }
  }
  .label-designer-spacer {
    flex-grow: 1;
    width: 0;
  }

  .label-designer-body {
    display: flex;
    flex-grow: 1;

    .label-editor {
      width: 70%;
    }
    .label-inspector {
      width: 30%;
    }

    .label-loading {
      width: 100%;
      height: 100%;
    }
  }
}
