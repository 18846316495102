@use "/src/styles/base/resources" as *;

.cc-section-header {
  min-height: 60px;
  background-color: $blue;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 1em;

  &.table-header,
  &.table-header.md-panel-header {
    font-size: 1em;
    color: #212121;
    background: $primary3;
    margin-top: 1em;
    padding-left: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  .title {
    font-size: 1em;

    span:first-of-type {
      font-weight: bold;
    }

    .description {
      &::before {
        content: " - ";
        width: 1em;
        padding: 0 2px;
      }
    }
  }

  > button {
    margin: 0 !important;
  }

  .asci-select.enabled {
    .browser-default {
      padding: 2px 30px 2px 20px;
      border: 1px solid $white;
      border-radius: 5px;
      color: $blue;
      background: $white;
    }

    &:after {
      cursor: pointer;
      color: $white;
      text-shadow: 0px 1px 1px #5c7a1f;
      background: #9c3;
      border-radius: 0 5px 5px 0;
      border: 1px solid $white;
    }
  }
}

@media print {
  .cc-section-header {
    border: none;
    &.table-header,
    &.table-header.md-panel-header,
    &.white-header,
    &.white-header.md-panel-header {
      border: none;
      border-bottom: 1px solid $grey;
      min-height: unset;
      height: unset;
    }

    .title {
      font-weight: normal;
      span:first-of-type,
      span:nth-of-type(1n) {
        font-weight: normal;
      }
    }
  }
}
