@use "/src/styles/base/resources" as *;

.client-logo {
  .logo-icon {
    color: $white;
    font-size: 2.2em;
    text-shadow: 0px 1px 1px black;

    @include mq("tablet-wide") {
      font-size: 3em;
    }
  }

  img.logo-icon {
    max-height: 1em;
    width: auto;
  }
}
