@use "/src/styles/base/resources" as *;

.task-modal {
  .payload-field {
    margin-top: 1rem;

    .payload-label {
      font-weight: bold;
      font-size: 1.05rem;
    }
  }
}
