@use "/src/styles/base/resources" as *;

.app-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 195px;
  margin: 36px;

  svg {
    transition: 0.4s ease;
  }

  &:hover {
    svg {
      transform: scale(1.1);

      stop:nth-child(1) {
        stop-color: $blue;
      }
      stop:nth-child(2) {
        stop-color: #386b53;
      }
      stop:last-child {
        stop-color: $green;
      }
    }
  }

  .app-name {
    color: $dark-blue;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    margin: 20px 0 5px 0;
    white-space: pre-line;
    line-height: 1.1em;
  }

  .app-description {
    color: $grey-description;
    font-size: 0.9em;
    white-space: pre-line;
    line-height: 1.6em;
    margin-top: 10px;
  }
}

.applications {
  .app-button {
    height: 215px;
    border-radius: 12px;
    background: white;
    box-shadow: $glow-shadow;
  }
}
