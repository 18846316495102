@use "/src/styles/base/resources" as *;

.color-input-container {
  position: relative;
  display: flex;
  flex-flow: row-reverse;
  justify-content: flex-end;

  .color-input-button {
    padding: 3px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    .color-input-preview {
      width: 20px;
      height: 20px;
      border-radius: 3px;
    }
  }
  .color-input-picker {
    position: absolute;
    z-index: 100;
    top: 30px;
    left: -192px;
  }
}
