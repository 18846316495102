@use "/src/styles/base/resources" as *;

.bordered-group-container {
  position: relative;
  border: 1px solid #ccc;
  color: $grey;
  padding: 25px;
  margin-bottom: 20px;
  color: $dark-color;
  background: $grey-header;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    border: $green solid 1px;
    .bordered-group-title {
      color: $blue;
    }
  }

  .bordered-group-title {
    transform: translate(0, -40px);
    background: linear-gradient(transparent 50%, $grey-header 50%);
    font-size: 20px;
    white-space: nowrap;
    font-weight: bold;
  }

  .bordered-group-body {
    .space {
      width: 20px;
    }
  }
}
