// This file is a resource module.
// It is listed in ./_resources.scss
// All resource modules must be imported in all .scss files with `@use "/src/styles/base/resources"`.

@use "variables" as *;

@mixin linear-gradient(
  $primary-color,
  $secondary-color: darken($primary-color, 2%),
  $direction: left
) {
  background: $primary-color;
  background: linear-gradient(to $direction, $secondary-color, lighten($primary-color, 3%));
}

@mixin border-radius($radius: 5px) {
  border-radius: $radius;
}

@mixin user-select($value: none) {
  user-select: $value;
}

// Usefull mixings from https://engageinteractive.co.uk/blog/top-10-scss-mixins

// Media queries
// A massive time saver! You can just use pixel values but we have some default breakpoints setup that work too, another time saver while keeping your breakpoints consistent.

// example

// .site-header {
//     padding: 2rem;
//     font-size: 1.8rem;
//     @include mq('tablet-wide') {
//         padding-top: 4rem;
//         font-size: 2.4rem;
//     }
// }

$breakpoints: (
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-wide": 1024px,
  "desktop": 1248px,
  "desktop-wide": 1440px,
);
@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type==max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

// Z-index

// While technically this is just a pure function,but I felt it's worth being on the list as it's so easy to loose track of your z-index values when working in several different files,so we created this so that we could store them in one place for easy editing/recording. Just place this snippet in your main variables/settings.scss file (needs to be imported before any of your other files that reference a x-index of course).

// Then where ever you're wanting to use a z-index value, name it the same as your class and add in it into your variables/setting file, like below. You'll never have a
// "z-index: 99999999;" headache again. .site-header {
//     z-index: z('site-header');
// }

@function z($name) {
  @if index($z-indexes, $name) {
    @return (length($z-indexes) - index($z-indexes, $name))+1;
  } @else {
    @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
    @return null;
  }
}

$z-indexes: ("outdated-browser", "modal", "site-header", "page-wrapper", "site-footer");

@mixin flexbox {
  display: flex;
}

%flexbox {
  @include flexbox;
}

//----------------------------------

@mixin inline-flex {
  display: inline-flex;
}

%inline-flex {
  @include inline-flex;
}

@mixin flex-direction($value: row) {
  flex-direction: $value;
}
// Shorter version:
@mixin flex-dir($args...) {
  @include flex-direction($args...);
}

@mixin flex-wrap($value: nowrap) {
  flex-wrap: $value;
}

@mixin flex-flow(
  $values: (
    row nowrap,
  )
) {
  flex-flow: $values;
}

//----------------------------------------------------------------------

// Flexbox Order
//
// The 'order' property controls the order in which flex items appear within
// their flex container, by assigning them to ordinal groups.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#order-property

@mixin order($int: 0) {
  order: $int;
}

//----------------------------------------------------------------------

// Flexbox Grow
//
// The 'flex-grow' property sets the flex grow factor. Negative numbers
// are invalid.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#flex-grow-property

@mixin flex-grow($int: 0) {
  flex-grow: $int;
}

//----------------------------------------------------------------------

// Flexbox Shrink
//
// The 'flex-shrink' property sets the flex shrink factor. Negative numbers
// are invalid.
//
// Default: 1
//
// http://w3.org/tr/css3-flexbox/#flex-shrink-property

@mixin flex-shrink($int: 1) {
  flex-shrink: $int;
}

//----------------------------------------------------------------------

// Flexbox Basis
//
// The 'flex-basis' property sets the flex basis. Negative lengths are invalid.
//
// Values: Like "width"
// Default: auto
//
// http://www.w3.org/TR/css3-flexbox/#flex-basis-property

@mixin flex-basis($value: auto) {
  flex-basis: $value;
}

//----------------------------------------------------------------------

// Flexbox "Flex" (shorthand)
//
// The 'flex' property specifies the components of a flexible length: the
// flex grow factor and flex shrink factor, and the flex basis. When an
// element is a flex item, 'flex' is consulted instead of the main size
// property to determine the main size of the element. If an element is
// not a flex item, 'flex' has no effect.
//
// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: See individual properties (1 1 0).
//
// http://w3.org/tr/css3-flexbox/#flex-property

@mixin flex($fg: 1, $fs: null, $fb: null) {
  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg) == "list" {
    $fg-boxflex: nth($fg, 1);
  }
  flex: $fg $fs $fb;
}

//----------------------------------------------------------------------

// Flexbox Justify Content
//
// The 'justify-content' property aligns flex items along the main axis
// of the current line of the flex container. This is done after any flexible
// lengths and any auto margins have been resolved. Typically it helps distribute
// extra free space leftover when either all the flex items on a line are
// inflexible, or are flexible but have reached their maximum size. It also
// exerts some control over the alignment of items when they overflow the line.
//
// Note: 'space-*' values not supported in older syntaxes.
//
// Values: flex-start | flex-end | center | space-between | space-around
// Default: flex-start
//
// http://w3.org/tr/css3-flexbox/#justify-content-property

@mixin justify-content($value: flex-start) {
  justify-content: $value;
}
// Shorter version:
@mixin flex-just($args...) {
  @include justify-content($args...);
}

//----------------------------------------------------------------------

// Flexbox Align Items
//
// Flex items can be aligned in the cross axis of the current line of the
// flex container, similar to 'justify-content' but in the perpendicular
// direction. 'align-items' sets the default alignment for all of the flex
// container's items, including anonymous flex items. 'align-self' allows
// this default alignment to be overridden for individual flex items. (For
// anonymous flex items, 'align-self' always matches the value of 'align-items'
// on their associated flex container.)
//
// Values: flex-start | flex-end | center | baseline | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-items-property

@mixin align-items($value: stretch) {
  align-items: $value;
}

//----------------------------------

// Flexbox Align Self
//
// Values: auto | flex-start | flex-end | center | baseline | stretch
// Default: auto

@mixin align-self($value: auto) {
  align-self: $value;
}

//----------------------------------------------------------------------

// Flexbox Align Content
//
// The 'align-content' property aligns a flex container's lines within the
// flex container when there is extra space in the cross-axis, similar to
// how 'justify-content' aligns individual items within the main-axis. Note,
// this property has no effect when the flexbox has only a single line.
//
// Values: flex-start | flex-end | center | space-between | space-around | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-content-property

@mixin align-content($value: stretch) {
  align-content: $value;
}

@mixin inputToolbarStyle {
  color: #005288;
  border: 1px solid $grey;
  box-sizing: border-box;
  resize: vertical;
  border-radius: 5px;
  font-size: 16px;
  padding: 2px 10px;
  background: $white;
  min-height: 28px;
  &:focus {
    box-shadow: 0 0 0 3px $blue-sky;
    border-radius: 5px;
    outline: none;
  }
}
