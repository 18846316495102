@use "/src/styles/base/resources" as *;

@use "sass:math";

.table-text-field {
  font-size: 1em;
  padding: 0;
  margin: 0;
  input {
    font-size: 100%;
  }
  &.percent-input {
    input {
      width: 3em;
    }
  }
  i {
    font-size: 1.2em;
    transition: color 0.5s;
    &.md-text--theme-primary {
      color: $blue;
    }
  }
}
.table-column-min-width {
  $min-width-breakpoints: 5 10 15 20 25 30 math.div(100, 3) 35 40 45 50 55 60 65 math.div(200, 3) 70
    75 80 85 90 95 100;

  flex: 1 1 auto;

  @each $bp in $min-width-breakpoints {
    // floor so that the class name is .flex--33 instead of .flex-33.3333
    &--#{floor($bp)} {
      min-width: $bp * 1%;
    }
  }
}

.md-table-header {
  padding: 1em;
  background: $grey-header;
  color: $dark-color;
  th {
    font-size: 1em;
  }
}
