@use "/src/styles/base/resources" as *;

.cc-navbar {
  // z-index: 100;
  min-height: $navbar-height;
  max-height: $navbar-height;
  height: $navbar-height;
  background-color: $blue;
  border-bottom: 3px solid $green;

  .header-btn {
    color: $white;
  }
  .client-logo {
    margin-left: 10px;
    @include mq("tablet", "max") {
      display: none;
    }
  }

  .asci-select {
    color: white;
    margin: 0 15px;

    .browser-default {
      color: white;
      border-bottom-color: white;
    }
  }

  .md-drawer {
    max-width: 50%;
    min-width: 200px;
    left: 50%;

    h1,
    h2,
    h3,
    h4,
    p,
    span,
    button,
    .app-name,
    a {
      font-family: "Titillium";
      font-weight: normal;
    }

    .drawer-header {
      .md-btn.close-drawer-btn {
        height: 50px;
        width: 50px;
        padding: 0;
        font-size: 2em;
        margin: 5px;
        margin-right: 0;
      }
    }

    .md-divider {
      margin-bottom: 0;
      margin-top: 0;
    }

    .md-list {
      padding-bottom: 0;
      padding-top: 0;
      height: calc(100vh - 60px);
    }

    @include mq(tablet) {
      left: unset;
    }

    .menu-drawer-nav-items {
      button {
        font-size: 1em;
      }
    }

    .context-menu {
      button {
        width: 100%;

        &.selected {
          background: #005288;
          pointer-events: none;
          color: white;
        }
      }

      .md-btn {
        height: 55px;
      }
    }

    .app-button {
      font-size: 14px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 10px 10px 10px 20px;
      margin: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      .app-name,
      .md-icon {
        color: $dark-color;
        font-size: 1em;
        margin: 5px;
        text-align: left;
      }

      &:hover {
        background: $grey-ligth;
        svg {
          transform: scale(1);
        }
      }

      &.selected {
        background: $blue;
        pointer-events: none;

        .app-name {
          color: $white;
        }

        svg {
          stop:nth-child(1n) {
            stop-color: $white;
          }
        }
      }
    }

    .apps-collection {
      padding: 0;
      @include flexbox;
      @include flex-wrap(wrap);
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(space-around);
      @include mq("tablet") {
        margin-top: 10px;
      }
    }
  }

  .cc-drawer-menu {
    margin-right: 10px;
    margin-left: 10px;
  }

  .drawer-btn {
    &.md-text--inherit.md-text--inherit {
      color: $white;
    }

    .md-icon-text {
      font-family: "Titillium";
      text-transform: capitalize;
      font-size: 1.23em;
    }
    color: $white;
    .md-icon {
      font-size: 1em;
    }
  }
}
