@use "/src/styles/base/resources" as *;

#app-wrapper {
  .main-container {
    .bordered-group-body {
      .date-time-selector {
        padding: 0 !important;

        .cc-textfield {
          height: 40px;
          background-color: white;
          border-radius: 2px;
          border: 1px solid $gray1;
          line-height: 40px;
          font-size: 15px;
          padding: 0 8px;
          min-width: 190px;
        }

        .label {
          line-height: 40px;
          font-family: "Open Sans";
          color: $gray-cold7;
        }

        .horizontal-label-margin {
          color: $gray-cold7;
        }

        .md-divider {
          display: none;
        }

        .md-text-field {
          margin: 0px;
        }

        .horizontal-label-margin {
          line-height: 3;
        }

        .md-text-field:disabled,
        .md-text-field-container:hover {
          color: #4d4d4d;
          cursor: default;
        }

        .btn-with-text {
          color: $old-primary2;
          min-width: 100px;

          .asci-btn {
            margin: 4px 10px;
            border-radius: 3px;
            color: white;
            background-color: $primary1;
            font-weight: bold;
            text-shadow: none;
            padding: 0 15px;

            i {
              padding-right: 10px;
              color: white;
            }
          }

          div {
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
          }

          &:first-of-type {
            .asci-btn,
            div {
              margin-left: 0;
            }
          }
        }

        .btn-icon {
          padding: 0 10px;

          .md-icon div {
            display: inline;
            margin: 0 10px;
            font-family: "Open Sans";
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
