@use "/src/styles/base/resources" as *;

.model-descriptors-page {
  .ant-card {
    border-radius: $border-radius;
    box-shadow: $glow-shadow;

    .ant-card-head {
      border-radius: $border-radius-top;
      background: $primary3;
    }
  }

  .model-descriptors-list {
    .ant-card-body {
      padding: 0;
    }
  }

  .model-descriptors-search {
    border-radius: $border-radius;
    box-shadow: $glow-shadow;
    margin-bottom: 1em;
  }
}

.model-descriptors-modal {
  .descriptor-id {
    margin: 1em 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .ant-list-item {
    padding: 0 0 0.5em;

    &:last-child {
      padding: 0;
    }
  }

  // Hide the react-json-view validation message since we're using antd's message component
  .react-json-view .validation-failure {
    display: none;
  }
}
