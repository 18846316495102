@use "/src/styles/base/resources" as *;

.reporting-form {
  box-shadow: $glow-shadow;
  border-radius: 8px;

  .cc-section-header {
    border-radius: 8px 8px 0 0;
  }

  .facility-selector {
    label {
      margin-left: 1em;

      &:first-of-type {
        margin-left: 0;
      }
    }

    margin-right: 1em;
  }

  .select-label {
    margin-right: 0.5em;
  }

  .uniqueness {
    margin-top: 1em;
  }
}
