@use "/src/styles/base/resources" as *;

.action-list {
  display: flex;
  width: 100%;
  justify-content: space-between;
  > :first-child {
    width: 10rem;
  }
}

.details-filters {
  .md-dialog-container {
    z-index: 300 !important;
  }
  background-color: #e9f4fd;
  .panel-container {
    padding: 1rem !important;
  }
}

// Scope style updates to workstations component
.workstations {
  .ant-table-thead > {
    tr > {
      th {
        background: #e9f4fd;
        color: #005288;
      }
      th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        width: 0px;
      }
    }
  }
  .ant-table-tbody {
    tr:nth-child(2n + 0) {
      background-color: #f5f5f5;
    }
    tr {
      cursor: pointer;
    }
  }
}

.details-title-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.name-changer {
  display: flex;
}
