@use "/src/styles/base/resources" as *;

.cc-table-pagination {
  user-select: none;
  background: $grey;
  padding: 0.5em 1em;

  .pages-container {
    .page-navigation {
      cursor: pointer;
      &.disabled {
        .pagination-nav-btn {
          color: $grey-description;
        }
      }
    }
    .pagination-nav-btn {
      cursor: pointer;

      color: black;
      padding: 0 16px;
      border-radius: 5px;
      text-decoration: none;
      &.current {
        background: $blue;
        color: $white;
      }
    }
  }
}
