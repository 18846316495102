@use "/src/styles/base/resources" as *;

.new-label-modal-content {
  .separator {
    width: 100%;
    border-bottom: 1px solid #dedede;
    margin-bottom: 0.5rem;
  }
}

.new-label-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;

  .new-label-input-label {
    font-weight: bold;
    width: 40%;
  }
  .new-label-input-input {
    width: 50%;

    .cc-select,
    .cc-text-field {
      width: 100%;
    }

    .cc-text-field input {
      width: 100%;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
