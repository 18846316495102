@use "/src/styles/base/resources" as *;

.cc-simple-panel {
  background-color: #f3f3f6;
  padding: 20px;

  &.no-padding {
    padding: 0;
  }
}
