@use "/src/styles/base/resources" as *;

.license-list {
  .facility-list,
  .deployment-list {
    margin-left: 2em;
  }

  .license {
    margin-top: 0.5em;

    &:first-child {
      margin-top: 0;
    }

    .label {
      font-weight: 500;
    }
  }

  [class$="-header"] {
    color: $primary1;
    font-weight: 700;
    margin: 0.5em 0;
  }
}
