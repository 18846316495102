@use "/src/styles/base/resources" as *;

.list-editor {
  display: flex;
  flex-direction: row;

  .label {
    flex: 0 1 50%;
    text-align: right;
    padding-right: 20px;
  }

  .label + div {
    flex: 0 1 50%;
  }
}
