@use "/src/styles/base/resources" as *;

.cc-list-to-list {
  $list-title-height: 40px;
  $border-color: #ddd;
  $item-background-color: white;
  $selected-background-color: #eee;
  $hover-background-color: #f6f6f6;
  $list-background-color: #fcfcfc;
  $item-height: 32px;
  $item-padding-v: 5px;
  $item-padding-h: 10px;
  $items-displayed: 5;

  display: flex;
  flex-direction: row;
  padding: 5px;

  .list-container {
    flex: 1 1 50%;

    > p {
      font-weight: bold;
      font-size: 1.1em;
      line-height: $list-title-height;
      margin: 0;
    }

    .list-items {
      display: flex;
      flex-direction: column;
      border: 1px solid $border-color;
      border-radius: 3px;
      background-color: $list-background-color;
      height: ($items-displayed * $item-height) + 2px;
      overflow-y: auto;

      .list-item {
        border-bottom: 1px solid $border-color;
        padding: $item-padding-v $item-padding-h;
        background-color: $item-background-color;
        height: $item-height;
        line-height: $item-height - 2 * $item-padding-v;

        &:hover {
          cursor: pointer;
          background-color: $hover-background-color;
        }

        &.selected {
          background-color: $selected-background-color;
        }
      }
    }
  }

  .actions {
    width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: $list-title-height;
  }
}
