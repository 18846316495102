@use "/src/styles/base/resources" as *;

.cc-test-row {
  $icon-size: 50px;
  $icon-side-padding: 15px;
  $name-width: 220px;

  display: flex;
  flex-direction: row;

  > div {
    padding: 5px 0;
  }

  .icon {
    flex: 0 0 $icon-size;
    width: $icon-size + 2 * $icon-side-padding;
    padding: 5px $icon-side-padding;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .name {
    flex: 0 1 $name-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #696969;
  }

  .description {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 10px;
    padding-left: 10px;
  }

  .extra {
    flex: 1 1 auto;
  }
}
