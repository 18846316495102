@use "/src/styles/base/resources" as *;

@use "sass:math";

.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.bold {
  font-weight: 700;
}
.bold-screen {
  font-weight: bold;
  @media print {
    font-weight: normal;
  }
}

.no-overflow {
  overflow: initial;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Flex
// Usage:
// flex for automatic sizing
// flex-33 for 1/3 of the container
// flex-50 for half the container
.flex {
  $flex-breakpoints: 5 10 15 20 25 30 math.div(100, 3) 35 40 45 50 55 60 65 math.div(200, 3) 70 75
    80 85 90 95 100;

  flex: 1 1 auto;

  @each $bp in $flex-breakpoints {
    // floor so that the class name is .flex--33 instead of .flex-33.3333
    &--#{floor($bp)} {
      flex: 1 1 $bp * 1%;
    }
  }

  &--nogrow {
    flex-grow: 0;
  }

  &--noshrink {
    flex-shrink: 0;
  }
}

// Layout Row
.layout-row {
  display: flex;
  flex-direction: row;
}

// Layout Column
.layout-column {
  display: flex;
  flex-direction: column;
}

// Alignment
// Usage:
// layout-row layout-row--center-end: items are centered horizontally and at the end vertically (bottom of the container)
.layout-row,
.layout-column {
  $alignments: (
      name: start,
      value: flex-start,
    )
    (
      name: center,
      value: center,
    )
    (
      name: end,
      value: flex-end,
    )
    (
      name: space-between,
      value: space-between,
    );

  @each $main in $alignments {
    // usage: layout-column--center (centered only on the main axis)
    &--#{map-get($main, "name")} {
      justify-content: map-get($main, "value");
    }

    @each $cross in $alignments {
      // usage: layout-row--space-between-center
      // aligned with space-between horizontally for the main axis and centered vertically for the cross axis)
      &--#{map-get($main, "name")}-#{map-get($cross, "name")} {
        justify-content: map-get($main, "value");
        align-items: map-get($cross, "value");
      }
    }
  }
}

.layout-wrap {
  flex-wrap: wrap;
}

// Usage:
// padding--5 : padding: 5px;
// padding-top--15 : padding-top: 15px;
.padding {
  $step: 5;

  @for $i from 0 through 4 {
    $val: $i * $step;

    &--#{$val} {
      padding: $val * 1px;
    }

    &-top--#{$val} {
      padding-top: $val * 1px;
    }

    &-right--#{$val} {
      padding-right: $val * 1px;
    }

    &-bottom--#{$val} {
      padding-bottom: $val * 1px;
    }

    &-left--#{$val} {
      padding-left: $val * 1px;
    }

    &-v--#{$val} {
      padding-top: $val * 1px;
      padding-bottom: $val * 1px;
    }

    &-h--#{$val} {
      padding-left: $val * 1px;
      padding-right: $val * 1px;
    }
  }
}
// Usage:
// margin--5 : margin: 5px;
// margin-top--15 : margin-top: 15px;
.margin {
  $step: 5;

  @for $i from 0 through 4 {
    $val: $i * $step;

    &--#{$val} {
      margin: $val * 1px;
    }

    &-top--#{$val} {
      margin-top: $val * 1px;
    }

    &-right--#{$val} {
      margin-right: $val * 1px;
    }

    &-bottom--#{$val} {
      margin-bottom: $val * 1px;
    }

    &-left--#{$val} {
      margin-left: $val * 1px;
    }

    &-v--#{$val} {
      margin-top: $val * 1px;
      margin-bottom: $val * 1px;
    }

    &-h--#{$val} {
      margin-left: $val * 1px;
      margin-right: $val * 1px;
    }
  }
}

.grey--1 {
  background-color: #e6e6e6;
}

.color--error {
  color: $red;
}

.text-color {
  color: $blue;
  $colors: (
      name: "blue",
      value: $blue,
    )
    (
      name: "green",
      value: $green,
    )
    (
      name: "red",
      value: $red,
    )
    (
      name: "white",
      value: $white,
    )
    (
      name: "yellow",
      value: $yellow,
    )
    (
      name: "grey",
      value: $grey,
    );

  @each $color in $colors {
    // usage: text-color--red (centered only on the color axis)
    &--#{map-get($color, "name")} {
      color: #{map-get($color, "value")};
    }
  }
  &--error {
    color: $red;
  }
}

.background-color {
  color: $blue;
  $colors: (
      name: "blue",
      value: $blue,
    )
    (
      name: "green",
      value: $green,
    )
    (
      name: "red",
      value: $red,
    )
    (
      name: "white",
      value: $white,
    )
    (
      name: "yellow",
      value: $yellow,
    )
    (
      name: "grey",
      value: $grey,
    );

  @each $color in $colors {
    // usage: background-color--red (centered only on the color axis)
    &--#{map-get($color, "name")} {
      background-color: #{map-get($color, "value")};
    }
  }
}
