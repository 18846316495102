@use "/src/styles/base/resources" as *;

// Summary line contains reorder & expand buttons
.workflow-behavior-editor {
  border: solid 1px #f3f3f3;
  border-top: none;

  .workflow-behavior-summary {
    background-color: white;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    > * {
      padding: 1rem;
    }

    .reorder-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      cursor: pointer;
      margin: 0 0.5rem;
    }

    > :last-child {
      cursor: pointer;
      user-select: none;
    }

    .summary-behavior-name {
      font-weight: bold;
    }
  }

  .workflow-behavior-editor-body {
    padding: 1rem;
    background-color: #f3f3f3;
  }

  .workflow-behavior-editor-conditions {
    margin: 1rem 0;
    background: white;
    border-radius: 3px;
    .condition-header {
      background: #005288;
      color: white;
      padding: 0.5rem;
      border-radius: 3px 3px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .condition-body {
      padding: 0.25rem;
    }
  }
  .workflow-behavior-editor-content {
    padding: 0.25rem;
    background-color: white;
    border-radius: 3px;
  }
}
