@use "/src/styles/base/resources" as *;

.service-list {
  width: 100%;
  margin: 15px;

  .service-list-btn-group {
    .asci-btn.blue-button.add-service-btn {
      box-shadow: none;
      text-shadow: none;
      padding: 0 10px;
      min-width: 100px;
      min-height: 35px;
      margin-bottom: 15px;
      &:hover:not([disabled]) {
        background-color: $dark-blue;
      }
    }

    .asci-btn.white-button.cancel-btn {
      background-color: $gray1;
      color: $gray6;
      margin-right: 1em;
      box-shadow: none;
      text-shadow: none;
      padding: 0 10px;
      min-width: 70px;
      min-height: 35px;
      margin-bottom: 15px;
      &:not([disabled]) {
        color: $gray8;
        background-color: $gray3;
      }
      &:hover:not([disabled]) {
        color: $gray7;
        background-color: $gray4;
      }
    }

    .asci-btn.green-button.save-btn {
      box-shadow: none;
      text-shadow: none;
      padding: 0 10px;
      min-width: 70px;
      min-height: 35px;
      margin-bottom: 15px;
    }
  }

  .serviceDetails-expansion {
    .service-details-expansion-panel {
      border-radius: 8px;

      &.md-expansion-panel--expanded:not(:last-child) {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      &.md-paper {
        box-shadow: none;
        margin-top: 0px;
        margin-bottom: 2px;
      }

      .md-panel-header {
        border-color: $gray1;
        .md-panel-column.md-text,
        .md-collapser {
          color: $gray8;
        }

        .md-panel-column.md-text {
          font-size: 16px;
        }

        .md-collapser {
          font-size: 16px;
        }
      }
    }
  }
}
