@use "/src/styles/base/resources" as *;

.manual-entry {
  padding-left: 15px;

  .section-title {
    height: 24px;
    color: $gray8;
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    margin: 10px 0;
  }

  .manual-entry-column {
    min-width: 150px;
    margin: 15px 0;
    padding-right: 45px;

    .field-label {
      color: $primary1;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.6px;
      line-height: 16px;
      margin: 7px 7px 10px 0;
    }

    .input-field-operator {
      border: 2px solid $gray3;
      border-radius: 2px;
      width: 170px;
      min-width: 100px;
      background-color: white;
      padding: 0 0 11px 7px;
      margin-top: 5px;
      font-size: 14px;
      line-height: 20px;
      color: $gray8;

      .md-divider {
        display: none;
      }
    }

    .date-time-field {
      background-color: white;
      border: 2px solid $gray3;
      border-radius: 2px;
      width: 170px;
      padding: 7px;
      margin-top: 5px;
      line-height: 30px;
      font-size: 14px;
      color: $gray8;
    }
  }
}
