@use "/src/styles/base/resources" as *;

.percent-bar-chart {
  width: 1260px;
  height: 600px;
  margin: 25px auto;

  .chart-header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: 0.5em;
    justify-items: center;
    margin: 2em 0 1em;

    .chart-title {
      color: #262e47;
      font-size: 22px;
      font-weight: 500;
      grid-column-start: 2;
      margin: auto;
    }
  }
}
