@use "/src/styles/base/resources" as *;

.canned-report-filters {
  background: #e5eef1;
  padding: 1em 3em;

  .filters {
    .filter-container {
      width: 180px;
      margin-right: 1.5em;

      .filter-label {
        color: $primary1;
        font-weight: bold;
        margin-bottom: 0.5em;
      }

      .cc-select-list {
        border: 1px solid $gray1;
        font-size: 14px;

        &::after,
        select {
          color: $gray5;
        }
      }
    }
  }

  .actions {
    .asci-btn {
      font-size: 16px;
      padding: 0.5em 1em;
      margin: 0 0 1em;
    }

    .secondary-btn {
      color: $primary1;
      font-weight: 500;
      cursor: pointer;

      span {
        text-decoration: underline;
      }

      [class^="icon-"],
      [class*=" icon-"] {
        margin-right: 0.5em;
      }

      &.is-disabled {
        color: rgba($primary1, 0.6);
      }
    }
  }

  .bordered-group-container {
    padding: 0;
    border: none;
    background: none;

    .date-time-selector .date-time-selector-instruction {
      color: $primary1;
      font-weight: bold;
      margin-bottom: 0.5em !important; // TODO: not use !important
    }
  }
}
