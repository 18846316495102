@use "/src/styles/base/resources" as *;

.dashboard-page {
  background: $gray-cold2;

  .cc-expansion-panel {
    margin: 16px 16px 32px;
    border-radius: 8px;

    &.md-expansion-panel--expanded:not(:last-child) {
      margin-bottom: 32px;
    }

    &.md-paper {
      box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    }

    .md-panel-header {
      height: 80px;

      .md-panel-column.md-text,
      .md-collapser {
        color: $primary1;
      }

      .md-panel-column.md-text {
        font-size: 20px;
        font-weight: bold;
      }

      .md-collapser {
        font-size: 30px;
      }

      .filter-tag {
        background: $gray-cold2;
        padding: 0.25em 0.5em;
        margin-right: 1em;
        width: fit-content;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        border-bottom: 2px solid rgba($gray-cold4, 0.33);
        border-radius: 3px;
      }
    }

    .md-panel-content {
      padding: 0 24px 24px;
    }

    .layout-wrap {
      margin: -16px;
    }

    .ant-spin-spinning {
      margin: 3em auto;
    }
  }
}
