@use "/src/styles/base/resources" as *;

@import "/node_modules/react-date-range/dist/styles.css";

#app-wrapper {
  .main-container {
    .date-picker {
      .container-picker {
        position: absolute;
        width: calc(100% - 64px);
        height: calc(100% - 100px);
        min-height: 375px;
        @include mq("desktop-wide", "min") {
          min-height: 450px;
        }
      }
      .md-dialog {
        height: 585px;
        overflow: auto;
        max-height: calc(100vh - 50px);
        max-width: 950px;
        @include mq("desktop", "max") {
          height: 625px;
        }
        @include mq("desktop-wide", "min") {
          height: 665px;
          max-width: 1050px;
        }
      }
    }
  }
}

///date select style
.rdrCalendarWrapper {
  color: #000000;
  font-size: 10px;
  @include mq("desktop", "max") {
    font-size: 12px;
  }
  @include mq("desktop-wide", "min") {
    font-size: 13px;
  }
  @media screen and (orientation: landscape) {
    @include mq("tablet", "max") {
      font-size: 9px;
    }
  }
}

.rdrDateDisplay {
  background-color: $blue;
  padding: 0.833em;
}

.rdrDateDisplayItem {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
  input {
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    font-size: 1.5em;
    background: $blue;
    color: $white;
  }
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers {
  font-weight: 600;
  font-size: 1.25em;
  select {
    font-size: 1em;
    appearance: none;
    border: 0;
    background: transparent;
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: #3e484f;
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='#0E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>")
      no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    text-align: center;
    &:hover {
      background-color: $grey-ligth;
    }
  }
}

.rdrMonthPicker,
.rdrYearPicker {
  margin: 0 5px;
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: $grey-ligth;
  &:hover {
    background: #e1e7f0;
  }
  i {
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
  }
}

.rdrPprevButton {
  i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0px);
  }
}

.rdrNextButton {
  i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
  }
}

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth {
  padding: 0 0.833em 1.666em 0.833em;
  .rdrWeekDays {
    padding: 0;
  }
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: rgb(132, 144, 149);
}

.rdrDay {
  background: transparent;
  user-select: none;
  border: 0;
  padding: 0;
  line-height: 3em;
  height: 3em;
  font-size: 1.25em;
  text-align: center;
  color: #1d2429;
  &:focus {
    outline: 0;
  }
}

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #3d91ff;
  }
}

.rdrDayToday {
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected {
    & ~ .rdrDayNumber span:after {
      background: #fff;
    }
  }
}

.rdrDay:not(.rdrDayPassive) {
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected {
    & ~ .rdrDayNumber {
      span {
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: $blue;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

.rdrSelected {
  left: 2px;
  right: 2px;
}

.rdrInRange {
}

.rdrStartEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected {
  border-radius: 1.042em;
}

.rdrDayStartOfMonth,
.rdrDayStartOfWeek {
  .rdrInRange,
  .rdrEndEdge {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }
}

.rdrDayEndOfMonth,
.rdrDayEndOfWeek {
  .rdrInRange,
  .rdrStartEdge {
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }
}

.rdrDayStartOfMonth,
.rdrDayStartOfWeek {
  .rdrDayInPreview,
  .rdrDayEndPreview {
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    border-left-width: 1px;
    left: 0px;
  }
}

.rdrDayEndOfMonth,
.rdrDayEndOfWeek {
  .rdrDayInPreview,
  .rdrDayStartPreview {
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    border-right-width: 1px;
    right: 0px;
  }
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  border: 0px solid $blue;
  z-index: 1;
}

.rdrDayStartPreview {
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0px;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 2px;
  right: 0px;
}
.rdrDateRangePickerWrapper {
  min-height: 375px;
  @include mq("desktop-wide", "min") {
    min-height: 450px;
  }
}
.rdrDefinedRangesWrapper {
  font-size: 1em;
  width: 226px;
  border-right: solid 1px $grey-ligth;
  background: #fff;
  .rdrStaticRangeSelected {
    color: $blue;
    font-weight: 600;
  }
  .rdrStaticRanges {
    justify-content: space-between;
    height: 375px;
    @include mq("desktop-wide", "min") {
      height: 450px;
    }
  }
}

.rdrStaticRange {
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid $grey-ligth;
  padding: 0;
  background: #fff;
  height: inherit;
  &:last-of-type {
    border-bottom: none;
  }
  &:hover,
  &:focus {
    .rdrStaticRangeLabel {
      background: $blue;
      color: $white;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      min-height: 74px;
    }
  }
}

.rdrStaticRangeLabel {
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

// .rdrInputRanges{
//   padding: 10px 0;
// }

.rdrInputRange {
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122);
  &:focus,
  &:hover {
    border-color: rgb(180, 191, 196);
    outline: 0;
    color: #333;
  }
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  content: "";
  border: 1px solid $blue;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive {
  pointer-events: none;
  .rdrDayNumber span {
    color: #d5dce0;
  }
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected,
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    display: none;
  }
}

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);
  .rdrDayNumber span {
    color: #aeb9bf;
  }
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected,
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    filter: grayscale(100%) opacity(60%);
  }
}

.rdrMonthName {
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}
