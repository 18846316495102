@use "/src/styles/base/resources" as *;

.data-import-page {
  .cc-tabs {
    border-radius: 3px;
    background-color: white;
    box-shadow: $glow-shadow;
    border-radius: 8px 8px 8px 8px;
    .react-tabs__tab-list {
      border-radius: 8px 8px 0 0;
      background: lighten($grey-header, 3);
    }
    .react-tabs__tab-panel {
      border-radius: 5px;
    }
  }
}
