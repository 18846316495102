@use "/src/styles/base/resources" as *;

.cc-performance-card {
  min-width: 502px;
  height: fit-content;
  margin: 16px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 4px 11px 2px rgba(0, 0, 0, 0.08);

  .cc-section-header {
    font-size: 18px;
    border-radius: 5px 5px 0 0;

    .title span:first-of-type {
      font-weight: 500;
    }
  }

  .asci-btn.blue-button {
    border: 1px solid $white;
    border-radius: 3px;
    box-shadow: none;
    text-shadow: none;
    font-weight: bold;
    margin-right: 0;

    i {
      font-size: 1em;
      margin-right: 8px;
    }
  }
}
