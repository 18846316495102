@use "/src/styles/base/resources" as *;

.asci-sortable-column {
  .asci-sort-icon {
    font-size: 16px;
    animation-fill-mode: forwards;
    display: inline-block;

    &.ascending-false {
      animation: rotateup 0.2s linear 1;
    }
    &.ascending-true {
      animation: rotatedown 0.2s linear 1;
    }
  }
}

@keyframes rotateup {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotatedown {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
