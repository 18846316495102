@use "/src/styles/base/resources" as *;

.dialog-download-center {
  .release-container {
    .release-version {
      margin: 15px 0;
    }
    .release-notes {
      background: #eaeaea;
      padding: 15px;
      margin: 15px 0;
      border: 1px solid $grey;
    }
  }
}
