@use "/src/styles/base/resources" as *;

.cc-select-list {
  $border-color: #ccc;
  $font-color: $dark-color;
  $font-size: 1em;
  $cc-list-height: 30px; // + 2px for borders
  $padding-h: 11px;

  position: relative;

  &:not(.underlined) {
    background-color: white;
    border-radius: 4px;
    border: 1px solid $border-color;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    font-family: "icomoon";
    content: "\f0d7"; // caret down
    color: $font-color;
    pointer-events: none;
    width: $cc-list-height;
    height: $cc-list-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  > select {
    min-width: 50px;
    appearance: none;
    -webkit-appearance: none; // autoprefixer doesn't add this properly
    font-family: inherit;
    background-color: transparent;
    line-height: $cc-list-height;
    padding: 0 $padding-h;
    padding-right: $cc-list-height;
    font-size: $font-size;
    color: $font-color;
    border: none;
    width: 100%;
    border-radius: 0;

    /* Remove focus */
    &:focus {
      outline: none;
      appearance: none;
      text-indent: 0.01px;
      text-overflow: "";
    }
  }

  &.underlined {
    > select {
      border-bottom: 1px solid $border-color;
      &:focus {
        border-bottom: 2px solid $black;
      }
    }
  }

  &.disabled {
    opacity: 0.4;
  }
}
