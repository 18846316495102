@use "/src/styles/base/resources" as *;

.version-manager {
  .release-notes-container {
    background: $white;
    width: 100%;
    padding: 20px;
    margin: 0;
    height: 25vh;
    min-height: 100%;
    overflow-y: auto;
    text-align: justify;
    display: flex;
    justify-content: center;
    & > div {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  @include mq("tablet", "max") {
    .content-footer {
      p {
        display: none;
      }
    }
  }
}
