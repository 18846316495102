@use "/src/styles/base/resources" as *;

.programs-page {
  .programs-table {
    box-shadow: $glow-shadow;

    .cc-section-header {
      border-radius: 8px 8px 0 0;
    }
  }
}
