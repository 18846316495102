@use "/src/styles/base/resources" as *;

.label-printer-page {
  .cc-section-header {
    border-radius: 8px 8px 0 0;
  }

  .cc-table {
    border-radius: 0 0 8px 8px;
  }

  .label-list-table {
    box-shadow: $glow-shadow;
  }
}
