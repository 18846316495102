@use "/src/styles/base/resources" as *;

.data-list {
  $border-color: #e1e1e1;
  padding: 10px 5px;

  &__item {
    padding: 5px;
  }

  &__item-name {
    font-weight: bold;
  }

  &__item-value {
    color: #666;
  }

  &--border-right {
    border-right: 1px solid $border-color;
  }

  &--border-left {
    border-left: 1px solid $border-color;
  }

  &--border-horizontal {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }
}
