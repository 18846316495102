@use "/src/styles/base/resources" as *;

.custom-fields-page {
  .panel-container {
    .custom-fields-table {
      box-shadow: $glow-shadow;
      .cc-section-header {
        border-radius: 8px 8px 0 0;
      }
    }
  }
}
