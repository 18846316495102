@use "/src/styles/base/resources" as *;

.all-service-suites-list {
  .icon-btn {
    flex-shrink: 0;

    .icon-file-text {
      font-size: 20px;
    }
  }
}
