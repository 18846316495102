@use "/src/styles/base/resources" as *;

.device-services {
  .device-services-table {
    .table-header {
      background-color: $primary1;
      .header-label {
        color: white;
        font-size: 18px;
      }
    }

    .table-body {
      .table-data {
        color: $gray8;
        font-size: 16px;
      }
    }
  }
}
