@use "/src/styles/base/resources" as *;

.confirmation-dialog {
  color: $gray8;
  .description-text {
    margin-bottom: 10px;
  }

  .affected-csid {
    margin-bottom: 10px;
    .csid-label {
      margin-right: 15px;
    }

    .csid-programs {
      font-weight: bold;
    }
  }

  .identifier-row {
    margin-left: 10px;
    .icon-circle {
      font-size: 10px;
      margin-right: 5px;
      line-height: 25px;
    }

    .identifier-value {
    }
  }

  .btn-group {
    justify-content: flex-end;

    .asci-btn.white-button.close-btn {
      background-color: $gray1;
      box-shadow: none;
      text-shadow: none;
      padding: 0 10px;
      min-width: 70px;
      min-height: 35px;
      border-radius: 4px;

      &:hover:not([disabled]) {
        background-color: $gray3;
      }
    }
  }
}
