@use "/src/styles/base/resources" as *;

.feature-title {
  color: $white;

  stop {
    stop-color: $white;
  }

  .feature-text {
    text-align: center;
    display: block;
    font-family: "Titillium";
    letter-spacing: 1.5px;
    font-weight: bold;
    font-size: 1.25em;
    margin-left: 10px;
  }
}
