@use "/src/styles/base/resources" as *;

.import-data-filters {
  padding-left: 15px;
  .section-title {
    height: 24px;
    color: $gray8;
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    margin: 10px 0;
  }
  .filters {
    .filter-container {
      min-width: 150px;
      margin: 15px 0;

      .filter-label {
        color: $primary1;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.6px;
        line-height: 16px;
        margin: 7px 0;
      }

      .cc-select-list {
        border: 2px solid $gray3;
        border-radius: 2px;
        margin-top: 5px;
        margin-right: 50px;
        width: 170px;
        min-width: 100px;
        line-height: 45px;
        font-size: 14px;

        &::after,
        select {
          color: $gray8;
          min-height: 45px;
        }
      }
    }
  }
}
