@use "/src/styles/base/resources" as *;

.test-suite-builder {
  $border-color: #ccc;
  $icon-width: 80px;
  $name-width: 300px;

  border-top: 1px solid $border-color;
  overflow: auto;

  .category-container {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;

    .category-header {
      padding: 10px 8px;
      border-bottom: 1px solid $border-color;

      &:hover {
        cursor: pointer;
        background-color: darken(white, 3%);
      }

      span {
        color: $dark-color;
      }

      .md-icon {
        display: inline-block;
        color: $dark-color;
        margin: 0 10px;
        transition: 300ms transform ease;
        transform: rotate(0);
        font-size: 20px;
      }
    }

    .category-tests-header {
      background-color: #666;
      color: white;

      > div {
        padding: 5px 10px;
      }

      .test {
        width: $icon-width + $name-width;
        text-align: center;
      }

      .description {
      }
    }

    .category-tests {
      .test-name {
        flex-shrink: 0;
        width: 300px;
        font-size: 16px;
        color: $blue;
        font-weight: 700;
      }

      .test-desc {
        flex: 1 1 auto;
      }

      .test-config {
        margin: 0.25em 0;
        margin-right: 1em;
        background: #eaeaea;
        padding: 0.5em 1em;

        .config-name {
          width: $name-width;
          margin-left: 0.5em;
        }

        .count {
          width: 40px;
          text-align: center;
          color: #aaa;

          &.non-zero {
            color: $black;
            font-weight: 700;
            font-size: 18px;
          }
        }

        .md-icon {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &.open {
      .category-header {
        .md-icon {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
