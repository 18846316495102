@use "/src/styles/base/resources" as *;

.workstations {
  .list-table {
    box-shadow: $glow-shadow;
    .workstation-item {
      cursor: pointer;
    }
    .workstations-filters {
      padding-top: 0.5rem;
      .md-btn:disabled {
        background-color: #3e484f !important;
        color: #9e9e9e !important;
      }
    }
    .list-body {
      .cc-table {
        border-radius: 0 0 8px 8px;
      }
    }
  }
}
