@use "/src/styles/base/resources" as *;

.md-dialog {
  @include flexbox;
  @include flex-direction(column);
  border-radius: 9px;

  .md-title--dialog {
    border-bottom-width: 4px;
    padding: 1.5em 1em 1em 1em;
    line-height: 50px;
    justify-content: space-around;
    display: flex;

    color: #53565d;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }

  section {
    @include flex(1);
    margin: 0;
    padding: 2em;
    color: $dark-color;
    p {
      font-size: 1em;
    }
  }
  .dialog-buttons {
    padding: 1em;
  }
  .md-dialog-footer {
    padding: 1em;
    background-color: $grey-ligth;
    border-radius: 0 0 9px 9px;
  }

  &#dialog {
    max-width: 500px;
  }
}
