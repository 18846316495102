@use "/src/styles/base/resources" as *;

.inspector-section {
  .inspector-section-title {
    border-bottom: 1px solid #e1e1e1;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-size: 1.125rem;
    font-weight: bolder;
    user-select: none;
    padding: 0.5rem;

    &.sub-section {
      border: none;
      font-size: 1rem;
      margin-left: 1rem;
      padding-left: 0.25rem;
      width: calc(100% - 1.25rem);
    }

    &.folded {
      color: #9e9e9e;
      font-style: italic;
      margin: 0;
      &.sub-section {
        margin-left: 1rem;
      }
    }
  }

  &:first-child {
    .inspector-section-title {
      margin-top: 0;
    }
  }

  .inspector-section-body {
    &.folded {
      display: none;
      border-bottom: none;
    }
    border-bottom: 1px solid #e1e1e1;
    &.sub-section {
      border-bottom: none;
      margin-left: 0.75rem;
    }
  }
  &:last-child {
    .inspector-section-body {
      border-bottom: none;
      margin-bottom: 1rem;
      &.folded,
      &.sub-section {
        margin-bottom: 0;
      }
    }
  }
}
