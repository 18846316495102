@use "/src/styles/base/resources" as *;

.label-shape {
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  &.shape-border {
    border: dotted 1px #ababab;
    @media print {
      border: none;
    }
  }

  &.shape-selected {
    border: dashed 1px #2f67fb;
    @media print {
      border: none;
    }
  }
}
