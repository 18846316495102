@use "/src/styles/base/resources" as *;

.test-definition-icon {
  $border-color: #ddd;
  $container-padding: 2px;
  $container-border-size: 1px;

  background-color: white;
  border-radius: 13px;
  border: 1px solid $border-color;
  padding: $container-padding;

  img {
    display: block;
    border: 0;
    border-radius: 13px; // so that images with background don't stick out the container's border
  }

  &.is-category {
    border: 0;
  }
}
