@use "/src/styles/base/resources" as *;

.cc-layout-container {
  display: flex;

  &.direction-row {
    flex-direction: row;
  }

  &.direction-column {
    flex-direction: column;
  }

  &.main {
    &-start {
      justify-content: flex-start;
    }
    &-center {
      justify-content: center;
    }
    &-end {
      justify-content: flex-end;
    }
    &-space-between {
      justify-content: space-between;
    }
    &-space-around {
      justify-content: space-around;
    }
  }

  &.cross {
    &-start {
      align-items: flex-start;
    }
    &-center {
      align-items: center;
    }
    &-end {
      align-items: flex-end;
    }
    &-space-between {
      align-items: space-between;
    }
    &-space-around {
      align-items: space-around;
    }
  }
}
