@use "/src/styles/base/resources" as *;

.cc-data-export {
  .radio-btn-vertical {
    display: block;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
  }

  .radio-label {
    margin-right: 0.5em;
  }

  .separator {
    width: 100%;
    margin: 1em auto 1em 0;
    border: none;
    height: 1px;
    background: $gray3;
  }

  .email-input {
    width: 240px;
    margin-left: 24px;
    display: inline-block;

    .md-text-field--margin {
      margin-top: 0;
    }

    .md-divider--text-field {
      margin-bottom: 0;
    }

    .md-divider--text-field:not(.md-divider--text-field-error) {
      &::after {
        background: $primary1;
      }
    }
  }

  .email-error {
    color: #f44336;
    font-size: 12px;
    margin-left: 0.5em;
  }

  // This fixes some alignment issues that were happening with content: "\a0" (ie, a nbsp)
  .ant-radio-wrapper::after {
    content: none;
  }
}
