@use "/src/styles/base/resources" as *;

.cc-info-banner {
  background: $green;
  padding: 0.5em 15%;
  font-size: 14px;

  .icon-info-circle {
    padding-right: 0.5em;
    font-size: 20px;
  }
}
