@use "/src/styles/base/resources" as *;

.upload-device-tab {
  .section-spacing {
    padding: 20px;
  }
  .section-separator {
    margin: auto;
    height: 1px;
    width: 95%;
    border: 1px solid #bab9b9;
    opacity: 0.25;
  }

  .cc-content-container {
    margin: none;
  }

  .button-group {
    border-radius: 4px;
    font-size: 14px;
    text-shadow: none;
    height: 35px;
    line-height: 30px;
    margin: 15px;

    .asci-btn.white-button.clear-filters-btn {
      padding: 0 10px;
      min-width: 100px;
      margin-right: 7px;
    }
    .asci-btn.blue-button.import-data-btn {
      color: white;
      background-color: $primary1;
      font-weight: bold;
      padding: 0 15px;
      min-width: 150px;

      &:hover:not([disabled]) {
        background-color: $dark-blue;
      }
    }
  }

  .asci-btn.white-button.session-id-btn {
    margin: 10px 15px;
    padding: 5px 10px;
    background-color: white;

    .icon-copy {
      color: $primary1;
      padding-left: 10px;
      font-size: 16px;
    }
  }
}
