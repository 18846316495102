@use "/src/styles/base/resources" as *;

.md-text-field-message--inactive {
  position: absolute;
  pointer-events: none;
}
.nav-bar {
  .context-menu ~ .md-divider {
    margin-top: 0;
    margin-bottom: 0;
    margin: 0;
  }
}

.md-dialog {
  max-height: 80vh;
  max-width: 800px;
  min-width: 400px;
}
.md-text-field {
  font-size: 1em;
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px #e1e1e1 inset;
  }
}
