@use "/src/styles/base/resources" as *;

.cc-deployment-summary {
  .md-grid {
    padding: 0;
    margin: 0;
  }

  .md-cell {
    padding: 0;
    margin: 0;
    padding: 5px;
    color: $dark-color;
    border: 1px solid $grey;
    overflow: hidden;
    text-overflow: ellipsis;

    &.title-cell {
      background: $grey-header;
    }

    &.content-cell {
      background: $white;

      .spaced-text {
        margin-right: 0.5em;
      }
    }
  }
}
