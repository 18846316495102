@use "/src/styles/base/resources" as *;

.inspector-input {
  display: flex;
  margin-bottom: 0.25rem;
  margin-left: 1rem;
  font-size: 0.9rem;

  .inspector-input-label {
    color: #949494;
    padding: 0.25rem;
    width: calc(50% - 0.5rem);
  }
  .inspector-input-input {
    width: calc(50% - 0.5rem);
    margin-left: 1rem;
    .ant-select {
      width: 11.5rem;
      background: white;
      border: 1px solid #cccccc;
      border-radius: 3px 3px 0 0;
    }
  }
}
