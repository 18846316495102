@use "/src/styles/base/resources" as *;

.heat-map-row {
  margin: 0 30px;

  .heat-map-cell {
    flex: 1;
    background: $gray2;
    color: $white;
    font-size: 18px;
    font-weight: bold;
    border-right: 1px solid $white;
    text-shadow: 0 2px 4px rgba($color: $black, $alpha: 0.43);

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-right: none;
      border-radius: 0 5px 5px 0;
    }

    .big-text {
      font-size: 20px;
    }
  }
}
