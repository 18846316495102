@use "/src/styles/base/resources" as *;

.documentation-training {
  .md-tabs-container {
    box-shadow: $glow-shadow;
    border-radius: 8px;
    .md-tabs {
      background: lighten($grey-header, 3);
    }
  }
  .docs-container {
    box-shadow: $glow-shadow;
    @include flexbox;
    @include flex-flow(row nowrap);
    @include mq("phone", "max") {
      @include flexbox;
      @include flex-flow(column nowrap);
      @include align-items(center);
      @include justify-content(center);
    }
    flex-flow: row;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .doc-selection-container {
    .doc-name {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .description-icon {
      position: absolute;
      height: 225px;
      width: 180px;
      i {
        font-size: 1.5em;
        position: absolute;
        left: -5px;
        top: 5px;
        color: $blue;
        background: $white;
        border-radius: 1.5em;
        padding: 5px;
        box-shadow: -1px 1px 5px;
      }
    }
  }
}
