@use "/src/styles/base/resources" as *;

.report-summary-processes-wrapper {
  max-width: 800px;
  width: 100%;
  margin: 30px auto 0;
  padding: 20px 0;
  .report-summary-row {
    display: flex;
    justify-content: center;
  }
  .report-summary-table-header {
    background: $white;
  }
  .report-summary-row-header {
    font-size: 16px;
  }
  .report-summary-name-rolumn {
    font-size: 14px;
  }
  .report-summary-name-rolumn,
  .report-summary-title,
  .report-summary-row-header {
    font-weight: 500;
    color: $black;
  }
}

.cc-paper {
  padding: 24px;
}

.report-summary-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  color: $black;
}
