@use "/src/styles/base/resources" as *;

.details-page {
  width: 80%;
  margin-top: 1rem;

  .details-page-title {
    font-weight: bold;
    font-size: 1.33rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .details-page-title-head {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
