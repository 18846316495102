@use "/src/styles/base/resources" as *;

.test-definition-arguments-editor {
  border: 1px solid #eeeeee;

  // override media query fucking everything up
  .md-cell {
    width: 100% !important;
    margin: 0 !important;
  }

  > header {
    background-color: $blue;
    color: $white;
    padding: 10px;
    font-size: 14px;
  }

  .col-info {
    flex: 0 0 30px;
    width: 30px;
    margin-right: 10px;

    i {
      color: $blue;
      font-size: 18px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .col-check {
    flex: 0 0 40px;
    width: 40px;
    font-size: 12px;
    text-align: center;
  }

  .col-name {
    flex: 1 1 50%;
  }

  .col-value {
    flex: 1 1 50%;
  }

  .arguments {
    padding: 10px 0;

    .argument {
      padding: 15px 10px;
    }
  }

  .preview {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      max-width: 60px;
      max-height: 60px;
      display: block;
    }

    .replace-link {
      color: $blue;
      text-align: center;
      margin-left: 5px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  > footer {
    background-color: #cacaca;
    padding: 10px;

    p {
      font-weight: bold;
      color: $blue;
      margin: 0;
    }

    > div {
      flex: 1 1 33%;
    }
  }
}
