@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?g96ffg') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?g96ffg') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?g96ffg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-file-text {
  &:before {
    content: $icon-file-text; 
  }
}
.icon-stats-bars {
  &:before {
    content: $icon-stats-bars; 
  }
}
.icon-stats {
  &:before {
    content: $icon-stats; 
  }
}
.icon-statistics {
  &:before {
    content: $icon-statistics; 
  }
}
.icon-chart {
  &:before {
    content: $icon-chart; 
  }
}
.icon-stats-bars1 {
  &:before {
    content: $icon-stats-bars1; 
  }
}
.icon-stats1 {
  &:before {
    content: $icon-stats1; 
  }
}
.icon-statistics1 {
  &:before {
    content: $icon-statistics1; 
  }
}
.icon-chart1 {
  &:before {
    content: $icon-chart1; 
  }
}
.icon-stats-dots {
  &:before {
    content: $icon-stats-dots; 
  }
}
.icon-stats2 {
  &:before {
    content: $icon-stats2; 
  }
}
.icon-plot {
  &:before {
    content: $icon-plot; 
  }
}
.icon-statistics2 {
  &:before {
    content: $icon-statistics2; 
  }
}
.icon-chart2 {
  &:before {
    content: $icon-chart2; 
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart; 
  }
}
.icon-stats3 {
  &:before {
    content: $icon-stats3; 
  }
}
.icon-statistics3 {
  &:before {
    content: $icon-statistics3; 
  }
}
.icon-graph {
  &:before {
    content: $icon-graph; 
  }
}
.icon-printer {
  &:before {
    content: $icon-printer; 
  }
}
.icon-film {
  &:before {
    content: $icon-film; 
  }
}
.icon-video {
  &:before {
    content: $icon-video; 
  }
}
.icon-movie {
  &:before {
    content: $icon-movie; 
  }
}
.icon-tape {
  &:before {
    content: $icon-tape; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-film2 {
  &:before {
    content: $icon-film2; 
  }
}
.icon-video2 {
  &:before {
    content: $icon-video2; 
  }
}
.icon-movie2 {
  &:before {
    content: $icon-movie2; 
  }
}
.icon-tape2 {
  &:before {
    content: $icon-tape2; 
  }
}
.icon-play2 {
  &:before {
    content: $icon-play2; 
  }
}
.icon-text-color {
  &:before {
    content: $icon-text-color; 
  }
}
.icon-pilcrow {
  &:before {
    content: $icon-pilcrow; 
  }
}
.icon-barcode {
  &:before {
    content: $icon-barcode; 
  }
}
.icon-users2 {
  &:before {
    content: $icon-users2; 
  }
}
.icon-user-minus2 {
  &:before {
    content: $icon-user-minus2; 
  }
}
.icon-user-plus2 {
  &:before {
    content: $icon-user-plus2; 
  }
}
.icon-user2 {
  &:before {
    content: $icon-user2; 
  }
}
.icon-asci-logo {
  &:before {
    content: $icon-asci-logo; 
  }
}
.icon-empty-gear {
  &:before {
    content: $icon-empty-gear; 
  }
}
.icon-billing {
  &:before {
    content: $icon-billing; 
  }
}
.icon-cam-manager {
  &:before {
    content: $icon-cam-manager; 
  }
}
.icon-client-deployment {
  &:before {
    content: $icon-client-deployment; 
  }
}
.icon-customer-manager {
  &:before {
    content: $icon-customer-manager; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-docs {
  &:before {
    content: $icon-docs; 
  }
}
.icon-operator-manager {
  &:before {
    content: $icon-operator-manager; 
  }
}
.icon-client-portal {
  &:before {
    content: $icon-client-portal; 
  }
}
.icon-user-management {
  &:before {
    content: $icon-user-management; 
  }
}
.icon-filled-gear {
  &:before {
    content: $icon-filled-gear; 
  }
}
.icon-test-editor {
  &:before {
    content: $icon-test-editor; 
  }
}
.icon-remote-testing {
  &:before {
    content: $icon-remote-testing; 
  }
}
.icon-remote-editor {
  &:before {
    content: $icon-remote-editor; 
  }
}
.icon-label-designer {
  &:before {
    content: $icon-label-designer; 
  }
}
.icon-asci-gear {
  &:before {
    content: $icon-asci-gear; 
  }
}
.icon-spinner-10 {
  &:before {
    content: $icon-spinner-10; 
  }
}
.icon-expert-op {
  &:before {
    content: $icon-expert-op; 
  }
}
.icon-junior-op {
  &:before {
    content: $icon-junior-op; 
  }
}
.icon-probationary-op {
  &:before {
    content: $icon-probationary-op; 
  }
}
.icon-senior-op {
  &:before {
    content: $icon-senior-op; 
  }
}
.icon-standard-op {
  &:before {
    content: $icon-standard-op; 
  }
}
.icon-training-op {
  &:before {
    content: $icon-training-op; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-th-large {
  &:before {
    content: $icon-th-large; 
  }
}
.icon-th {
  &:before {
    content: $icon-th; 
  }
}
.icon-th-list {
  &:before {
    content: $icon-th-list; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-search-plus {
  &:before {
    content: $icon-search-plus; 
  }
}
.icon-search-minus {
  &:before {
    content: $icon-search-minus; 
  }
}
.icon-power-off {
  &:before {
    content: $icon-power-off; 
  }
}
.icon-cog {
  &:before {
    content: $icon-cog; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-file-o {
  &:before {
    content: $icon-file-o; 
  }
}
.icon-clock-o {
  &:before {
    content: $icon-clock-o; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-arrow-circle-o-down {
  &:before {
    content: $icon-arrow-circle-o-down; 
  }
}
.icon-arrow-circle-o-up {
  &:before {
    content: $icon-arrow-circle-o-up; 
  }
}
.icon-play-circle-o {
  &:before {
    content: $icon-play-circle-o; 
  }
}
.icon-repeat {
  &:before {
    content: $icon-repeat; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-align-left {
  &:before {
    content: $icon-align-left; 
  }
}
.icon-align-center {
  &:before {
    content: $icon-align-center; 
  }
}
.icon-align-right {
  &:before {
    content: $icon-align-right; 
  }
}
.icon-align-justify {
  &:before {
    content: $icon-align-justify; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-dedent {
  &:before {
    content: $icon-dedent; 
  }
}
.icon-indent {
  &:before {
    content: $icon-indent; 
  }
}
.icon-video-camera {
  &:before {
    content: $icon-video-camera; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-share-square-o {
  &:before {
    content: $icon-share-square-o; 
  }
}
.icon-check-square-o {
  &:before {
    content: $icon-check-square-o; 
  }
}
.icon-arrows {
  &:before {
    content: $icon-arrows; 
  }
}
.icon-step-backward {
  &:before {
    content: $icon-step-backward; 
  }
}
.icon-fast-backward {
  &:before {
    content: $icon-fast-backward; 
  }
}
.icon-backward {
  &:before {
    content: $icon-backward; 
  }
}
.icon-play22 {
  &:before {
    content: $icon-play22; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-stop {
  &:before {
    content: $icon-stop; 
  }
}
.icon-forward {
  &:before {
    content: $icon-forward; 
  }
}
.icon-fast-forward {
  &:before {
    content: $icon-fast-forward; 
  }
}
.icon-step-forward {
  &:before {
    content: $icon-step-forward; 
  }
}
.icon-eject {
  &:before {
    content: $icon-eject; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle; 
  }
}
.icon-minus-circle {
  &:before {
    content: $icon-minus-circle; 
  }
}
.icon-times-circle {
  &:before {
    content: $icon-times-circle; 
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle; 
  }
}
.icon-question-circle {
  &:before {
    content: $icon-question-circle; 
  }
}
.icon-info-circle {
  &:before {
    content: $icon-info-circle; 
  }
}
.icon-times-circle-o {
  &:before {
    content: $icon-times-circle-o; 
  }
}
.icon-check-circle-o {
  &:before {
    content: $icon-check-circle-o; 
  }
}
.icon-ban {
  &:before {
    content: $icon-ban; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-mail-forward {
  &:before {
    content: $icon-mail-forward; 
  }
}
.icon-expand {
  &:before {
    content: $icon-expand; 
  }
}
.icon-compress {
  &:before {
    content: $icon-compress; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-exclamation-circle {
  &:before {
    content: $icon-exclamation-circle; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-eye-slash {
  &:before {
    content: $icon-eye-slash; 
  }
}
.icon-exclamation-triangle {
  &:before {
    content: $icon-exclamation-triangle; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-random {
  &:before {
    content: $icon-random; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-retweet {
  &:before {
    content: $icon-retweet; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-folder-open {
  &:before {
    content: $icon-folder-open; 
  }
}
.icon-arrows-v {
  &:before {
    content: $icon-arrows-v; 
  }
}
.icon-arrows-h {
  &:before {
    content: $icon-arrows-h; 
  }
}
.icon-cogs {
  &:before {
    content: $icon-cogs; 
  }
}
.icon-sign-out {
  &:before {
    content: $icon-sign-out; 
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link; 
  }
}
.icon-sign-in {
  &:before {
    content: $icon-sign-in; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-square-o {
  &:before {
    content: $icon-square-o; 
  }
}
.icon-bookmark-o {
  &:before {
    content: $icon-bookmark-o; 
  }
}
.icon-unlock {
  &:before {
    content: $icon-unlock; 
  }
}
.icon-arrow-circle-left {
  &:before {
    content: $icon-arrow-circle-left; 
  }
}
.icon-arrow-circle-right {
  &:before {
    content: $icon-arrow-circle-right; 
  }
}
.icon-arrow-circle-up {
  &:before {
    content: $icon-arrow-circle-up; 
  }
}
.icon-arrow-circle-down {
  &:before {
    content: $icon-arrow-circle-down; 
  }
}
.icon-globe {
  &:before {
    content: $icon-globe; 
  }
}
.icon-wrench {
  &:before {
    content: $icon-wrench; 
  }
}
.icon-tasks {
  &:before {
    content: $icon-tasks; 
  }
}
.icon-briefcase {
  &:before {
    content: $icon-briefcase; 
  }
}
.icon-arrows-alt {
  &:before {
    content: $icon-arrows-alt; 
  }
}
.icon-group {
  &:before {
    content: $icon-group; 
  }
}
.icon-chain {
  &:before {
    content: $icon-chain; 
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud; 
  }
}
.icon-paperclip {
  &:before {
    content: $icon-paperclip; 
  }
}
.icon-floppy-o {
  &:before {
    content: $icon-floppy-o; 
  }
}
.icon-square {
  &:before {
    content: $icon-square; 
  }
}
.icon-bars {
  &:before {
    content: $icon-bars; 
  }
}
.icon-list-ul {
  &:before {
    content: $icon-list-ul; 
  }
}
.icon-list-ol {
  &:before {
    content: $icon-list-ol; 
  }
}
.icon-caret-down {
  &:before {
    content: $icon-caret-down; 
  }
}
.icon-caret-up {
  &:before {
    content: $icon-caret-up; 
  }
}
.icon-caret-left {
  &:before {
    content: $icon-caret-left; 
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right; 
  }
}
.icon-sort {
  &:before {
    content: $icon-sort; 
  }
}
.icon-sort-desc {
  &:before {
    content: $icon-sort-desc; 
  }
}
.icon-sort-asc {
  &:before {
    content: $icon-sort-asc; 
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope; 
  }
}
.icon-rotate-left {
  &:before {
    content: $icon-rotate-left; 
  }
}
.icon-sitemap {
  &:before {
    content: $icon-sitemap; 
  }
}
.icon-clipboard {
  &:before {
    content: $icon-clipboard; 
  }
}
.icon-exchange {
  &:before {
    content: $icon-exchange; 
  }
}
.icon-cloud-download {
  &:before {
    content: $icon-cloud-download; 
  }
}
.icon-cloud-upload {
  &:before {
    content: $icon-cloud-upload; 
  }
}
.icon-file-text-o {
  &:before {
    content: $icon-file-text-o; 
  }
}
.icon-plus-square {
  &:before {
    content: $icon-plus-square; 
  }
}
.icon-angle-left {
  &:before {
    content: $icon-angle-left; 
  }
}
.icon-angle-right {
  &:before {
    content: $icon-angle-right; 
  }
}
.icon-angle-up {
  &:before {
    content: $icon-angle-up; 
  }
}
.icon-angle-down {
  &:before {
    content: $icon-angle-down; 
  }
}
.icon-circle-o {
  &:before {
    content: $icon-circle-o; 
  }
}
.icon-circle {
  &:before {
    content: $icon-circle; 
  }
}
.icon-mail-reply {
  &:before {
    content: $icon-mail-reply; 
  }
}
.icon-folder-o {
  &:before {
    content: $icon-folder-o; 
  }
}
.icon-folder-open-o {
  &:before {
    content: $icon-folder-open-o; 
  }
}
.icon-mail-reply-all {
  &:before {
    content: $icon-mail-reply-all; 
  }
}
.icon-question {
  &:before {
    content: $icon-question; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-exclamation {
  &:before {
    content: $icon-exclamation; 
  }
}
.icon-calendar-o {
  &:before {
    content: $icon-calendar-o; 
  }
}
.icon-chevron-circle-left {
  &:before {
    content: $icon-chevron-circle-left; 
  }
}
.icon-chevron-circle-right {
  &:before {
    content: $icon-chevron-circle-right; 
  }
}
.icon-chevron-circle-up {
  &:before {
    content: $icon-chevron-circle-up; 
  }
}
.icon-chevron-circle-down {
  &:before {
    content: $icon-chevron-circle-down; 
  }
}
.icon-unlock-alt {
  &:before {
    content: $icon-unlock-alt; 
  }
}
.icon-ellipsis-h {
  &:before {
    content: $icon-ellipsis-h; 
  }
}
.icon-ellipsis-v {
  &:before {
    content: $icon-ellipsis-v; 
  }
}
.icon-play-circle {
  &:before {
    content: $icon-play-circle; 
  }
}
.icon-minus-square {
  &:before {
    content: $icon-minus-square; 
  }
}
.icon-minus-square-o {
  &:before {
    content: $icon-minus-square-o; 
  }
}
.icon-check-square {
  &:before {
    content: $icon-check-square; 
  }
}
.icon-external-link-square {
  &:before {
    content: $icon-external-link-square; 
  }
}
.icon-share-square {
  &:before {
    content: $icon-share-square; 
  }
}
.icon-file {
  &:before {
    content: $icon-file; 
  }
}
.icon-file-text-1 {
  &:before {
    content: $icon-file-text-1; 
  }
}
.icon-sort-alpha-asc {
  &:before {
    content: $icon-sort-alpha-asc; 
  }
}
.icon-sort-alpha-desc {
  &:before {
    content: $icon-sort-alpha-desc; 
  }
}
.icon-sort-amount-asc {
  &:before {
    content: $icon-sort-amount-asc; 
  }
}
.icon-sort-amount-desc {
  &:before {
    content: $icon-sort-amount-desc; 
  }
}
.icon-sort-numeric-asc {
  &:before {
    content: $icon-sort-numeric-asc; 
  }
}
.icon-sort-numeric-desc {
  &:before {
    content: $icon-sort-numeric-desc; 
  }
}
.icon-long-arrow-down {
  &:before {
    content: $icon-long-arrow-down; 
  }
}
.icon-long-arrow-up {
  &:before {
    content: $icon-long-arrow-up; 
  }
}
.icon-long-arrow-left {
  &:before {
    content: $icon-long-arrow-left; 
  }
}
.icon-long-arrow-right {
  &:before {
    content: $icon-long-arrow-right; 
  }
}
.icon-arrow-circle-o-right {
  &:before {
    content: $icon-arrow-circle-o-right; 
  }
}
.icon-arrow-circle-o-left {
  &:before {
    content: $icon-arrow-circle-o-left; 
  }
}
.icon-caret-square-o-left {
  &:before {
    content: $icon-caret-square-o-left; 
  }
}
.icon-dot-circle-o {
  &:before {
    content: $icon-dot-circle-o; 
  }
}
.icon-plus-square-o {
  &:before {
    content: $icon-plus-square-o; 
  }
}
.icon-file-pdf-o {
  &:before {
    content: $icon-file-pdf-o; 
  }
}
.icon-file-word-o {
  &:before {
    content: $icon-file-word-o; 
  }
}
.icon-file-excel-o {
  &:before {
    content: $icon-file-excel-o; 
  }
}
.icon-file-powerpoint-o {
  &:before {
    content: $icon-file-powerpoint-o; 
  }
}
.icon-file-image-o {
  &:before {
    content: $icon-file-image-o; 
  }
}
.icon-file-archive-o {
  &:before {
    content: $icon-file-archive-o; 
  }
}
.icon-file-audio-o {
  &:before {
    content: $icon-file-audio-o; 
  }
}
.icon-file-movie-o {
  &:before {
    content: $icon-file-movie-o; 
  }
}
.icon-file-code-o {
  &:before {
    content: $icon-file-code-o; 
  }
}
.icon-circle-o-notch {
  &:before {
    content: $icon-circle-o-notch; 
  }
}
.icon-history {
  &:before {
    content: $icon-history; 
  }
}
.icon-circle-thin {
  &:before {
    content: $icon-circle-thin; 
  }
}
.icon-sliders {
  &:before {
    content: $icon-sliders; 
  }
}
.icon-at {
  &:before {
    content: $icon-at; 
  }
}
.icon-mouse-pointer {
  &:before {
    content: $icon-mouse-pointer; 
  }
}
.icon-hand-grab-o {
  &:before {
    content: $icon-hand-grab-o; 
  }
}
.icon-hand-stop-o {
  &:before {
    content: $icon-hand-stop-o; 
  }
}
.icon-hand-pointer-o {
  &:before {
    content: $icon-hand-pointer-o; 
  }
}
.icon-registered {
  &:before {
    content: $icon-registered; 
  }
}
.icon-creative-commons {
  &:before {
    content: $icon-creative-commons; 
  }
}
.icon-television {
  &:before {
    content: $icon-television; 
  }
}
.icon-calendar-plus-o {
  &:before {
    content: $icon-calendar-plus-o; 
  }
}
.icon-calendar-minus-o {
  &:before {
    content: $icon-calendar-minus-o; 
  }
}
.icon-calendar-times-o {
  &:before {
    content: $icon-calendar-times-o; 
  }
}
.icon-calendar-check-o {
  &:before {
    content: $icon-calendar-check-o; 
  }
}
.icon-question-circle-o {
  &:before {
    content: $icon-question-circle-o; 
  }
}
.icon-times-rectangle {
  &:before {
    content: $icon-times-rectangle; 
  }
}
.icon-times-rectangle-o {
  &:before {
    content: $icon-times-rectangle-o; 
  }
}
.icon-apple {
  &:before {
    content: $icon-apple; 
  }
}
.icon-brand {
  &:before {
    content: $icon-brand; 
  }
}
.icon-android {
  &:before {
    content: $icon-android; 
  }
}
.icon-brand1 {
  &:before {
    content: $icon-brand1; 
  }
}
.icon-os {
  &:before {
    content: $icon-os; 
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile; 
  }
}
.icon-copyright {
  &:before {
    content: $icon-copyright; 
  }
}
.icon-hour-glass {
  &:before {
    content: $icon-hour-glass; 
  }
}
.icon-terminal {
  &:before {
    content: $icon-terminal; 
  }
}
.icon-mail4 {
  &:before {
    content: $icon-mail4; 
  }
}
.icon-tux {
  &:before {
    content: $icon-tux; 
  }
}
.icon-appleinc {
  &:before {
    content: $icon-appleinc; 
  }
}
.icon-windows8 {
  &:before {
    content: $icon-windows8; 
  }
}
.icon-file-c {
  &:before {
    content: $icon-file-c; 
  }
}
.icon-file-java {
  &:before {
    content: $icon-file-java; 
  }
}
.icon-archive {
  &:before {
    content: $icon-archive; 
  }
}
.icon-unarchive {
  &:before {
    content: $icon-unarchive; 
  }
}
.icon-x {
  &:before {
    content: $icon-x; 
  }
}
.icon-border_left {
  &:before {
    content: $icon-border_left; 
  }
}
.icon-cell-phone {
  &:before {
    content: $icon-cell-phone; 
  }
}
.icon-code {
  &:before {
    content: $icon-code; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-csv-json {
  &:before {
    content: $icon-csv-json; 
  }
}
.icon-border_color {
  &:before {
    content: $icon-border_color; 
  }
}
.icon-empty-data {
  &:before {
    content: $icon-empty-data; 
  }
}
.icon-template {
  &:before {
    content: $icon-template; 
  }
}
.icon-align-vertical-middle {
  &:before {
    content: $icon-align-vertical-middle; 
  }
}
.icon-align-horizontal-middle {
  &:before {
    content: $icon-align-horizontal-middle; 
  }
}
.icon-align-top {
  &:before {
    content: $icon-align-top; 
  }
}
.icon-align-left1 {
  &:before {
    content: $icon-align-left1; 
  }
}
.icon-align-bottom {
  &:before {
    content: $icon-align-bottom; 
  }
}
.icon-align-right1 {
  &:before {
    content: $icon-align-right1; 
  }
}

