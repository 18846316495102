@use "/src/styles/base/resources" as *;

.import-data-file-uploader {
  padding-left: 15px;
  .section-title {
    height: 24px;
    color: $gray8;
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    margin: 10px 0;
  }
  .uploadCSV {
    margin: 15px 0;

    .file-uploader {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;

      // this makes the default file uploader hidden and use label with styling as overlay
    }
    .file-uploader + label {
      font-weight: bold;
      color: white;
      margin: 5px;
      padding: 10px 0;
      padding-right: 15px;
      background-color: $primary1;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.4);
      border-radius: 3px;
      display: inline-block;
      cursor: pointer;
    }
    .file-uploader:focus + label,
    .file-uploader + label:hover {
      background-color: $dark-blue;
    }

    .file-uploader:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }

    .icon-upload {
      padding: 10px;
    }

    .file-name {
      margin: 5px 10px;
      height: 40px;
      line-height: 40px;
    }

    .md-btn {
      margin: auto;

      .md-icon div {
        display: inline;
        margin: 0 10px;
        font-family: "Open Sans";
        font-size: 15px;
        text-transform: uppercase;
        vertical-align: middle;
      }
    }
  }
}
