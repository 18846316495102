@use "/src/styles/base/resources" as *;

.cc-team-performance {
  .performance-row {
    margin: 0 16px 16px;
    padding: 16px;
    border-radius: 3px;
    background: $gray2;

    &:first-of-type {
      margin-top: 16px;
    }
  }

  .uph-stats {
    height: 82px;
    width: 152px;
    border-radius: 3px;
    background: $white;

    .stats-number {
      color: $gray-cold6;
      font-size: 30px;
      font-weight: bold;
    }

    .stats-label {
      color: $primary1;
      font-weight: 500;
    }
  }

  .uph-arrow {
    height: 72px;
    width: 72px;
    border-radius: 8px;
    background: $gray3;
    color: $white;
    font-size: 52px;
    text-align: center;

    &[class^="icon-"],
    &[class*=" icon-"] {
      line-height: 72px;
    }

    &.above-avg {
      background: $good1;
    }

    &.below-avg {
      background: $bad1;
    }
  }

  .devices-stats {
    .devices-number {
      color: $gray-cold5;
      font-size: 19px;
      font-weight: 500;
    }

    .devices-label {
      color: $gray-cold4;
      font-size: 14px;
    }
  }

  .more-info {
    border-top: 1px solid rgba($gray7, 0.11);
    margin: 8px 16px 0;
    padding: 8px 0;
  }

  .more-info-row {
    margin: 16px 0;
    color: $gray-cold6;

    .title {
      margin-bottom: 16px;
      color: $primary1;
      font-size: 18px;
      font-weight: 500;

      [class^="icon-"],
      [class*=" icon-"] {
        padding-right: 8px;
      }
    }
  }

  .show-more-btn {
    height: 65px;
    border-radius: 0 0 5px 5px;
    background: $gray-cold2;
    color: $primary1;
    font-weight: bold;
    cursor: pointer;

    [class^="icon-"],
    [class*=" icon-"] {
      padding-right: 8px;
    }
  }

  .columns-2,
  .columns-3 {
    margin: 0 24px;
  }

  .columns-2 {
    columns: 2;
  }

  .columns-3 {
    columns: 3;
  }
}
