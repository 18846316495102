@use "/src/styles/base/resources" as *;

.test-definition-fail-codes-editor {
  .cc-content-panel {
    > header {
      padding: 5px 10px;
    }
  }

  .col-check {
    flex: 0 0 40px;
    margin-right: 10px;
  }

  .col-category {
    flex: 0 0 80px;
    margin: 0 5px;
  }

  .col-name {
    flex: 0 1 80px;
    margin: 0 5px;
  }

  .col-desc {
    flex: 1 0 auto;
    margin: 0 5px;
  }

  .col-ff {
    flex: 0 1 60px;
    margin: 0 5px;
    text-align: center;
  }

  .col-desktop {
    flex: 0 1 60px;
    margin-left: 5px;
    text-align: center;
  }
}
