@use "/src/styles/base/resources" as *;

.custom-field-list-options-editor {
  .md-grid {
    padding: 0;

    .md-cell {
      margin: 0;
    }
  }

  .head {
    background-color: $blue;
    color: white;
    font-weight: bold;
    margin: 5px 0;
  }

  .list-item {
    &:nth-of-type(odd) {
      background-color: transparentize($blue, 0.95);
    }

    &:nth-of-type(even) {
      background-color: transparentize($blue, 0.9);
    }
  }

  .display-names {
    padding: 5px;

    > div {
      border: 1px solid white;

      &:nth-of-type(odd) {
        background-color: $blue;
        color: white;
        font-weight: bold;

        input {
          background-color: white;
        }
      }
    }
  }

  .list-item-value {
    border-left: 1px solid white;
    padding: 5px;
  }
}
