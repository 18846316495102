@use "/src/styles/base/resources" as *;

.details-section {
  border-radius: 5px;
  border: 1px solid #ededed;
  background-color: #e9f4fd;
  margin-bottom: 0.5rem;

  .section-title {
    text-align: center;
    font-weight: bold;
    font-size: 1.25rem;
    color: white;
    background-color: #005287;
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid #98cb33;
    user-select: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;

    .expand-icon {
      color: white;
      cursor: pointer;
    }
  }

  .section-body {
    padding: 0.25rem;
  }
  &.details-table {
    .section-body {
      max-height: 20rem;
      overflow-y: auto;
    }

    table {
      border-top: none;
    }

    thead tr {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #e9f4fd !important;
      border-top: none;
    }
  }
}
.double-section {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .details-section {
    flex-grow: 1;
    &:first-child {
      margin-right: 0.5rem;
    }

    &.details-table {
      .section-body {
        padding: 0;
        height: 100%;
      }
    }
  }
  margin-bottom: 0.5rem;
}
