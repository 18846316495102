@use "/src/styles/base/resources" as *;

.customer-teams {
  .cc-table table tbody tr {
    &.team-row {
      background: none;
    }

    &.default-team {
      background: $old-primary7;

      &:hover {
        background: rgba($primary3, $alpha: 0.5);
      }

      .team-name {
        color: $primary1;
        font-weight: bold;
      }
    }
  }

  .icon-file-text {
    font-size: 20px;
  }
}
