@use "/src/styles/base/resources" as *;

.programs-list {
  .see-more {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 16px;
    .ant-popover-title {
      background: $blue;
      color: $white;
      font-weight: bold;
      font-weight: 500;
      line-height: 19px;
    }
  }
}
