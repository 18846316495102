@use "/src/styles/base/resources" as *;

.suite-template-list {
  .identifiers {
    div:first-of-type {
      width: 350px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 1.5em;
    }
  }

  .see-more {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 16px;
    .ant-popover-title {
      background: $blue;
      color: $white;
      font-weight: bold;
      font-weight: 500;
      line-height: 19px;
    }
  }
}
