@use "/src/styles/base/resources" as *;

.composed-chart {
  width: 1260px;
  height: 600px;
  margin: 0 auto;

  .chart-header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: 0.5em;
    justify-items: center;
    margin: 2em 0 1em;

    .chart-title {
      color: #262e47;
      font-size: 22px;
      font-weight: 500;
      grid-column-start: 2;
      margin: auto;
    }
  }

  .recharts-default-tooltip {
    margin: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border-left: 6px solid #2a6ebf;
    white-space: nowrap;

    .recharts-tooltip-label {
      margin: 0;
      text-transform: none;

      .accent {
        font-weight: bold;
        font-style: italic;
        font-size: 1.1em;
        color: #2a6ebf;
      }
    }
  }

  .recharts-default-legend {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
