@use "/src/styles/base/resources" as *;

@keyframes fade-and-slide-in-to-left {
  from {
    opacity: 0;
    transform: translateX(50%);
    overflow: hidden;
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fade-and-slide-in-to-right {
  from {
    opacity: 0;
    transform: translateX(-50%);
    overflow: hidden;
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.cc-tabs {
  overflow: hidden;

  &.full-height {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  .react-tabs__tab-list {
    margin: 0;
    display: flex;
    flex-direction: row;

    .react-tabs__tab {
      background-color: #e1e1e1;
      color: $dark-color;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      border-left: 1px solid #ccc;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0 1 auto;
      height: 48px;
      min-width: 140px;
      padding: 0 30px;

      &.expand {
        flex: 1 1 auto;
        padding: 0 15px;
      }

      &:focus {
        outline: none;
      }

      &:first-of-type {
        border-left: 0;
        border-top-left-radius: 5px;
      }

      &:last-of-type {
        border-top-right-radius: 5px;
      }

      &:hover {
        cursor: pointer;
      }

      &.react-tabs__tab--selected {
        background-color: $blue;
        color: $white;
      }
    }
  }

  .react-tabs__tab-panel {
    background-color: $white;
  }

  .react-tabs__tab-panel--selected {
    opacity: 1;

    &.full-height {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
    }
  }

  &.animate-to-left {
    .react-tabs__tab-panel {
      &.react-tabs__tab-panel--selected {
        animation: fade-and-slide-in-to-left 350ms ease-out;
      }
    }
  }

  &.animate-to-right {
    .react-tabs__tab-panel {
      &.react-tabs__tab-panel--selected {
        animation: fade-and-slide-in-to-right 350ms ease-out;
      }
    }
  }
}
