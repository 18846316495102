@use "/src/styles/base/resources" as *;

.certificate-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: lighten(#000, 0%);
  height: 30px;
  min-height: 30px;
  color: $white;
  font-size: 10px;
}
