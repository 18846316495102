@use "/src/styles/base/resources" as *;

.fail-codes-categories-page {
  $border-color: #979797;
  $primary-column-background: #4e4e4e;
  $secondary-column-background: #696969;
  $tertiary-column-background: #848484;
  // Padding, section header, header, subheader, footer, scrollbar, margin of error
  $height-of-other-elements: calc(64px + 60px + 32px + 56px + 64px + 17px + 3px);

  padding: 32px !important;

  @mixin header {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 5px;
  }

  @include mq("phone-wide", "max") {
    width: 100%;
  }

  .fail-codes-table {
    box-shadow: $glow-shadow;
  }

  .cc-section-header {
    border-radius: 8px 8px 0 0;
  }

  .content-wrapper {
    border: 1px solid $border-color;
  }

  .selection-columns {
    background: white;
    max-width: 100%;
    flex: 1 1 100%;
    overflow: auto;

    > div:not(:last-of-type) {
      border-right: 1px solid $border-color;
    }

    .header-column {
      @include header;
      height: 32px;
      color: $white;
      font-weight: 500;
      text-align: center;
    }

    .subheader-column {
      @include header;
      height: 56px;
      background: $grey;
      border-bottom: 1px solid $border-color;
    }

    .scroll-container {
      height: calc(100vh - #{$navbar-height} - #{$footer-height} - #{$height-of-other-elements});
      overflow: auto;
    }

    .primary-container {
      flex: 4;
      min-width: 435px;

      .header-column {
        background: $primary-column-background;
      }

      .subheader-column .priority {
        padding: 4px;
        width: 48px;
      }
    }

    .secondary-container {
      flex: 5;
      min-width: 555px;

      .header-column {
        background: $secondary-column-background;
      }
    }

    .tertiary-container {
      flex: 3;
      min-width: 310px;

      .header-column {
        background: $tertiary-column-background;
      }

      .icon {
        width: 80px;
      }

      .name {
        max-width: 25%;
        min-width: 25%;
      }
    }
  }

  .actions-footer {
    background: $grey;
    padding: 1em 0;
    max-width: 100%;
    overflow: hidden;

    .primary-container {
      flex: 4;
      min-width: 435px;
      justify-content: flex-end;
    }

    .secondary-container {
      flex: 5;
      min-width: 555px;
      justify-content: flex-end;
    }

    .tertiary-container {
      flex: 3;
      min-width: 310px;
    }
  }

  .white {
    color: $white;
  }

  .trash {
    color: $red;
  }
}

.dialog-section {
  padding: 15px;

  &:not(:nth-last-of-type(2)) {
    border-bottom: 1px $grey solid;
  }

  h3 {
    margin-bottom: 10px;
  }
}
