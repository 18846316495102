@use "/src/styles/base/resources" as *;

.duplicate-template-dialog {
  .identifier-input {
    margin-bottom: 10px;
    .identifier-label {
      font-size: 15px;
      color: $gray8;
      line-height: 35px;
      margin-right: 6px;
    }

    .identifier-field {
      margin-right: 5px;
      border: 0.5px solid $gray2;
      border-radius: 3px;
      height: 35px;
      min-width: 100px;
      background-color: $gray1;
      padding-left: 5px;
      position: relative;
      font-size: 16px;

      .md-divider {
        display: none;
      }
      .md-text {
        font-size: 15px;
        margin-top: 10px;
        padding-left: 3px;
        color: $gray8;
      }

      .md-text-field-icon {
        display: none;
        line-height: 0px;
        position: absolute;
        margin-left: 140px;
        font-size: 15px;
        color: $gray5;
      }
    }
  }

  .btn-group {
    justify-content: flex-end;
    margin-top: 10px;

    .asci-btn.green-button.confirm-btn {
      box-shadow: none;
      text-shadow: none;
      padding: 0 10px;
      min-width: 70px;
      min-height: 35px;
      border-radius: 4px;
    }

    .asci-btn.white-button.cancel-btn {
      background-color: $gray1;
      box-shadow: none;
      text-shadow: none;
      padding: 0 10px;
      min-width: 70px;
      min-height: 35px;
      border-radius: 4px;

      &:hover:not([disabled]) {
        background-color: $gray3;
      }
    }
  }
}
