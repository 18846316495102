@use "/src/styles/base/resources" as *;

.recharts-responsive-container {
  .recharts-wrapper {
    .recharts-surface {
      border-radius: 5px;
    }

    .recharts-tooltip-wrapper {
      .recharts-default-tooltip {
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        box-shadow: 0 3px 8px rgba($color: $black, $alpha: 0.2);
      }
    }
  }
}
