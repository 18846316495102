@use "/src/styles/base/resources" as *;

.grouped-buttons {
  display: flex;
  border: #e4e4e4 solid 1px;
  border-radius: 3px;
  flex-wrap: nowrap;
  background: #fff;

  .option-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    border-left: #e4e4e4 solid 1px;

    &.selected {
      background-color: #3491fd !important;
    }
    &:hover {
      background-color: #e1e1e1;
      cursor: pointer;
      user-select: none;
    }

    &:first-child {
      border-radius: 3px 0 0 3px;
      border-left: none;
    }
    &:last-child {
      border-radius: 0 3px 3px 0;
    }
  }
}
