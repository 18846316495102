@use "/src/styles/base/resources" as *;

.category-tests-selector {
  $border-color: #ccc;

  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;

  > header {
    padding: 10px 8px;
    border-bottom: 1px solid $border-color;

    &:hover {
      cursor: pointer;
      background-color: darken(white, 3%);
    }

    span {
      color: $dark-color;
    }

    .md-icon {
      display: inline-block;
      color: $dark-color;
      margin: 0 10px;
      transition: 300ms transform ease;
      transform: rotate(0);
      font-size: 20px;
    }
  }

  .columns-header {
    background-color: #666;
    color: white;

    > div {
      padding: 5px 10px;
    }

    .status {
      width: 80px;
      text-align: center;
    }

    .test {
      width: 300px;
      text-align: center;
    }

    .select {
      width: 50px;
      padding: 0;

      .md-selection-control-toggle {
        color: white;
      }
    }
  }

  &.open {
    > header {
      .md-icon {
        transform: rotate(-90deg);
      }
    }
  }
}
