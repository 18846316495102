@use "/src/styles/base/resources" as *;

.devices-processed-by-os-page {
  .report-results-wrapper {
    background: #e5eef1;

    .report-results {
      margin: 0 3em;
      padding: 1em 0;
      border-top: 1px solid rgba($gray-cold4, 0.2);
      align-items: center;
      justify-content: space-between;
    }

    .result-item {
      margin-right: 2em;
      align-items: center;
    }
    .radio-label {
      margin-right: 0.5em;
      align-items: center;
    }
    .report-statistics {
      display: flex;
      flex-direction: column;
    }
    .report-radio-group {
      margin-top: 0.5em;
    }
  }

  .md-dialog {
    max-width: 1000px;
  }

  /* antd overrides */

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #005288;
    border-color: #005288;
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #186694;
    border-color: #186694;
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #fff;
    background: #003761;
    border-color: #003761;
  }

  .ant-radio-button-wrapper:hover {
    color: #005288;
  }
}
