@use "/src/styles/base/resources" as *;

.condition-editor {
  display: flex;
  justify-content: space-between;
  background: #fafafa;
  border-radius: 3px;
  padding: 0.25rem;
  margin: 0.25rem;
  border: 1px solid #dedede;
  align-items: center;

  .condition-editor-body {
    display: flex;
  }

  .remove-condition {
    cursor: pointer;
    user-select: none;
    color: red !important;
    &:hover {
    }
  }
}
