@use "/src/styles/base/resources" as *;

.asci-list-page {
  table thead tr {
    background-color: #e1e1e1;
    border-bottom: 1px solid #d4d4d4;
    th {
      background-color: #e1e1e1;
    }
  }
  .list-filters {
    .filter-field {
      background-color: #e7e7e8;
    }
    background-color: #dfe0e0;
    .panel-container {
      padding: 1rem !important;
    }
  }

  .list-page-title {
    padding: 0.66rem;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    background-color: #005287;
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid #98cb33;
    color: white;
  }

  .list-body {
  }
  .list-table {
    thead tr {
      position: sticky;
      top: 0;
      z-index: 15;
    }
  }
}
