// This file is a resource module.
// It is listed in ./_resources.scss
// All resource modules must be imported in all .scss files with `@use "/src/styles/base/resources"`.

// Paths
$iconsPath: "/styles/icons";
$imagesPath: "/media/images";
$imagesBasePath: $imagesPath + "/base";

// Font sizes
$small-font-size: 12px;
$normal-font-size: 14px;
$big-font-size: 16px;

$footer-height: 55px;
$navbar-height: 70px;

// Other styles
$border-radius: 8px;
$border-radius-top: $border-radius $border-radius 0 0;
$border-radius-bottom: 0 0 $border-radius $border-radius;
$box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.1);
$glow-shadow: 0px 0px 60px #0000001a;

// NEW color palette
// Primary
$primary1: #005288;
$primary2: #4a90e2;
$primary3: #9bcaf5;

// Neutral
$white: #ffffff;
$black: #000000;

$gray1: #f3f3f3;
$gray2: #f2f2f2;
$gray3: #e3e3e3;
$gray4: #e1e1e1;
$gray5: #a7a7a7;
$gray6: #999999;
$gray7: #979797;
$gray8: #6d6d6d;
$gray9: #676767;

$gray-cold1: #eff4f5;
$gray-cold2: #e9eef1;
$gray-cold3: #e7eced;
$gray-cold4: #929799;
$gray-cold5: #696e6f;
$gray-cold6: #626769;
$gray-cold7: #262e47;

$gray-bg: $gray-cold2;

// Validation
// Green (good)
$good1: #5cd9a7;

// Red (bad)
$bad1: #e55e67;

// Yellow (warning)
$warning1: #f8ac1c;

// Accents
$accent1: #5cd9c7;
$accent2: #f8ac1c;
$accent3: #bee695;
$accent4: #f7a0d7;
$accent5: #a5a4f6;

// OLD color palette
$white-dirty: darken($white, 5%);
$yellow: #ffff00;
$yellow-ligth: lighten($yellow, 20%);
$green: #99cc33;
$green-steel: #096059;
$green-ligth: lighten($green, 20%);
$blue: #005288;
$blue-ligth: #d1dfe9;
$blue-sky: saturate(lighten($blue, 10), 7);
$dark-blue: #043863;
$grey: #ccc;
$grey-ligth: #e6e6e6;
$grey-header: darken($grey-ligth, 2%);
$grey-description: #999999;
$dark-color: darken($grey, 50%);
$red: rgb(253, 0, 0);

// Primary
$old-primary1: #003366;
$old-primary2: #005288;
$old-primary7: #ebf6ff;
