@use "/src/styles/base/resources" as *;

// .only-printable: will be hidden on screen and displayed on print media.
// .no-printable: will be displayed on screen and hidden on print media.
// .printable-page: style for non label printing page (e.x. reports and certificates)

.only-printable {
  visibility: hidden;
  height: 0;
  width: 0;
}

@media print {
  html,
  body {
    overflow: visible;
    background: $white;
  }

  .only-printable {
    visibility: visible;
    height: auto;
    width: auto;
  }

  .no-printable {
    display: none;
  }

  .printable-page {
    font-size: 11px;
    height: auto;

    .list-content {
      page-break-after: auto;
      margin-bottom: 48px;

      thead {
        break-inside: auto;
      }
    }
  }
}
