@use "/src/styles/base/resources" as *;

.cc-details-panel {
  background-color: #eaeaea;
  padding: 20px;
  margin: 10px 0;
}

.cc-fullwidth-panel {
  &.md-panel-content {
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
.cc-footer-panel-container.md-fake-btn {
  background: $blue;
  color: $white;
  .panel-text {
    color: $white;
    font-size: 16px;
  }
  &.white-header {
    color: $black;
    background: $white;
    border: 1px solid $grey;
    border-bottom: 3px solid $green;
    .panel-text {
      color: $black;
    }
  }
}
.cc-section-header {
  .toolbar-title {
    color: white;
  }
  .md-switch-track--on {
    background: rgba($green, 0.5);
  }
  .md-switch-thumb--on {
    background: $green;
  }
}

.cc-input {
  min-width: 50px;
  appearance: none;
  background-color: transparent;
  line-height: 30px;
  padding: 0 8px;
  padding-right: 30px;
  font-size: 1em;
  color: #4d4d4d;
  border: none;
  width: 100%;

  border-bottom: $grey solid 1px;

  &:focus {
    outline: none;
    border-bottom: #4d4d4d solid 2px;
  }
}

.cc-date-picker {
  .react-datepicker__close-icon {
    height: 2em;
    width: 2em;
    top: 0.5em;
    &::after {
      content: "\F057";
      font-size: 1.5em;
      font-family: "icomoon";
      background: transparent;
      text-align: center;
      width: 1.5em;
      height: 1.5em;
      color: darken($grey, 5%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      transition-duration: 0.15s;
      transition-property: color;
    }
    &:hover,
    &:focus,
    &:active {
      &::after {
        background: $grey;
        color: $blue;
      }
    }
  }
}

.form-layout {
  .field-row {
    display: flex;
    flex-direction: row;
    align-items: center; // vertical align center
    padding: 5px;

    .field-name {
      flex: 1 1 30%;

      .description {
        color: #888;
        font-size: 0.8em;
      }
    }

    .field-control {
      flex: 1 1 70%;

      &.align-end {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.radio-inline {
  display: flex;
  flex-direction: row;
}

.cc-grey-select-container {
  justify-content: flex-start;
  .browser-default {
    margin-top: 12px;
    padding-top: 0;
  }
  & > .cc-select-list {
    background: transparent;
    border: none;
    margin-top: 10px;
    padding-top: 0;
    select {
      appearance: none;
      border-bottom: 1px solid;
      border-radius: 0;
    }
  }
}

.cc-divider {
  &.horizontal-margin {
    margin: 5px 15px;
  }
}
