@use "/src/styles/base/resources" as *;

.user-logs-list {
  width: 80%;
  display: flex;
  justify-content: center;
  padding: 15px 0;

  .logs-table {
    background: white;
    border-collapse: collapse;
    border-radius: 8px;
    box-shadow: $glow-shadow;

    .md-table-column {
      border: 1px solid $gray-cold2;
    }

    .logs-table-header {
      .md-text--secondary {
        color: white;
        text-shadow: none;
        font-size: 18px;
        padding: 0 20px;

        &.sortable {
          &:hover {
            cursor: pointer;
          }

          &::after {
            font-family: "icomoon";
            margin-left: 8px;
          }
        }

        &.is-sorted {
          &::after {
            content: "\F0D8";
          }

          &.sort-reverse {
            &::after {
              content: "\F0D7";
            }
          }
        }
      }
      background-color: $primary1;
    }

    .logs-table-body {
      .md-text {
        font-size: 16px;
        padding: 0 20px;
        color: $gray8;
      }
    }
  }
}
