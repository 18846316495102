@use "/src/styles/base/resources" as *;

.asci-timestamp {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .asci-date {
    font-weight: bold;
  }
  .asci-time {
    font-style: italic;
    color: #7e7e7e;
    font-size: 0.9rem;
  }

  &.asci-timestamp-inline {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .asci-time {
      font-size: initial;
    }
    .asci-date {
      font-weight: initial;
      &:after {
        content: ", ";
      }
    }
  }
}
