@use "/src/styles/base/resources" as *;

.gear-icon-btn {
  position: relative;

  &[class*="disabled"] {
    svg {
      fill: #099dff;
    }
    .button-text {
      color: #595958;
      opacity: 0.8;
    }
  }

  &[class*="enabled"] {
    .button-text {
      color: $white;
    }
    &:not(.active) {
      svg {
        fill: $blue;
      }
    }
    &.active {
      svg {
        fill: $green;
      }
    }
  }
}
