@use "/src/styles/base/resources" as *;

.fail-code-categories-list {
  $selector-row-height: 60px;
  $border-color: #979797;

  .selector-row {
    height: $selector-row-height;
    border-bottom: 1px solid $border-color;
    padding: 5px;

    &.checked {
      background: $grey-ligth;
    }

    &.current {
      background: #bbe4ff;
    }

    &.hidden {
      > div {
        opacity: 0.33;
      }

      .visible {
        opacity: 1;
      }
    }

    .handle,
    .order {
      padding: 4px;
      width: 24px;
      text-align: center;
    }

    .handle:hover {
      cursor: pointer;
    }

    .content-pointer {
      color: $grey;
    }
  }

  .fail-code-category-name {
    width: 40%;
    flex-shrink: 0;
    padding: 5px;
  }
}
