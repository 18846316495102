@use "/src/styles/base/resources" as *;

.service-suite-wizard {
  color: #53565d;
  position: relative;
  padding: 2em;
  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    padding: 1.5em 1em 1em 1em;
    color: #53565d;
  }
  h3 {
    color: #53565d;
    font-size: 18px;
    line-height: 21px;
    padding: 0.5em;
  }
}

.error-button-message {
  height: 20px;
  width: 204px;
  color: $red;
  font-size: 16px;
  font-style: italic;
  line-height: 20px;
  text-align: center;
  padding: 15px 0;
}

.service-wizard-content {
  width: 100%;
  min-height: 350px;
  & > *:first-child {
    max-height: 350px;
    overflow-y: auto;
    margin-bottom: 34px;
  }
}
.footer-btns {
  position: absolute;
  bottom: 0;
  padding: 1em;
  background-color: $grey-ligth;
  border-radius: 0 0 9px 9px;
}

.filter-service-suite {
  border-bottom: 1px solid $grey;
  padding-bottom: 20px;
  margin: 15px;
  width: calc(100% - 40px);
}
