@use "/src/styles/base/resources" as *;

@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  animation: anim-rotate 2s infinite linear;
  color: #fff;
  text-shadow: 0 0 0.25em rgba(255, 255, 255, 0.3);
}

.spinner--steps {
  animation: anim-rotate 1s infinite steps(8);
}

.spinner--steps2 {
  animation: anim-rotate 1s infinite steps(12);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
}
