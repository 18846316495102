@use "/src/styles/base/resources" as *;

// EXAMPLES
// button icon
// <Button mini floating className="icon-btn blue-button" iconClassName="icon-info" onClick= {
//     this.handleInfoOnCLick.bind(this)
// }></Button>
// square button with icon
// <Button flat className="asci-btn green-button" iconClassName="icon-user-plus2" onClick= {
//     this.handleAddOperator.bind(this)
// }
// >Add Operator </Button>
@function set-shadow-color($selectedColor) {
  @if (lightness($selectedColor) > 60) {
    @return lighten($selectedColor, 40%);
  } @else {
    @return darken($selectedColor, 20%);
  }
}

@function set-background-color($selectedColor) {
  @if (lightness($selectedColor) > 50) {
    @return darken($selectedColor, 5%);
  } @else {
    @return lighten($selectedColor, 5%);
  }
}

@mixin asci-button($color, $background, $activeColor: $color, $iconColor: $color) {
  $background-disabled: saturate(lighten($background, 20), 10);
  $color-disabled: darken($color, 70);
  font-family: "Roboto", "Open Sans", Helvetica, Arial, sans-serif;
  cursor: pointer;
  padding: 5px 7px;
  margin: 0 10px;
  line-height: 1em;
  text-transform: none;
  font-size: $normal-font-size;
  color: $color;
  text-shadow: 0px 1px 1px set-shadow-color($background);
  background: $background;
  display: flex;
  align-items: center;
  justify-content: center;

  @include border-radius;

  & > * {
    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
    white-space: nowrap;
  }

  // TODO: move that out of here
  .space-right {
    margin: 0 5px 0 0;
    padding: 0;
    & ~ i {
      margin-right: 0;
    }
  }

  &.left-margin {
    margin: 0 0 0 10px;
  }

  &.mini {
    width: 24px;
    height: 24px;
  }

  i {
    font-size: 1.25em;
  }

  &:hover,
  &:focus,
  &:active {
    background: set-background-color($background);
    color: $activeColor;
  }

  &[class*="-disabled"] {
    background: set-background-color($background-disabled);
    color: set-background-color($color-disabled);
    opacity: 0.8;
    cursor: not-allowed;
    text-shadow: unset;
    i {
      color: set-background-color($color-disabled);
    }
  }

  // TODO: move that out of here
  &.nav-button {
    font-size: $normal-font-size;
    min-width: 150px;
    .md-icon-separator {
      height: fit-content;
      .md-icon-text {
        text-align: center;
      }
    }
  }
}

body {
  .position-btns {
    color: $blue;
    & > span {
      margin: 0 2px;
      font-size: 1.5em;
      &:active {
        background-color: $blue;
        color: transparent;
        text-shadow: 1px 1px 1px rgba(111, 164, 218, 0.8);
        background-clip: text;
      }
    }
  }

  .asci-btn {
    padding: 0px;
    color: darken($grey, 25%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;

    & > div {
      font-size: $normal-font-size;
    }
    &:hover,
    &:focus,
    &:active {
      color: $blue;
    }
    &.primary-button {
      @include asci-button($white, $grey, $white);
    }
    &.secondary-button {
      @include asci-button($white, $dark-color, $grey);
    }
    &.white-button {
      @include asci-button($dark-color, $white, $dark-color, $dark-color);
    }
    &.green-button {
      @include asci-button($white, $green, $white, $blue);
    }
    &.blue-button {
      @include asci-button($white, $blue, $white, $white);

      &:not([disabled]) {
        .md-icon {
          color: set-background-color($white);
        }
      }
    }
    &.red-button {
      @include asci-button($white, $red, $white, $red);

      &:not([disabled]) {
        .md-icon {
          color: set-background-color($white);
        }
      }
    }

    &.no-margin {
      margin: 0;
    }
  }
}

.dialog-buttons {
  position: relative;
  top: 15px;
}

// removed from all buton stuff above
.icon-btn {
  color: $blue;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding: 0;
  &.icon-text-button {
    .md-icon-text:last-child {
      padding-left: 5px;
      font-size: 16px;
    }
  }
}
