@use "/src/styles/base/resources" as *;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cc-spinner {
  $size: 40px;

  height: $size;
  display: inline-block;

  i {
    display: block;
    color: $blue;
    font-size: $size;
    animation: spin 3s linear infinite;
  }
}
