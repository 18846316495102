@use "/src/styles/base/resources" as *;

.team-fail-codes {
  margin: 36px 0 26px;

  .fail-codes-header {
    margin-bottom: 26px;

    .title {
      margin-bottom: 18px;
      color: $primary1;
      font-size: 18px;
      font-weight: bold;
    }

    .instructions {
      color: $gray8;
    }
  }

  .custom-tooltip {
    background: rgba(#485465, 0.9);
    padding: 0.25em 0.5em;
    border-radius: 3px;

    .label {
      font-size: 12px;
      font-weight: 500;
      color: $white;
      margin: 0;
    }
  }

  .ant-tooltip-inner {
    background: rgba(#485465, 0.9);
  }

  .ant-tooltip-arrow::before {
    background: rgba(#485465, 0.9);
  }

  .recharts-pie-labels text {
    font-size: 14px;
    font-weight: 500;
    text-shadow: 0 2px 14px rgba($black, 0.35);
  }

  .recharts-legend-wrapper {
    padding: 36px 8px 8px;
    border-top: 2px solid rgba($gray7, 0.32);

    .recharts-legend-item {
      margin-bottom: 28px;

      .recharts-legend-item-text {
        margin-left: 4px;
      }
    }
  }

  .no-data {
    margin: 2em 1em;

    [class^="icon-"],
    [class*=" icon-"] {
      margin-right: 0.5em;
    }
  }
}
