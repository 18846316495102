@use "/src/styles/base/resources" as *;

@import "./layout";
@import "./data-list";

.border-grey {
  border: 1px solid $grey;

  &--left {
    border-left: 1px solid $grey;
  }

  &--right {
    border-right: 1px solid $grey;
  }
}
