@use "/src/styles/base/resources" as *;

.recording-preview {
  display: flex;
  .recording-preview-label {
    font-weight: bold;
    margin-right: 16px;
  }
  .recording-preview-value {
  }
}
