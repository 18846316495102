@use "/src/styles/base/resources" as *;

.dashboard-filters {
  background: $gray-cold2;
  box-shadow: 0 4px 11px 2px rgba(0, 0, 0, 0.08);
  padding: 20px;

  .filters {
    flex-wrap: wrap;

    .filter-container {
      width: 180px;

      .filter-label {
        color: $primary1;
        font-weight: bold;
        margin-bottom: 0.5em;
      }

      .cc-select-list {
        border: 1px solid $gray1;
        font-size: 14px;

        &::after,
        select {
          color: $gray5;
        }
      }
    }

    .filter-separator {
      height: 60px;
      width: 1px;
      background: rgba($gray-cold4, 0.2);
    }

    .filter-container,
    .filter-separator {
      margin: 0.5em 1em 0.5em 0;
    }
  }

  .actions {
    width: 180px;

    .btn-group {
      margin-bottom: 0.5em;
    }

    .asci-btn {
      font-size: 16px;
      padding: 0 0.5em;
      margin: 0 1em 0.5em;
    }

    .secondary-btn {
      color: $primary1;
      font-weight: 500;
      cursor: pointer;

      span {
        text-decoration: underline;
      }

      [class^="icon-"],
      [class*=" icon-"] {
        margin-right: 0.5em;
      }
    }
  }

  .bordered-group-container {
    padding: 0;
    border: none;
    background: none;

    .date-time-selector .date-time-selector-instruction {
      color: $primary1;
      font-weight: bold;
      margin-bottom: 0.5em !important; // TODO: not use !important
    }
  }
}
