@use "/src/styles/base/resources" as *;

.statistics-table {
  border: 1px solid $gray-cold2;
  .switch-dashboard {
    width: 100%;
    .direction-row {
      float: right;
      margin-right: 15px;
    }
  }
  .no-data-uph {
    margin-block: auto;
  }
  .recharts-legend-wrapper {
    margin-bottom: -40px;
  }
  .recharts-legend-item {
    cursor: pointer;
  }
  .statistics-table-row {
    height: 64px;
  }

  .statistics-table-body {
    height: 100%;
    min-height: 320px;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: $gray4;
      border-radius: 4px;
      margin: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: $primary1;
      border-radius: 4px;
    }
  }

  .table-header,
  .table-body,
  .table-footer {
    min-width: 578px;
  }

  .table-header,
  .table-footer {
    > .cc-layout-container:first-child {
      padding-left: 16px;
    }
  }

  .table-body,
  .table-footer {
    color: $gray8;
  }

  .table-header {
    background: $primary1;
    color: $white;
    font-size: 18px;
    font-weight: 500;
  }

  .table-body {
    min-height: 100%;
    border-right: 1px solid $gray-cold2;

    &:not(.last-level) {
      .statistics-table-row {
        cursor: pointer;

        &:hover {
          background: rgba($gray-cold1, 0.5);
        }
      }
    }

    .statistics-table-row {
      border-bottom: 1px solid $gray-cold2;

      > .cc-layout-container:first-child {
        padding-left: 16px;
        max-width: 25%;

        [class^="icon-"],
        [class*=" icon-"] {
          color: $primary1;
          padding-right: 12px;
        }
      }
    }

    .item-name {
      width: 100%;

      > div:first-child {
        font-weight: 500;
      }
    }
  }

  .table-footer {
    background: $gray-cold2;
    color: $gray8;
    font-size: 18px;
    font-weight: bold;
  }

  .charts-header {
    color: $primary1;
    font-size: 18px;
    font-weight: bold;
    padding-right: 16px;

    ul {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-column-gap: 0.5em;
      justify-items: center;

      li {
        &:nth-child(1) {
          grid-column-start: 2;
          margin: auto;
        }

        &:nth-child(2) {
          margin-left: auto;
        }
      }
    }

    .cc-toggle-btn {
      [class^="icon-"],
      [class*=" icon-"],
      .anticon {
        padding-right: 0.5em;
      }
    }
  }

  .charts-body {
    min-height: 100%;

    .statistics-table-row {
      padding: 8px 24px 8px 24px;
      overflow: hidden;

      &.heat-map {
        width: 99%;
      }
    }
  }

  .charts-footer {
    padding: 0 24px;
    overflow: hidden;
  }

  .ant-spin-spinning {
    height: 100%;
    margin: auto 0;
  }

  .no-data {
    height: 100%;
    margin: auto 0;

    [class^="icon-"],
    [class*=" icon-"] {
      margin-right: 0.5em;
    }
  }
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small-text {
  font-size: 0.8em;
}
