@use "/src/styles/base/resources" as *;

.service-container {
  .drag-expandable {
    width: 50px;
    align-items: center;
    justify-content: center;
    margin-right: 1px;
    font-size: 16px;
    display: flex;
    height: 60px;
    background-color: #f3f3f3;
    margin: 4px 1px 4px 0;
  }
  .cc-expandable-panel {
    flex-grow: 1;
  }
}
