@use "/src/styles/base/resources" as *;

.certificate-container {
  .certificate-header {
    height: 25px;
    min-height: 25px;
    font-size: 13px;
    background: lighten(#000, 50%);
    font-weight: bold;

    .title span {
      font-weight: bold;
    }
  }

  .certificate-main-info {
    .md-table-column:first-of-type {
      font-weight: bold;
      width: 25%;
    }

    .md-cell--6 .md-table-column:first-of-type {
      width: 50%;
    }
  }

  .status-container * {
    color: $dark-color;
  }
}
