@use "/src/styles/base/resources" as *;

.drop-image-wrapper {
  width: 100%;
  @include flexbox;
  @include flex-flow(column nowrap);
  @include align-items(center);
  @include justify-content(center);
  form {
    width: 100%;
  }
  .drop-image-zone {
    background: $grey-ligth;
    max-height: 50px;
    min-height: 100px;
    width: 100%;
    overflow: hidden;
    border: 2px dashed $grey;
    @include border-radius(5px);
    @include flexbox;
    @include flex-flow(column nowrap);
    @include align-items(center);
    @include justify-content(center);
    .image-dropped {
      height: 100%;
      width: auto;
      object-fit: contain;
    }
    &.active {
      background: $green-ligth;
    }
    .error-message {
      color: #f44336;
      font-size: 1em;
      font-weight: bold;
    }
    .info-message {
      color: $dark-color;
      font-size: 1em;
      font-weight: bold;
    }
  }
  .drop-image-instructions {
    font-size: 0.9em;
    margin: 10px 0;
  }
}
