@use "/src/styles/base/resources" as *;

.service-suite-templates-page {
  background: $gray-cold2;

  .new-template-row {
    margin-top: 2%;

    .asci-btn.blue-button.new-template-btn {
      box-shadow: none;
      text-shadow: none;
      padding: 0 10px;
      margin-bottom: 15px;
      min-width: 70px;
      min-height: 35px;
      border-radius: 4px;
      &:hover:not([disabled]) {
        background-color: $dark-blue;
      }
    }
  }
}
