@use "/src/styles/base/resources" as *;

.custom-field-section {
  padding-left: 15px;
  .section-title {
    height: 24px;
    color: $gray8;
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    margin: 10px 0;
  }

  .cf-column {
    min-width: 150px;
    margin: 15px 0;

    .cf-label {
      color: $primary1;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.6px;
      line-height: 16px;
      margin: 30px 7px 0px 0;
    }

    .cf-subtitle {
      color: $primary1;
      font-size: 12px;
      font-style: italic;
      font-weight: 500;
      line-height: 16px;
      margin: 0px 7px 13px 0;
    }

    .add-cf-btn {
      margin: 7px 0 0 10px;
    }

    .remove-cf-btn {
      margin-right: 20px;
      height: 30px;
      width: 30px;
      padding: 0;
      color: $primary1;
    }

    .cc-select-list {
      border: 2px solid $gray3;

      width: 170px;
      min-width: 100px;
      line-height: 44px;
      font-size: 14px;

      &::after,
      select {
        color: $gray8;
        min-height: 45px;
      }
    }

    .cc-text-field {
      border: 2px solid $gray3;
      border-radius: 2px;
      background-color: white;
      color: $gray8;
      width: 170px;

      & > input {
        height: inherit;
        width: inherit;
        padding: 6px 0 6px 10px;
        border: none;
      }

      & > .number-controls {
        height: 100%;
        justify-content: space-evenly;
        padding-right: 0.2em;
        top: 0;
        right: 0;
      }
    }
  }
}
