@use "/src/styles/base/resources" as *;

.file-validation {
  padding-left: 15px;
  .section-title {
    height: 24px;
    color: $gray8;
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    margin: 15px 0 35px;
  }

  .md-paper {
    background-color: $white;
    padding: 20px;
    min-width: 270px;
    max-height: 190px;
    border-radius: 3px;
    overflow: scroll;
    -ms-overflow-style: none;

    .box-title {
      padding-top: 13px;
      padding-bottom: 20px;
      font-weight: bolder;
      color: $green;
      font-size: 18px;

      .icon-spinner-10 {
        padding-right: 10px;
        line-height: 25px;
      }

      .icon-check {
        padding-right: 10px;
        line-height: 25px;
      }
    }

    .box-title-error {
      padding-top: 13px;
      padding-bottom: 20px;
      font-weight: bolder;
      color: $red;
      font-size: 18px;

      .icon-exclamation-circle {
        padding-right: 10px;
        line-height: 25px;
      }
    }
    .file-label {
      font-weight: bold;
      padding-right: 20px;

      div {
        padding-bottom: 10px;
      }
    }

    .data-label {
      margin-left: 15px;

      div {
        padding-bottom: 10px;
      }
    }

    .error-list {
      padding-right: 20px;
      margin-bottom: 10px;
      height: 80px;
      .cell-error-message {
        font-weight: bold;
        padding-bottom: 5px;
      }

      .icon-close {
        line-height: 18px;
        padding-right: 5px;
        color: $red;
      }
    }

    .descrition-label {
      padding-bottom: 15px;
      font-size: 16px;
    }
  }

  .md-paper::-webkit-scrollbar {
    display: none;
  }
}
