@use "/src/styles/base/resources" as *;

.cc-expandable-panel {
  $header-height: 60px;
  $header-color: #f3f3f3;
  $content-color: white;

  margin: 4px 0;
  height: $header-height;
  overflow: hidden;

  header {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: $header-height;
    background-color: $header-color;

    > div:first-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;

      .md-selection-control-container {
        height: $header-height;
        width: $header-height;

        .md-btn--icon {
          height: $header-height;
          width: $header-height;
          padding: 20px;
        }

        .md-icon {
          height: 14px;
          width: 14px;
        }
      }
    }

    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
    }

    .secondary-label {
      font-size: 14px;
      font-style: italic;
      color: $gray7;
      margin: 0 1em;
    }

    .arrow-container {
      height: $header-height;
      width: $header-height;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .md-icon {
        font-size: 16px;
        opacity: 0.1;
        transition: 0.2s ease-in-out transform;
      }
    }
  }

  main {
    background-color: $content-color;
    padding: 20px 10px;
    border: 1px solid $header-color;
    border-top: 0;
    display: none;
  }

  &.open {
    height: auto;

    header {
      .icon-chevron-down {
        transform: rotate(180deg);
      }
    }

    main {
      display: block;
    }
  }

  &.toggleable {
    header {
      > div:first-of-type {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &.active {
    &.openable {
      header {
        &:hover {
          cursor: pointer;
        }
      }
    }

    header {
      .icon-chevron-down {
        opacity: 1;
      }
    }
  }
}
