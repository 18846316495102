@use "/src/styles/base/resources" as *;

.apps-collection {
  padding: 20px 0px 0px 0px;
  @include flexbox;
  @include flex-wrap(wrap);
  @include flex-direction(row);
  @include align-items(flex-start);
  @include justify-content(flex-start);
}
