@use "/src/styles/base/resources" as *;

// top level module class set on the main container
.test-definitions-container {
  > .content-container {
    overflow-x: auto;
    align-items: initial !important;

    @include mq("desktop-wide") {
      align-items: center !important;
    }
  }
}

.test-definitions-page {
  $default-columns-width: 50%;
  $default-editor-width: 50%;

  $large-columns-width: 55%;
  $large-editor-width: 45%;

  $border-color: #979797;

  $selection-column-min-width: 200px;
  $test-editor-min-width: 600px;

  $selector-row-height: 60px;
  $selector-row-image-container-width: 70px;
  $selector-row-image-size: 40px;
  $selector-row-arrow-container-width: 32px;

  padding: 2em 10px 80px 10px;
  min-height: calc(100vh - 100px); // TODO: add media queries
  min-width: 1220px;
  max-width: 1440px;
  width: 100%;

  .test-definitions-table {
    box-shadow: $glow-shadow;

    .cc-section-header {
      border-radius: 8px 8px 0 0;
      .filters {
        .asci-search {
          margin: 0;

          > .search-container {
            width: auto;
          }
        }

        + div {
          .md-selection-control-label.md-text {
            color: white;
          }
        }
      }
    }

    .content-wrapper {
      border: 1px solid $border-color;

      .scroll-container {
        overflow-y: auto;
      }

      .categories-container > h2,
      .tests-container > h2,
      .test-configs-container > h2 {
        color: white;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        padding: 5px 10px;
        text-align: center;
      }

      .categories-container {
        background: white;
        > h2 {
          background: #4e4e4e;
        }
      }

      .tests-container {
        background: white;
        > h2 {
          background: #696969;
        }
      }

      .test-configs-container {
        background: white;
        > h2 {
          background: #848484;
        }

        .selector-row {
          padding: 0 15px;
          height: $selector-row-height;

          .name {
            color: #5a5a5a;
            line-height: 18px;
            font-size: 14px;
          }

          &:hover:not(.selected) {
            cursor: pointer;
          }

          &.selected {
            background: #dde3e6;

            .name,
            .arrow .md-icon {
              color: $blue;
            }
          }
        }
      }

      .test-categories-list > div,
      .tests-list > div,
      .selector-row {
        border-bottom: 1px solid $border-color;
      }

      .selection-columns {
        > div {
          border-right: 1px solid $border-color;
        }
      }
    }

    .advanced-view {
      .selection-columns {
        flex: 1 1 $default-columns-width;
        max-width: $default-columns-width;
        min-width: 3 * $selection-column-min-width;

        > div {
          flex: 1 1 33.333%;
          min-width: $selection-column-min-width;
        }
      }
    }

    .test-config-editor {
      background: white;
      width: $default-editor-width;
      flex: 1 1 $default-editor-width;
      min-width: $test-editor-min-width;

      > header {
        background-color: #cacaca;

        h2,
        h3 {
          color: $blue;
          margin: 0;
        }

        h2 {
          font-weight: 700;
          font-size: 22px;
          line-height: 24px;
        }

        h3 {
          font-size: 14px;
          line-height: 20px;
          white-space: normal;
        }

        .config-id {
          font-size: 14px;
          font-style: italic;
          color: $gray9;
          margin-left: 1.5em;
          white-space: nowrap;
        }
      }

      .react-tabs__tab-panel--selected {
        border: 1px solid #eeeeee;
        padding: 20px;
      }
    }

    > .actions {
      background-color: #b3b3b3;
      height: 60px;
      padding: 10px;
      padding-left: calc(#{$default-columns-width} + 10px);

      .md-btn {
        white-space: nowrap;
      }
    }

    @include mq("desktop-wide") {
      .content-wrapper {
        + .actions {
          padding-left: calc(#{$large-columns-width} + 10px);
        }
      }

      .advanced-view {
        .selection-columns {
          flex: 1 1 $large-columns-width;
          max-width: $large-columns-width;
        }

        .test-config-editor {
          width: $large-editor-width;
          flex: 1 1 $large-editor-width;
        }
      }
    }
  }
}
