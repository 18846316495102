@use "/src/styles/base/resources" as *;

.customer-audits {
  .select-label {
    background: #e1e1e1;
    padding: 15px;
    border-bottom: #f0f0f0 solid;
  }
  .customer-audits-section {
    background-color: #e9e9e9;
    padding: 20px;
    .card-audits {
      .ant-input-number {
        margin: 0px 15px 0px 15px;
      }
      .ant-card-body {
        text-align: center;
      }
      .ant-select-selector {
        height: auto;
      }
    }
  }
}
