@use "/src/styles/base/resources" as *;

.active-customer-selector {
  .asci-search {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .drawer-btn {
    border-bottom: white 1px solid;
    padding: 5px;
    height: 30px;
  }
  .customer-button {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px 10px 10px 25px;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    .app-name,
    .md-icon {
      color: $dark-color;
      font-size: 1em;
      margin: 5px;
      text-align: left;
    }

    &:hover {
      background: $grey-ligth;
    }

    &.selected {
      background: $blue;
      color: $white;
      svg {
        stop:nth-child(1n) {
          stop-color: $white;
        }
      }
    }
  }
}
