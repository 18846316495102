@use "/src/styles/base/resources" as *;

.cc-button {
  display: inline-flex;
  cursor: pointer;
  font-family: "Open Sans", "Roboto", Helvetica, Arial, sans-serif;
  padding: 5px 15px;
  line-height: 1em;
  font-size: 14px;
  border-radius: 5px;
  border: 0;
  outline: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  &:focus {
  }

  &:active {
  }
}
