@use "/src/styles/base/resources" as *;

.customer-management-page {
  .customer-management-table {
    margin-top: 60px;
    width: 85%;
    box-shadow: $glow-shadow;
    margin-bottom: 80px;

    .cc-section-header {
      border-radius: 8px 8px 0 0;
    }
  }
}
