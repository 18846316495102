@use "/src/styles/base/resources" as *;

.task-status {
  padding: 0.25rem;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;

  i {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    font-size: 1.1rem;
    color: white;
  }

  &.resolved {
    background-color: #1aa91f;
  }

  &.canceled {
    background-color: orange;
  }

  &.pending {
    background-color: #0489e5;
  }

  &.error {
    background-color: #991a1a;
  }
}
