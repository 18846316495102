@use "/src/styles/base/resources" as *;

.table-pagination {
  background: $grey-header;

  > tr {
    > td {
      position: relative;
      padding: 0;
    }
  }

  .md-table-pagination {
    margin: 0 !important;
    right: 10px;

    .table-select {
      display: none;
    }
  }
}
