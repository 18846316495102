@use "/src/styles/base/resources" as *;

.fail-code-associated-tests-list {
  $border-color: #979797;
  .cc-test-row {
    border-bottom: 1px solid $border-color;
  }
  .test-definition-icon {
    img {
      max-height: 35px;
      max-width: 35px;
    }
  }
  .extra {
    display: grid;
    justify-content: end;
  }
}
