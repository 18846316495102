@use "/src/styles/base/resources" as *;

.test-class-row {
  $border-color: #ddd;
  $background-color: #f0f0f0;
  $background-color-selected: darken($background-color, 4%);

  $icon-size: 50px;
  $icon-side-padding: 15px;

  $status-size: 80px;

  $name-width: 220px;

  $toggle-width: 50px;

  display: flex;
  flex-direction: row;
  border: 1px solid $border-color;
  background-color: $background-color;

  &.clickable:hover {
    cursor: pointer;
  }

  &.top-border-on-first:first-of-type {
    border-top: 1px solid $border-color;
  }

  &.even-bg:nth-of-type(even) {
    background-color: white;
  }

  &.selected,
  &.selected .toggle {
    background-color: $background-color-selected;
  }

  > div {
    padding: 5px 0;
  }

  .status {
    flex: 0 0 $status-size;
    width: $status-size;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon {
    flex: 0 0 $icon-size;
    width: $icon-size + 2 * $icon-side-padding;
    padding: 5px $icon-side-padding;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .test {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.fixed-width {
      width: $name-width;
    }

    .name {
      color: #696969;
    }

    .definitions {
      display: flex;
      flex-direction: column;
      justify-content: center;

      select {
        width: 100%;
      }
    }
  }

  .description {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 10px;
    padding-left: 10px;
    border-right: 1px solid $border-color;
  }

  .md-icon {
    flex: 0 0 24px;
  }

  .toggle {
    flex: 0 0 $toggle-width;
    width: $toggle-width;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid $border-color;
    background-color: darken($background-color, 2%);

    > div {
      height: 100%;
      width: 100%;

      .md-selection-control-label {
        height: 100%;
        width: 100%;
      }

      .md-selection-control-toggle {
        margin: 0 auto;
      }
    }
  }
}
