@use "/src/styles/base/resources" as *;

.fail-codes-picker {
  $selector-row-height: 60px;
  $border-color: #979797;
  @mixin header {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 5px;
  }
  .selector-row {
    height: $selector-row-height;
    border-bottom: 1px solid $border-color;
    padding: 5px;
    .content-pointer {
      color: $grey;
    }
    &.checked {
      background: $grey-ligth;
    }
    &.current {
      background: #bbe4ff;
    }
  }

  .subheader-column {
    background: $grey;
    font-size: 16px;
    border-bottom: 1px solid $border-color;
    @include header;
  }

  .fail-code-name {
    width: 20%;
    flex-shrink: 0;
    padding: 5px;
  }
  .fail-code-description {
    width: 30%;
    flex-shrink: 0;
    padding: 5px;
  }
}
