@use "/src/styles/base/resources" as *;

$col-order-width: 50px;
$col-handle-width: 40px;
$col-icon-width: 80px;
$col-name-width: 200px;
$col-identifier-name-width: 200px;
$border-color: #ccc;

.test-suite-order-list {
  ul {
    margin: 0;
  }

  li {
    border-bottom: 1px solid $border-color;
  }
}

body > .orderable-list-item {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}

// dragged item is appended to the body, we can't nest this in .test-suite-order-list
.orderable-list-item {
  display: flex;
  flex-direction: row;
  background: #f0f0f0;
  color: #696969;
  z-index: 1; // so that the dragged row is not displayed under the buttons
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;

  &.header {
    background: #666;
    height: 50px;
    color: white;

    .handle {
      border: 0;
    }
  }

  .order {
    font-size: 20px;
    flex: 0 0 $col-order-width;
    width: $col-order-width;
  }

  .handle {
    flex: 0 0 $col-handle-width;
    width: $col-handle-width;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: $col-handle-width;
      height: 100%;

      .md-icon {
        color: $black;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .icon {
    flex: 0 0 $col-icon-width;
    width: $col-icon-width;
    padding: 5px 15px;
  }

  .test-name {
    flex: 0 0 auto;
    width: $col-name-width;
  }

  .identifier-name {
    flex: 0 0 auto;
    width: $col-identifier-name-width;
  }

  .description {
    flex: 1 1 auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .actions {
    padding: 0 10px;
  }
}
