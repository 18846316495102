@use "/src/styles/base/resources" as *;

.cc-checkbox {
  display: inline-flex;

  i {
    width: 24px;
    height: 24px;
    line-height: 24px;

    &::before {
      position: relative;
      top: 1px;
    }
  }

  &--no-padding {
    height: auto !important;
    width: auto !important;

    .md-btn--icon {
      height: auto !important;
      width: auto !important;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &--no-vertical-padding {
    height: auto !important;

    .md-btn--icon {
      height: auto !important;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
