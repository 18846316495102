@use "/src/styles/base/resources" as *;

.fail-code-category-picker {
  $selector-row-height: 60px;
  $border-color: #979797;

  .order {
    padding: 4px;
    width: 36px;
    flex-shrink: 0;
  }

  @mixin header {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 5px;
  }

  .selector-row {
    height: $selector-row-height;
    border-bottom: 1px solid $border-color;
    padding: 5px;

    &.current {
      background: #bbe4ff;
    }

    .content-pointer {
      color: $grey;
    }
  }

  .subheader-column {
    @include header;
    background: $grey;
    border-bottom: 1px solid $border-color;
  }

  .fail-code-category-name {
    min-width: 50%;
    flex-grow: 1;
    padding: 5px;
  }
}
