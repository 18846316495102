@use "/src/styles/base/resources" as *;

.cc-dashboard-widgets {
  margin-top: 30px;
  padding: 15px;
  background: $white;
  box-shadow: $glow-shadow;
  border-radius: 8px;

  .filter-container {
    margin: 0.7em 0.7em 0.7em 0.7em;

    .filter-label {
      font-size: 15px;
      margin-left: 1em;
      margin-right: 0.5em;
    }

    .cc-select-list {
      border: none;
    }
  }

  .widgets-container {
    border-radius: 3px;
    background: $white;
  }

  .uph-stats {
    height: 82px;
    width: 25%;
    border-radius: 8px;
    background: $gray-cold2;
    box-sizing: border-box;
    margin: 0.5em 1em;

    .stats-number {
      color: $gray-cold6;
      font-size: 30px;
      font-weight: bold;
    }

    .stats-label {
      color: $gray-cold6;
    }
  }

  .section-note {
    padding-right: 2%;
    color: $gray6;
    font-size: 12px;
    font-style: italic;
  }
}
