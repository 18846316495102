@use "/src/styles/base/resources" as *;

.session-info {
  color: $white;
  text-align: center;
  margin-right: 15px;

  .nickname {
    margin-left: 0.6rem;
    color: $white;
  }
}
