@use "/src/styles/base/resources" as *;

.test-definition-locale-editor {
  .cc-section-header {
    font-size: 20px;
    font-weight: 700;
  }

  .cb-container {
    margin-right: 10px;
    background: white;
    border-radius: 5px;
  }

  .locale-table {
    $h-padding: 35px;

    margin-bottom: 25px;

    .col-lang {
      flex: 0 1 200px;
    }

    .col-value {
      flex: 1 1 auto;
    }

    > header {
      background: lighten($blue, 3%);
      display: flex;
      flex-direction: row;
      padding: 10px $h-padding;
      font-weight: 700;
      font-size: 16px;
      color: white;
    }

    > main {
      padding: 10px $h-padding;
      border: 1px solid $grey;
      border-top: 0;

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 5px 0;
      }

      .col-lang {
        color: $blue;
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
}
